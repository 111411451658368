import React from "react";
import {getUserData} from "../../../../lib/Auth";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from "reactstrap";
import apiRequest from "../../../../lib/Api";
import HeaderProntuario from "../../../../components/Header/HeaderProntuario";
import moment from "moment";
import withNotify from "../../../../lib/NotificationWrapper";

// reactstrap components

class HealthIndexForm extends React.Component {

    constructor(props) {
        super(props);
        let readOnly = false;
        let classInputs = '';
        this.state = {
            user: {name: '', date_start: moment()},
            currentUser: getUserData(),
            hasResponse: false,
            formsInputs: [],
            previousIndexes: [],
            readOnly: readOnly,
            isDone: true,
            emptyExam: {},
            values: {
                pressao_arterial: {}
            },
            indexes: [],
            total_points: 0,
            classInputs: classInputs,
        }
    }

    handleChange = (exam, name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        let currentValues = this.state.indexes;
        let examData = currentValues[exam].exams
        console.log('agora aqui -> ', examData)
        examData[name].value = value;
        this.setState({indexes: currentValues});
    }

    handleChangePressao = (exam, name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        let currentValues = this.state.indexes;
        let examData = currentValues[exam].exams
        examData.pressao_arterial[name] = value;
        this.setState({values: currentValues});
    }


    async componentWillMount() {
        await apiRequest("/patient/" + this.id, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                let readOnly = false;
                let classInputs = '';

                if (this.state.currentUser.role === 'patient') {
                    readOnly = true;
                    classInputs = 'form-exames-readonly';
                }
                this.setState({user: data, readOnly: readOnly, classInputs: classInputs});
            });

        await apiRequest(`/patient/${this.id}/exams`, {method: "GET"})
            .then(response => {
                let {data} = response.data;

                let values = {};
                this.state.total_points = 0;

                let emptyExam = {
                    date: null,
                    exams: {},
                    id: null,
                    points: 0
                }
                for (const input of data.exams) {
                    emptyExam.exams[input.slug] = {
                        value: input.value || '',
                        uuid: input.uuid,
                        points: input.points
                    }
                    if (input.slug === 'pressao_arterial') {
                        emptyExam.exams[input.slug].diastolica = input.diastolica || 0;
                        emptyExam.exams[input.slug].sistolica = input.sistolica || 0;
                        emptyExam.exams[input.slug].value = '';
                    }

                }
                const exams = [];
                for (let exam of data.previous) {
                    const examData = {
                        exams: {},
                        id: exam.id,
                        date: exam.created_at,
                        points: exam.data.total_points
                    }
                    for (let item of exam.data.exams) {
                        examData.exams[item.slug] = item
                    }
                    exams.push(examData)
                }
                if (exams.length === 0) {
                    exams.push(emptyExam)
                }

                /**
                 * Inicio da Correção
                 * Foi necessário fazer isso porque os arrays não estavam iguais.
                 */
                console.log('formInputs', data.exams)
                /* corrige o indexes */
                data.exams.map(formInput => {
                    exams.map(examIndex => {
                        if (!examIndex.exams[formInput.slug]) {
                            examIndex.exams[formInput.slug] = {
                                slug: formInput.slug,
                                uuid: formInput.uuid,
                                value: '',
                                points: 0
                            }
                        }
                    });
                });
                this.setState({formsInputs: data.exams, indexes: exams, emptyExam: emptyExam})
            })

    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    calculatePoints = async (index, exam, name, value) => {
        if (name === 'pressao_arterial') {
            value = this.state.indexes[index].exams.pressao_arterial.diastolica + 'x' + this.state.indexes[index].exams.pressao_arterial.sistolica
        }
        let retorno = await apiRequest(`/exams/${exam}/calculate-points`,
            {method: "POST", data: {value: value}, loader: false})

        let currentValues = this.state.indexes;
        let examData = currentValues[index].exams
        examData[name].points = retorno.data.data.points;
        this.setState({values: currentValues});
    }

    getTotalPoints(exam) {
        let totalPoints = 0;
        let index = this.state.indexes[exam];
        for (const item in index.exams) {
            totalPoints += index.exams[item].points;
        }
        return totalPoints;

    }

    onNewExam = () => {
        let newExam = {...this.state.emptyExam}
        let indexes = this.state.indexes;
        indexes.push(newExam);
        this.setState({indexes: indexes});
    }
    renderExamForms = (exams) => {
        return (
            <>
                {exams.map((exam, i) => {
                    return this.renderExamForm(i)
                })}
            </>
        )
    }

    renderExamForm = (exam) => {

        const totalPoints = this.getTotalPoints(exam);
        let index = this.state.indexes[exam];
        let data =  moment().format('DD/MM/YYYY');
        if (index.date !== null) {
            data = moment(index.date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
        }
        return (
            <div key={exam}>
                <h3 style={{padding:'0px'}}>Total de Pontos: {totalPoints}</h3>
                <h4 style={{padding:'0px'}}> Dados coletados em {data}</h4>
                <form>
                    <Row style={{paddingBottom: "25px"}}>
                        {this.renderInputs(exam)}
                    </Row>
                    <Row>
                        <Col sm='2'>
                            <Button color="primary"
                                    style={{display: this.state.readOnly ? 'none' : ''}}
                                    onClick={(event) => {
                                        this.save(exam)
                                    }} disabled={this.state.working}>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                    <hr/>
                </form>
            </div>
        )
    }

    renderInputPressaoSanguinea = (exam, input) => {

        let currentValue = this.state.indexes[exam].exams[input.slug];
        return (
            <>
                <Col md='3' key={input.slug+'sis'}>
                    <FormGroup>
                        <label>{input.name} - Sistolica</label>
                        <InputGroup>
                            <Input type="number" step="0.1"
                                   name={input.slug}
                                   value={currentValue.sistolica}
                                   onChange={(event) =>
                                       this.handleChangePressao(exam, 'sistolica', event.target.value)
                                   }
                                   onBlur={(event) => {
                                       this.calculatePoints(exam, input.uuid, input.slug, event.target.value)
                                   }}
                                   disabled={this.state.readOnly}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>&nbsp;</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md='3' key={input.slug+'dia'}>
                    <FormGroup>
                        <label>{input.name} - Diastolica</label>
                        <InputGroup>
                            <Input type="number" step="0.1"
                                   name={input.slug}
                                   value={currentValue.diastolica}
                                   onChange={(event) =>
                                       this.handleChangePressao(exam, 'diastolica', event.target.value)
                                   }
                                   onBlur={(event) => {
                                       this.calculatePoints(exam, input.uuid, input.slug, event.target.value)
                                   }}
                                   disabled={this.state.readOnly}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>{currentValue.points} pontos</InputGroupText>
                                <InputGroupText>&nbsp;</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </>

        )
    }

    renderInputs = (exam) => {
        let forms = this.state.formsInputs
        return (
            forms.map(input => {
                let currentValue = this.state.indexes[exam].exams[input.slug];
                if (input.slug === 'pressao_arterial') {
                    return this.renderInputPressaoSanguinea(exam, input);
                }
                return (
                    <Col md='6' key={input.slug}>
                        <FormGroup>
                            <label>{input.name}</label>
                            <InputGroup>
                                <Input type="number" step="0.1"
                                       name={input.slug}
                                       value={currentValue.value}
                                       onChange={(event) =>
                                           this.handleChange(exam, input.slug, event.target.value)
                                       }
                                       onBlur={(event) => {
                                           this.calculatePoints(exam, input.uuid, input.slug, event.target.value)
                                       }}
                                       disabled={this.state.readOnly}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>{currentValue.points} pontos</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                )
            })
        )
    }

    save = async (exam) => {
        try {
            let indexes = this.state.indexes;
            let index = indexes[exam];
            let exams = [];
            for (const item in index.exams) {
                let examData = index.exams[item];
                if (exam === 'pressao_arterial') {
                    examData.value = this.state.values.pressao_arterial.diastolica + 'x' + this.state.values.pressao_arterial.sistolica
                }
                exams.push(examData);
            }
            let data = {
                exams: exams,
                id: index.id || ''
            }
            let request = await apiRequest(`/patient/${this.id}/exams`, {method: "POST", data})
            index.id = request.data.data.id;
            this.setState({indexes: indexes});
            this.props.notify({type: 'success', message: `Indice de saude e nutrição registrado com sucesso`});
        } catch (e) {
            this.props.notify({type: 'error', message: e.data.message});
        }
    }

    render() {
        let link = `/admin/patient/${this.id}/details`;
        let textoLink = 'Voltar para o perfil do paciente.';
        if (this.state.readOnly) {
            link = `/admin/dashboard`;
            textoLink = 'Voltar para o painel.';
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={8} md={8}>
                                            <HeaderProntuario
                                                title={`Indice de saúde de ${this.state.user.name}`}
                                                link={link}
                                                buttonText={textoLink}
                                            />
                                        </Col>
                                        <Col sm={4} md={4}>
                                            <div className="float-right" style={{textAlign: 'right'}}>
                                            <Button type="text" outline color="primary"
                                            onClick={this.onNewExam}>
                                                <i className="fa fa-plus"/>&nbsp;Novo</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.renderExamForms(this.state.indexes)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(HealthIndexForm)
