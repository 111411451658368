import React from "react";
import {getUserData} from "../../../../lib/Auth";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import apiRequest from "../../../../lib/Api";
import {Link} from "react-router-dom";

// reactstrap components

class PatientPlanMeal extends React.Component {

    constructor(props) {
        super(props);
        let user = this.props.patient ? this.props.patient : getUserData().id;
        this.state = {
            me: user,
            meals: [],
            working: false,
            exams: {}
        };
    }

    async componentDidMount() {
        await apiRequest(`/patient/${this.state.me}/plan-meal?include=foods`, {method: 'GET'}).then(response => {
            let {meals} = response.data.data;
            this.setState({meals: meals});
        });
    }

    renderAlternativesClick(food) {
        if (food.alternatives.length === 0) {
            return (
                <></>
            )
        }

        return (
            <Link to={'#'} onClick={(event) => {
                event.preventDefault();
                this.toogleAlternatives(event, food.uuid)
            }}>
                Visualizar
            </Link>
        )
    }

    toogleAlternatives = (event, id) => {

        event.preventDefault();
        let item = document.getElementById('alternatives_' + id);
        if (item) {
            const currentStyle = item.style.display;
            item.style.display = currentStyle === 'none' ? '' : 'none';
        }
    }

    renderAlternativeslist(food) {
        if (food.alternatives.length === 0) {
            return (<></>);
        }

        return (
            <>
                <tr>
                    <td colSpan={3}>
                        <div className="text-divider"><span>Ou</span></div>
                    </td>
                </tr>
                {food.alternatives.map(alternative => {
                    let unitySelected = 'gramas';
                    if (alternative.home_measure_selected && alternative.home_measure_selected.label) {
                        unitySelected = alternative.home_measure_selected.label.toLowerCase();
                    }
                    return (
                        <tr key={alternative.uuid} id={'alternatives_' + food.uuid} className="food_alternative"
                            style={{display: ''}}>
                            <td>{alternative.name}</td>
                            {/*<td colSpan={2}>{(Number(alternative.quantity) * Number(alternative.home_measure_selected.value.unit))}{' g '} {" ou " + alternative.quantity + ' ' + unitySelected}</td>*/}
                            <td>{ Number(alternative.quantity) + ' ' + unitySelected}</td>
                        </tr>
                    )
                })}
            </>
        )
    }

    render() {
        let meals = this.state.meals;
        return (
            <div className="content">
                <Card>
                    <CardHeader>
                        <span className="meal-title">
                            Plano Alimentar
                        </span>
                    </CardHeader>
                    <CardBody>
                        <Card className="card-timeline card-plain">

                            <CardBody>
                                <ul className="timeline timeline-simple">
                                    {meals.map(meal => {
                                        if (meal.foods.length === 0) {
                                            return (
                                                <></>
                                            )
                                        }
                                        let mealNotes = '';
                                        if (meal.notes) {
                                            mealNotes = meal.notes.replace(/\n/g, '<br/>')
                                        }
                                        return (
                                            <li className="timeline-inverted" key={meal.uuid}>
                                                <div className="timeline-badge">
                                                    <i className="nc-icon nc-single-copy-04"/>
                                                </div>
                                                <div className="timeline-panel">
                                                    <div className="timeline-heading">
                                                        <span className="meal-title">
                                                            {meal.name}
                                                        </span>
                                                        <Row>
                                                            <Col>
                                                                <span
                                                                    className="meal-time">Horário sugerido {meal.hour}</span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="timeline-body">
                                                        <table style={{width: "100%"}}>
                                                            <thead>
                                                            <tr>
                                                                <th>Alimento</th>
                                                                <th>Porção</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {meal.foods.map(food => {
                                                                return (
                                                                    <React.Fragment key={food.uuid}>
                                                                        <tr>
                                                                            <td>{food.name}</td>
                                                                            {/*<td>{(Number(food.quantity) * Number(food.home_measure_selected.unit))}{' g '} {" ou " + Number(food.quantity).toFixed() + ' ' + food.home_measure}</td>*/}
                                                                            <td>{ Number(food.quantity).toFixed() + ' ' + food.home_measure.toLowerCase()}</td>
                                                                        </tr>

                                                                        {this.renderAlternativeslist(food)}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Row style={{paddingTop: '10px'}}>
                                                        <Col>
                                                            <span className="meal-time"><b>Observações:</b></span>
                                                            <span dangerouslySetInnerHTML={{__html: mealNotes}}></span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>

            </div>
        )
    }
}

export default PatientPlanMeal
