import React from "react";
import Select from "react-select";
import ReactQuill from 'react-quill';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import InputError from "../../components/InputError";
import withNotify from "../../../lib/NotificationWrapper";

class ProductForm extends React.Component {
    constructor(props) {

        super(props);
        this.types = [
            {
                value: 1,
                label: 'Transacionais'
            },
            {
                value: 2,
                label: 'Agendados'
            },
        ]
        this.state = {
            options: [],
            name: '',
            id: null,
            subject: '',
            body: '',
            type: this.types[0],
            product_service_id: null,
            reminder: '',
            working: false,
            showProduct: '',
            showCategories: '',
            category: '',
            categories: [],
            disabled: false,
            errors: {},
            productServices: [],
            scheduledOptions: [
                {
                    value: 'scheduled_notification',
                    label: 'Envio de notificação/email Agendado',
                }
            ],
        }
    }

    async componentWillMount() {
        await this.fetchOptions();
        await this.fetchCategories();
        await this.loadProductServices();
        await this.getTemplateData();
    }


    async getTemplateData() {
        if (this.id === null) {
            return;
        }
        let response = await apiRequest(`/email-templates/${this.id}`, {method: "GET"});

        let data = response.data.data;
        data.name = this.state.options.find(option => option.value === data.name);
        data.type = this.types.find(tye => tye.value === data.type);

        if (data.productService !== null) {
            data.product_service_id = this.state.productServices.find(productService => productService.value === data.productService.id);
        }

        this.setState({...data, disabled: true});
        this.setMailType('type', data.type);
    }

    async fetchOptions() {
        return await apiRequest("/email-templates/names", {method: "GET"})
            .then(response => {
                let options = response.data;
                options = options.map(opt => ({value: opt.name, label: opt.description}));
                this.setState({options});
            });
    }

    async fetchCategories() {
        return await apiRequest("/email-templates-categories", {method: "GET"})
            .then(response => {
                let categories = response.data.data;
                this.setState({categories});
            });
    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            if (id) {
                return id;
            }
        }
        return null;
    }

    fetchItem() {
        let {name, product_service_id} = this.state;
        if (!name) {
            return;
        }

        let data = {};
        if (product_service_id && product_service_id.value !== -1) {
            data.product_service_id = product_service_id.value;
        }

        return apiRequest("/email-templates/name/" + name.value, {method: "GET", data})
            .then(response => {
                if (response.status === 200) {
                    this.props.notify({type: 'error', message: 'Já existe um template para esse Produto/Evento.'});
                    return;
                }
            });
    }

    loadProductServices = async searchTerm => {
        let data = {
            sort_by: 'service_id',
            search: searchTerm
        };
        return await apiRequest("/product-services", {data, method: 'GET'})
            .then(response => {
                let {data} = response.data;
                let options = data.map(ps => {
                    return {value: ps.id, label: this.getProductServiceLabel(ps)};
                });
                options.unshift({value: -1, label: 'Nenhum atendimento'});
                this.setState({productServices: options})
                return options;
            });

    }

    getProductServiceLabel(ps) {
        return ps.service.displayName + ' - ' + ps.product.name;
    }

    clearForm() {
        this.setState({
            name: '',
            subject: '',
            body: '',
            product_service_id: '',
            reminder: null,
            category: '',
            type: this.types[0],
            errors: {}
        });
    }

    handleChange = (e) => {
        let {name, value} = e.target;

        this.setState({
            [name]: value
        });

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleBodyChange = (value) => {
        this.setState({
            body: value
        });
    }

    handleSelect = (name, value) => {

        this.setState({[name]: value}, () => {
            this.setMailType(name, value);
        });

        if (this.state.errors.name) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    setMailType = (name, value) => {
        if (name === 'name') {
            this.fetchItem();
        }
        if (name === 'type') {
            let block = value.value === 1
            let options = this.state.options.map(ps => {
                ps.isDisabled = !block;
                if (ps.value === 'scheduled_notification') {
                    ps.isDisabled = block;
                }
                return ps;
            })
            let trigger = this.state.name;
            if (value.value === 2 ) {
                trigger = this.state.options.find(option => option.value === 'scheduled_notification')
            }

            this.setState({options: options, showProduct: block ? '' : 'none', name: trigger})
        }
    }

    handleProductChange = (option) => {
        this.setState({product_service_id: option}, () => {
            this.fetchItem();
        });

        if (this.state.errors.product_service_id) {
            this.setState({errors: {...this.state.errors, product_service_id: ''}});
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();


        let {id, name, subject, body, product_service_id, reminder, type, category} = this.state;
        if (!name) {
            return;
        }

        let data = {
            id,
            name: name.value,
            description: name.label,
            subject,
            type: type.value,
            product_service_id,
            body,
            reminder,
            category
        };
        if (product_service_id && product_service_id.value !== -1) {
            data.product_service_id = product_service_id.value;
            data.description += ' ' + product_service_id.label;
        } else if (product_service_id && product_service_id.value === -1) {
            data.product_service_id = null;
        }

        let endPoint = "/email-templates";
        let method = 'POST';
        if (this.id !== null) {
            endPoint = `/email-templates/${this.id}`;
            method = 'PUT';
        }
        this.setState({working: true});
        await apiRequest(endPoint, {data, method: method})
            .then(response => {
                if (this.id === null) {
                    this.clearForm();
                }
                this.props.notify({type: 'success', message: 'Template armazenado com sucesso'});
            })
            .catch(err => {
                this.setState({errors: err.data.errors});
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);

        const toolbarOptions = [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', 'link'],
            [{ 'color': [] }],
            [{ 'align': [] }, { 'list': 'ordered'}, { 'list': 'bullet' }],
            ['clean']
        ]
        const modules = {
            toolbar: toolbarOptions
        };

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Template de e-mail</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <Row>
                                            <Label sm="2">Tipo da mensagem</Label>
                                            <Col sm="6">
                                                <FormGroup className={hasError.type}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        isDisabled={this.state.disabled}
                                                        value={this.state.type}
                                                        onChange={(evt) => {
                                                            this.handleSelect('type', evt);
                                                        }}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Selecione um tipo",
                                                                isDisabled: true
                                                            },
                                                            ...this.types
                                                        ]}
                                                        placeholder="Selecione um tipo"
                                                    />
                                                    <InputError name="type" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Evento</Label>
                                            <Col sm="6">
                                                <FormGroup className={hasError.name}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        isDisabled={this.state.disabled}
                                                        value={this.state.name}
                                                        onChange={(evt) => {
                                                            this.handleSelect('name', evt);
                                                        }}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Selecione um template",
                                                                isDisabled: true
                                                            },
                                                            ...this.state.options
                                                        ]}
                                                        placeholder="Selecione um template"
                                                    />
                                                    <InputError name="name" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{display: this.state.showProduct}}>
                                            <Label sm="2">Atendimento</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.product_service_id}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="product_service_id"
                                                        value={this.state.product_service_id}
                                                        onChange={this.handleProductChange}
                                                        defaultOptions
                                                        options={this.state.productServices}
                                                        placeholder="Selecione um atendimento (opcional)"
                                                    />
                                                    <InputError name="product_service_id" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{paddingBottom: '4px'}}>
                                            <Label sm="2">Assunto do e-mail</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.product_service_id}>
                                                    <Input type="text" name="subject" value={this.state.subject}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="subject" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: '4px', display: this.state.showCategories}}>
                                            <Label sm="2">Categoria</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.category}>
                                                    <Input list='browsers' type="text" name="category"
                                                           value={this.state.category}
                                                           onChange={this.handleChange}
                                                    />
                                                    <datalist id="browsers">
                                                        {this.state.categories.map(item =>
                                                            (<option value={item} key={item}/>)
                                                        )}
                                                    </datalist>
                                                    <InputError name="category" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{marginBottom: '4px'}}>
                                            <Label sm="2">Corpo do e-mail</Label>
                                            <Col sm="10">
                                                <div style={{height: '200px'}}>
                                                    <FormGroup className={hasError.body}>
                                                        <ReactQuill value={this.state.body}
                                                                    theme={'snow'}
                                                                    style={{height: '150px'}}
                                                                    modules={modules}
                                                                    onChange={this.handleBodyChange}/>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Tempo do lembrete (h)</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.reminder}>
                                                    <Input type="text"
                                                           name="reminder"
                                                           value={this.state.reminder}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="reminder" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col md="2"/>
                                            <Col md="10">
                                                <Button color="info" type="submit"
                                                        disabled={this.state.working}
                                                >
                                                    <i className="fas fa-save"/>{' '}
                                                    Salvar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(ProductForm);
