import React from "react";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";
import uuid from 'react-uuid';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import Switch from "react-bootstrap-switch";
import apiRequest from "../../../lib/Api";

class ExtendedData extends React.Component {
    constructor(props) {
        super(props);
        this.types = [
            {
                value: "",
                label: "selecione",
                isDisabled: true
            },
            {
                value: "text",
                label: "Resposta Curta",
                isDisabled: false
            },
            {
                value: "list",
                label: "Lista",
                isDisabled: false
            },
            {
                value: "chips",
                label: "Seleção Múltipla",
                isDisabled: false
            },
        ];
        this.places = [
            {
                value: "",
                label: "Selecione...",
                isDisabled: true
            },
            {
                value: "specialist",
                label: "Nutricionista",
                isDisabled: false
            },
        ]
        this.state = {
            working: false,
            errors: {},
            message: '',
            name: '',
            infos: [],
            info_name: '',
            info_type: '',
            place: null,
            info: {
                slug: '',
                name: '',
                type: this.types[0],
                options: [],
                searchable: true,
                required: false,
                default: '',
                uuid: '',
            },
            modal: false,
            productServices: [],
            inputTag: {
                placeholder: 'Informe os valores'
            },
            inputTagConsulta: {
                placeholder: '.'
            }
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRequiredField = this.handleRequiredField.bind(this);
        this.handleSearchableField = this.handleSearchableField.bind(this);
        this.handleTagsinput = this.handleTagsinput.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    componentDidMount() {

        if (this.id) {
            this.fetchData();
        }
    }

    fetchData() {
        return apiRequest("/extended-data/" + this.id, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                let extendedData = {
                    name: data.name,
                    slug: data.extended_data.slug,
                    place: this.places.find(place => place.value === data.place),
                    infos: this.parseFieldsData(data.extended_data.sections[0].fields),
                }
                this.setState(extendedData);
            });
    }

    parseFieldsData(fields) {
        for (let field of fields) {
            field.type = this.types.find(type => type.value === field.type);
            field.uuid = uuid();
        }
        return fields;
    }

    addNewInfo = () => {

        delete this.state.errors.info_name;
        delete this.state.errors.info_type;

        let info = {...this.state.info};
        let error = {};
        let hasErrors = false;
        if (info.name === '') {
            error.info_name = "O campo nome deve ser informado";
            hasErrors = true;
        }
        if (info.type === '') {
            error.info_type = "O campo tipo deve ser informado";
            hasErrors = true;
        }
        if (hasErrors){
            this.setState({errors: error});
            return;
        }
        let infos = this.state.infos;
        info.uuid = uuid();
        infos.push(info);
        this.props.notify({type: 'success', message: 'Informação salva com sucesso.'});
        this.setState({errors: error, infos: infos});
        this.clearInfoState();

    }

    handleChange = (e) => {
        let {name, value} = e.target;

        this.setState({
            [name]: value
        });

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }
    handleChangeInfo = (e) => {
        let {name, value} = e.target;
        let info = this.state.info;
        info[name] = value;
        this.setState({info: info});
        this.setState({
            [name]: value
        });
    }

    handleSelect = (option) => {
        this.setState({place: option});

        if (this.state.errors.place) {
            this.setState({errors: {...this.state.errors, place: ''}});
        }
    }
    handleSelectInfo = (option) => {

        let info = this.state.info;
        info.type = option;
        this.setState({info: info});


    }

    handleTagsinput(tagsinput) {
        let info = this.state.info;
        info.options = tagsinput;
        this.setState({info: info});
    }

    handleRequiredField(required) {

        let info = this.state.info;
        info.required = !info.required;
        this.setState({info: info});
    }

    handleSearchableField(searchable) {
        let info = this.state.info;
        info.searchable = !info.searchable;
        this.setState({info: info});
    }

    openModal() {
        this.setState({modal: !this.modal});
    }

    closeModal() {
        let info = this.state.info;
        info.options = [];
        info.name = '';
        info.type = '';
        this.setState({modal: false});
    }

    defaultRenderInput(props) {
        let {onChange, value, addTag, ...other} = props
        return (
            <input type="text" onChange={onChange} value={value} {...other} />
        )
    }

    updateInfo = (info) => {

        this.setState({info: {...info}, modal: true});
    }

    updateInfoData = () => {

        delete this.state.errors.info_name;
        delete this.state.errors.info_type;

        let info = {...this.state.info};
        let error = {};
        let hasError = false;
        if (info.name === '') {
            error.info_name = "O campo nome deve ser informado";
            hasError = true;
        }
        if (info.type === '') {
            error.info_type = "O campo tipo deve ser informado";
            hasError = true;
        }

        if (hasError) {
            return ;
        }
        let infos = this.state.infos;
        for (let i = 0; i < infos.length; i++) {
            let infoToUpdate = infos[i];
            if (infoToUpdate.uuid === info.uuid) {
                infos[i] = info;
            }
        }
        this.props.notify({type: 'success', message: 'Informação salva com sucesso.'});
        this.setState({errors: error, infos: infos});
        this.clearInfoState();
    }

    /**
     * Clear the state info
     */
    clearInfoState = () => {

        let clearInfo = this.state.info
        clearInfo.name = '';
        clearInfo.type = this.types[0];
        clearInfo.options = [];
        clearInfo.searchable = true;
        clearInfo.required = false;
        clearInfo.default = '';
        clearInfo.uuid = '';
        this.setState({info: clearInfo});
    }

    deleteInfo = (info) => {

        let infos = this.state.infos;
        const totalInfos = infos.length;
        for (let i = 0; i < totalInfos; i++) {
            if (infos[i].uuid === info.uuid) {
                infos.splice(i, 1);
                break;
            }
        }
        this.setState({infos: infos});
    }

    renderInfos() {
        return (
            this.state.infos.map(info => {
                return (
                    <Card key={info.uuid}>
                        <CardHeader>
                            <CardTitle tag="h4">{info.name}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <b>Tipo:</b> {info.type.label}
                                </Col>
                            </Row>
                            {(() => {
                                if (info.type.value === 'list' || info.type.value === 'chips') {
                                    return (<Row>
                                        <Col>
                                            <b>Opções:</b>
                                            <TagsInput
                                                disabled
                                                inputProps={this.state.inputTagConsulta}
                                                value={info.options}>

                                            </TagsInput>
                                        </Col>
                                    </Row>)
                                }
                            })()}
                            <Row>
                                <Col>
                                    <b>Obrigatório:</b> {info.required ? "Sim" : "Não"}
                                </Col>
                                <Col>
                                    <b>Pesquisável:</b> {info.searchable ? "Sim" : "Não"}
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <div style={{textAlign: 'right'}} className="mb-0">
                                <i className="fa fa-edit" onClick={() => {
                                    this.updateInfo({...info})
                                }}/>
                                &nbsp;
                                <i className="fa fa-trash" onClick={() => {
                                    this.deleteInfo(info)
                                }}/>
                            </div>
                        </CardFooter>
                    </Card>
                )
            })

        );
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Cadastro de Informações Adicionais</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Label sm="2">Grupo de Informações</Label>
                                            <Col sm="10">
                                                <FormGroup className={hasError.name}>
                                                    <Input type="text" name="name" value={this.state.name}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="name" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Onde</Label>
                                            <Col sm="6">
                                                <FormGroup className={hasError.place}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        value={this.state.place}
                                                        onChange={this.handleSelect}
                                                        options={this.places}
                                                        placeholder="Selecione um lugar para adicionar as informações"
                                                    />
                                                    <InputError name="place" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2"/>
                                            <Col md="10">
                                                <Button onClick={this.openModal} className="btn-round" color="info"
                                                        type="button">Adicionar Informações</Button>
                                                <Button className="btn-round" color="success" type="submit"
                                                        disabled={this.state.working}
                                                        onClick={this.save}>Salvar</Button>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.renderInfos()}
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.openModal} size="lg">
                    <ModalHeader toggle={this.toggle}>Adicionar Nova Informação</ModalHeader>
                    <ModalBody>
                        <form>
                            <Row>
                                <Label sm="2">Nome</Label>
                                <Col sm="10">
                                    <FormGroup className={hasError.info_name}>
                                        <Input type="text" name="name" value={this.state.info.name}
                                               onChange={this.handleChangeInfo}
                                        />
                                        <InputError name="info_name" errors={this.state.errors}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="2">Tipo</Label>
                                <Col sm="10">
                                    <FormGroup className={hasError.info_type}>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="type"
                                            value={this.state.info.type}
                                            onChange={this.handleSelectInfo}
                                            options={this.types}
                                            placeholder="Selecione o tipo da Informação"
                                        />
                                        <InputError name="info_type" errors={this.state.errors}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="2">Opções de Resposta</Label>
                                <Col sm="10">
                                    <FormGroup>
                                        <TagsInput
                                            inputProps={this.state.inputTag}
                                            placeholder="Informe as opções da informação"
                                            value={this.state.info.options}
                                            onChange={this.handleTagsinput}
                                            onlyUnique="true"
                                            renderInput={this.defaultRenderInput}
                                            className={"form-control react-tagsinput"}
                                            tagProps={{className: "react-tagsinput-tag success"}}/>

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="2">Valor Padrão</Label>
                                <Col sm="10">
                                    <FormGroup className={this.state.info.default}>
                                        <Input type="text" name="default" value={this.state.info.default}
                                               onChange={this.handleChangeInfo}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="2">Obrigatório</Label>
                                <Col sm="10">
                                    <Switch
                                        offColor="success"
                                        value={this.state.info.required}
                                        onChange={this.handleRequiredField}
                                        offText={<i className="nc-icon nc-simple-remove"/>}
                                        onColor="success"
                                        onText={<i className="nc-icon nc-check-2"/>}
                                    />{" "}
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="2">Pesquisável</Label>
                                <Col sm="10">
                                    <Switch
                                        offColor="success"
                                        value={this.state.info.searchable}
                                        onChange={this.handleSearchableField}
                                        offText={<i className="nc-icon nc-simple-remove"/>}
                                        onColor="success"
                                        onText={<i className="n c-icon nc-check-2"/>}
                                    />{" "}
                                </Col>
                            </Row>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            if (this.state.info.uuid === '') {
                                this.addNewInfo();
                            } else {
                                this.updateInfoData();
                            }
                        }}>Salvar</Button>{' '}
                        <Button color="secondary" onClick={this.closeModal}>Fechar</Button>
                        &nbsp;
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    get id() {

        if (this.props.match.params) {
            let {id} = this.props.match.params;
            if (id === undefined) {
                return  null;
            }
            return id;
        }
        return null;
    }

    save = () => {

        let hasError = false;
        let error = {};
        if (this.state.name === '') {
            error.name = "O campo nome deve ser informado";
            hasError = true;
        }
        if (this.state.place === '') {
            error.name = "O lugar da informação nome deve ser informado";
            hasError = true;
        }
        if (hasError) {
            this.setState({errors: error});
            return;
        }
        let data = {
            name: this.state.name,
            slug: this.slugify(this.state.name),
            place: this.state.place.value,
        }

        let extended_data = {...data}
        let section = {
            slug: this.slugify(this.state.name),
            name: this.state.name,
            fields: []
        }

        for (let info of this.state.infos) {
            let infoData = {...info};
            infoData.slug = this.slugify(info.name);
            infoData.type = info.type.value;
            infoData.property = info.slug.replaceAll('-', '_');
            section.fields.push(infoData);
        }
        extended_data.sections = [section];
        data.extended_data = extended_data;
        if (this.id !== null) {
            return apiRequest("/extended-data/" + this.id, {data, method: "PUT"})
                .then(response => {
                    this.props.notify({type: 'success', message: 'Grupo de informação atualizada com sucesso'});
                }).catch(err => {
                    this.setState({errors: err.data.errors})
                });
        } else {
            return apiRequest("/extended-data", {data, method: "POST"})
                .then(response => {
                    this.props.notify({type: 'success', message: 'Novo Grupo de informação armazenada com sucesso'});
                }).catch(err => {
                    this.setState({errors: err.data.errors})
                });
        }

    }

    slugify = (text) =>
        text
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-')


}

export default withNotify(ExtendedData);
