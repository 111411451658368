import React from "react";
import {getUserData} from "lib/Auth";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import defaultAvatar from "assets/img/default-avatar.png";
import apiRequest from "../../../lib/Api";
import ButtonChat from "../../../components/ButtonChat/ButtonChat";

class MySpecialist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: getUserData(),
            isAdmin: false,
            specialist: {
                name: '',
                bio: '',
                address: '',
                especialidades: '',
                crn: '',
                ano_graduacao: '',
                pos_graduacao: '',
                id: ''
            }
        };
    }

    componentDidMount() {
        this.mySpecialist();
    }

    renderChatButton() {


        if (!this.state.specialist.id) {
            return (<></>)
        }
        return (
            <ButtonChat
                user={this.state.specialist}
                label="Fale com o Nutricionista"
            />
        )
    }

    mySpecialist = () => {

        apiRequest('/user/my-specialist', {
            method: 'GET'
        }).then(success => {

            const specialist = success.data.data;
            const extendedData = specialist.extended_data;
            const newSpecialist = {
                name: specialist.name,
                pictureUrl: specialist.pictureUrl,
                bio: specialist.bio,
                address: specialist.address,
                id: specialist.id,
                crn: extendedData.values.crm.values || '',
                especialidades: 'Tag 1, Tag 2',
                // ano_graduacao: extendedData.values.ano_de_graduacao.values,
                // pos_graduacao: extendedData.values.pos_graduacao.values
            }
            this.setState({specialist: newSpecialist});

        }).catch(error => {

        });
    }

    get isAdmin() {
        return false;
    }

    get role() {
        return this.state.userData.role;
    }

    render() {

        let avatar = this.state.specialist.pictureUrl || defaultAvatar;
        return (
            <>
                <div className="content">
                    <Row>
                        <Col sm="12" md="8">
                            <Card>
                                <CardBody>
                                        <Row>
                                            <Col md={8} sm={6}>
                                                <CardTitle tag="h4">Meu Nutricionista</CardTitle>
                                            </Col>
                                            <Col md={4} sm={6} style={{textAlign: 'right'}}>
                                                {this.renderChatButton()}
                                            </Col>
                                        </Row>
                                    <Row>
                                        <Col md='12'>
                                            <div className="author">
                                                <img
                                                    alt="..."
                                                    style={{
                                                        width: '124px',
                                                        height: '124px',
                                                        position: 'relative'
                                                    }}
                                                    className="avatar border-gray float-left"
                                                    src={avatar}
                                                />
                                                <h3 className='text-center'>{this.state.specialist.name}</h3>
                                                <p className='text-center'>
                                                    <i className='nc-icon nc-pin-3'/>
                                                    &nbsp;&nbsp;
                                                    {this.state.specialist.address}
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='12'>
                                            <p>{this.state.especialidades}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='12'>
                                            <h5 className='nutrideck h5-subtitle'>Sobre</h5>
                                            <p className='nutrideck p-about'>{this.state.specialist.bio}</p>
                                        </Col>
                                        <Col md='12'>
                                            <h5 className='nutrideck h5-subtitle'>CRN</h5>
                                            <p className='nutrideck p-about'>{this.state.specialist.crn}</p>
                                        </Col>
                                        <Col md='12'>
                                            <h5 className='nutrideck h5-subtitle'>Ano de Graduação</h5>
                                            <p className='nutrideck p-about'>{this.state.specialist.ano_graduacao}</p>
                                        </Col>
                                        <Col md='12'>
                                            <h5 className='nutrideck h5-subtitle'>Pós Graduação</h5>
                                            <p className='nutrideck p-about'>{this.state.specialist.pos_graduacao}</p>
                                        </Col>
                                    </Row>
                                    <div>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                </div>
            </>
        );

    }
}

export default MySpecialist;
