import React, {Component} from "react";
// react plugin used to create switch buttons
import apiRequest from "../../lib/Api";
import InputError from "../../views/components/InputError";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

class ExtendedDataFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: "dropdown show",
            type: props.type,
            data: {},
            errors: {},
            sections: [{fields: []}],
            values: {},
            modal: false,
            plainValue: {},
            width:0,
            height:'300px',

        };

        this.height = '300px';
        this.handleClick = this.handleClick.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    updateWindowDimensions() {
        this.height =  ((window.innerHeight * 60)/100 )+'px';
        this.setState({ width: window.innerWidth, height: this.height });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleClick() {
        this.setState({modal: !this.modal});
    }

    closeModal() {
        this.setState({modal: false});
    }

    handleChange = (name, value) => {
        let values = this.state.values;
        let fieldValue = values[name];
        if (!fieldValue) {
            fieldValue = [];
        }
        const hasValue = fieldValue.find(element => element === value);
        if (!hasValue) {
            fieldValue.push(value);
        } else {
            for (let i = 0;  i < fieldValue.length; i++) {
                if (fieldValue[i] === value) {
                    fieldValue.splice(i, 1);
                    break;
                }
            }
        }

        values[name] = fieldValue;
        let plainvalues = this.parseReturn(values);
        this.setState({values: values});
        this.props.fillFields(plainvalues);
    }

    parseReturn = (data) => {
        let dataToReturn = {};
        for (let field in data) {

            if (typeof field == 'function') {
                continue;
            }
            let valueToReturn = this.state.values[field];
            dataToReturn[field] = valueToReturn;
        }
        return dataToReturn;
    }

    async componentDidMount() {
        let classes = this.props.open ? "dropdown show" : "dropdown";
        this.setState({classes: classes});
        await apiRequest("/extended-data/place/" + this.state.type, {method: 'GET'})
            .then(response => {
                let {data} = response.data;
                const fields = data.extended_data.sections[0].fields;
                this.setState({sections: data.extended_data.sections, data: data})
                let valuesList = {};
                let hasValuesSet = false;
                for (let field of fields) {
                    if (!fields.searchable) {
                        continue;
                    }
                    valuesList[field.property] = [];
                    this.state.showButton = true;
                }
                this.setState({values: valuesList});
                this.state.values = valuesList;
            });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    renderFields() {

        let hasError = InputError.convertToHasClass(this.state.errors);
        return (
            this.state.sections[0].fields.map(info => {
                if (!info.searchable) {
                    return;
                }
                return (
                    <div key={info.uuid}>
                        <Row>
                            <b>{info.name}</b></Row>
                        <Row>
                            {info.options.map(option => {
                                let value = [];
                                if (this.state.values[info.property]) {
                                    value = this.state.values[info.property];
                                }
                                let checked = false;
                                if (value.length > 0){
                                    checked = value.find(element => element === option);
                                }
                                return (<Col sm="4" xs="8" key={info.uuid+''+option}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name={option} value={option}
                                                       checked={checked}
                                                       onChange={() => {this.handleChange(info.property, option)}}/>
                                                <span className="form-check-sign"/>
                                                {option}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                )
            })
        );
    }

    search = () => {
        if (this.props.searchAction) {
            this.props.searchAction();
        }
        this.closeModal();
    }
    clear = () => {

        this.setState({values: {}});
        if (this.props.searchAction) {
            this.props.searchAction(true);
        }
    }

    openModal() {
        this.setState({modal: !this.modal});
    }


    render() {
        return (
            <>
                <Modal isOpen={this.state.modal} toggle={this.openModal} size="lg">
                    <ModalHeader toggle={this.toggle} style={{position: 'sticky'}}>Filtros para pesquisa</ModalHeader>
                    <ModalBody style={{maxHeight: this.state.height, overflow:'scroll'}}>
                        {this.renderFields()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'success'} onClick={this.search}>Pesquisar</Button>
                        <Button color={'warning'} onClick={this.clear}>Limpar Filtros</Button>
                        <Button color={'danger'} onClick={this.closeModal}>Cancelar</Button>
                        &nbsp;
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default ExtendedDataFilter;
