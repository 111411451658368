import React from "react";
import {Link} from 'react-router-dom'
import Slider from "react-slick";


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import moment from "moment";
import defaultAvatar from "assets/img/default-avatar.png";
import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import Appointment from "../appointments/Appointment";
import StatusProgram from "./StatusProgram/StatusProgram";
import CardAppointment from "./Appointment/CardAppointment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ExamIndex from "./Files/ExamIndex";
import ButtonChat from "../../../components/ButtonChat/ButtonChat";


function SliderArrows(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        />
    );
}


class PatientIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {name: '', date_start: moment(), program_status: null, pictureUrl: null},
            horizontalTabs: 'prontuario',
            appointments: [],
            appointmentsDone: [],
            alert: null,
            appointmentModal: null,
            exams: {
                health_nutrition_index: {data:{total_points: 0}}
            },
            examsActions: {
                objetivos: {
                    buttonLabel: 'Iniciar'
                },
                anamnese: {
                    buttonLabel: 'Iniciar'
                },
                antropometria: {
                    buttonLabel: 'Iniciar'
                },
                resultado_antropometria: {
                    buttonLabel: 'Iniciar'
                },
                plano_alimentar: {
                    buttonLabel: 'Iniciar'
                },
                perfil_conduta: {
                    buttonLabel: 'Iniciar'
                },
                metas_combinados: {
                    buttonLabel: 'Iniciar'
                },
                health_nutrition_index: {
                    buttonLabel: 'Iniciar',
                    data: {total_points: 0}
                }
            },
            patient: {
                name: '',
                birth: '',
                occupation: '',
                phone: '',
                email: '',
                gender: '',
            },
            stickyNote: '',
            stickyNotes: []
        };

        this.settingsSlider = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            nextArrow: <SliderArrows/>,
            prevArrow: <SliderArrows/>
        }

    }

    get id() {

        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    componentWillMount() {
        if (this.id) {
            this.getUserData();
        }
    }

    getUserData = async () => {
        const response = await apiRequest(`/patient/${this.id}`, {method: "GET"})
        const user = response.data.data;

        let dateBegin = moment(user.created_at);
        let userState = {
            name: user.name,
            pictureUrl: user.pictureUrl,
            date_start: dateBegin,
            program_status: user.program_status
        }
        this.setState({user: userState});

        let birthAge = []
        if (user.birth) {
            let birth = moment(user.birth, 'YYYY-MM-DD');
            let birthDay = moment(user.birth, 'YYYY-MM-DD').format('DD/MM/YYYY');
            let age = moment(birth).fromNow();
            let patientAge = age.replace('há ', '');
            birthAge.push(birthDay);
            birthAge.push(patientAge);
        }

        let address = [
            user.address + ', ' + user.address_number + ' ' + user.address_complement,
            user.neighborhood,
            user.city,
            user.state,
            user.cep
        ];
        this.setState({
            patient: {
                id: this.id,
                name: user.name,
                birth: `${birthAge.join(' - ')}`,
                occupation: user.occupation,
                phone: user.phone,
                email: user.email,
                gender: user.gender,
                address: address.join(' / '),
            }
        });

        try {
            const responseMedicalRecord = await apiRequest(`/patient/${this.id}/medical-record`, {method: "GET"})
            this.setState({exams: responseMedicalRecord.data.exams})
        } catch (error) {

        }

    }

    renderChatButton() {


        if (!this.state.patient.id) {
            return (<></>)
        }

        return (
            <ButtonChat
                user={this.state.patient}
                label="Mensagens"
            />
        )
    }

    renderExamStatus(exam) {
        let status = 'Não Iniciado';
        let examData = this.state.exams[exam];
        let buttonLabel = 'Iniciar'

        let icon = '';
        let colorIcon = "blue"
        if (examData && examData.status !== null) {
            icon = 'fa fa-check-circle'
            colorIcon = "green";
            status = examData.status === 'done' ? ' Concluído em ' : 'Iniciado em ';
            status += moment(examData.due_date, 'YYYY-MM-DD').format("DD/MM/YYYY");
            buttonLabel = examData.status === 'done' ? 'Visualizar' : 'Continuar';
        }
        this.state.examsActions[exam].buttonLabel = buttonLabel;
        return (
            <>
                <i className={icon} style={{color: colorIcon, fontSize: '25px'}}></i>&nbsp;<span>{status}</span>
            </>
        );
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({[name]: value});
    }

    newStick = () => {

        document.getElementById('stickyNote').focus();
    }
    addStickyNote = async () => {
        if (this.state.stickyNote === '') {
            this.getStickyNotes();
            return;
        }
        const request = await apiRequest(
            `/sticky-notes/${this.id}`,
            {method: 'POST', data: {'text': this.state.stickyNote}}
        ).then(response => {
            this.setState({stickyNote: ''});
            this.getStickyNotes();
            this.props.notify({type: 'success', message: 'Lembrete adicionado com sucesso.'});
        });
    }

    getStickyNotes = () => {
        this.setState({stickyNotes: []});
        const request = apiRequest(`/sticky-notes/${this.id}`, {method: 'GET'})
            .then(success => {
                this.setState({stickyNotes: success.data});
            }).catch(error => {
                this.setState({stickyNotes: []});
            });
    }

    deleteStickyNote = async (stickyNote) => {
        const request = await apiRequest(
            `/sticky-notes/${stickyNote.id}`,
            {method: 'DELETE'}
        ).then(response => {
            this.getStickyNotes();
            this.props.notify({type: 'success', message: 'Lembrete removido com sucesso.'});
        });
    }
    completeStickyNote = async (stickyNote) => {
        const request = await apiRequest(
            `/sticky-notes/${stickyNote.id}/done`,
            {method: 'PUT'}
        ).then(response => {
            this.getStickyNotes();
            this.props.notify({type: 'success', message: 'Lembrete concluído com sucesso.'});
        });
    }

    renderStickyNotes = (stickyNote) => {

        let dateDone = moment(stickyNote.created_at.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY');
        if (stickyNote.done) {
            dateDone = moment(stickyNote.updated_at.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        return (
            <Row key={stickyNote.id}
                 className='align-items-center'
                 style={{border: '0.9px dotted #f2f2f2'}}
            >
                <Col md="8" className='align-items-center'>{stickyNote.text}</Col>
                <Col md="2" className='align-items-center'>{dateDone}</Col>
                <Col md="2" className='align-items-center'>
                    <Button disabled={stickyNote.done} onClick={() => {
                        this.completeStickyNote(stickyNote)
                    }}>
                        {stickyNote.done ? 'Concluído' : 'Concluir'}
                    </Button>
                </Col>
            </Row>

        );
    }

    openAppointment = (appointment) => {
        if (!appointment) {
            appointment = false;
        }
        this.setState({
            appointmentModal: (
                <Appointment
                    open={true}
                    isSpecialist={true}
                    patient={this.state.patient}
                    appointment={appointment}
                    allowClose={true}
                    onHide={(success) => {
                        this.setState({appointmentModal: null});
                        this.getAppointments();
                        if (success) {
                            this.props.notify({type: 'success', message: 'Agendamento salvo com sucesso.'});
                        }
                    }}
                />
            )
        })
    }

    startAppointment = async (appointment) => {
        this.props.history.push(`/admin/appointments/${appointment.id}`);
    }

    getAppointments = async () => {
        this.setState({appointments: []});
        this.setState({appointmentsDone: []});
        await apiRequest(`/appointments?user_id=${this.id}`, {
            method: 'GET'
        }).then(success => {

            let appointments = [];
            let appointmentsDone = [];
            for (const appointment of success.data.data) {
                if (appointment.situation === 'approved' || appointment.situation === 'started') {
                    appointments.push(appointment);
                }
                if (appointment.situation === 'done') {
                    appointmentsDone.push(appointment);
                }
            }
            this.setState({appointments: appointments});
            this.setState({appointmentsDone: appointmentsDone});
        }).catch(error => {

        });
    }

    renderAppointmentCard = (appointment) => {
        return (
            <CardAppointment
                key={appointment.id}
                appointment={appointment}
                renderButtons={true}
                cancelButton={() => {
                    this.setState({
                        alert: (
                            <ReactBSAlert
                                style={{display: "block", marginTop: "-100px"}}
                                title="Confirma o cancelamento do agendamento?"
                                onConfirm={async () => {
                                    await apiRequest(`/appointments/${appointment.id}/cancel`, {
                                        method: 'PUT'
                                    }).then(success => {
                                        this.props.notify({
                                            type: 'success',
                                            message: 'Agendamento cancelado com sucesso.'
                                        });
                                        this.setState({alert: null});
                                        this.getAppointments();
                                    }).catch(error => {
                                        this.props.notify({
                                            type: 'error',
                                            message: 'Não foi possível cancelar o agendamento.'
                                        });
                                    });
                                }}
                                onCancel={() => this.setState({alert: null})}
                                confirmBtnBsStyle="primary"
                                confirmBtnText='Confirmar'
                                cancelBtnBsStyle="default"
                                cancelBtnText="Cancelar"
                                showCancel
                            />
                        )
                    });
                }}
                changeButton={() => {
                    this.openAppointment(appointment);
                }}
                startButton={() => {
                    this.startAppointment(appointment);
                }}
            />
        );
    }

    renderAppointments = (appointmentsToRender) => {

        if (appointmentsToRender.length === 0) {
            return (
                <div>
                    <h5 style={{fontSize: '18px'}}>Não há agendamentos para este paciente.</h5>
                    <hr/>
                </div>
            );
        }
        return (
            <Slider {...this.settingsSlider}>
                {appointmentsToRender.map((appointment) => {
                    return this.renderAppointmentCard(appointment);
                })}
            </Slider>
        );
    }

    renderProgramStatus = () => {
        if (!this.state.user.program_status) {
            return (<></>);
        }
        return (
            <StatusProgram resume={this.state.user.program_status}/>
        )
    }

    render() {
        let avatar = this.state.user.pictureUrl || defaultAvatar;
        return (
            <>

                {this.state.appointmentModal}
                {this.state.alert}
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md="6">
                                            <div className="author">
                                                <img
                                                    alt="..."
                                                    style={{
                                                        width: '124px',
                                                        height: '124px',
                                                        position: 'relative'
                                                    }}
                                                    className="avatar border-gray float-left"
                                                    src={avatar}
                                                />
                                                <h3>{this.state.user.name}</h3>

                                                <b>Data de
                                                    Entrada:</b> {this.state.user.date_start.format('DD/MM/YYYY')}<br/>
                                                <b>Andamento do Programa</b><br/>
                                                {this.renderProgramStatus()}
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className='float-right clearfix' style={{textAlign: 'right'}}>
                                                <Button color="white"
                                                        onClick={this.openAppointment}
                                                        className='white-button'>Agendar Consulta
                                                </Button>
                                                {this.renderChatButton()}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="nav-tabs-navigation">
                                        <div className="nav-tabs-wrapper">
                                            <Nav id="tabs" role="tablist" tabs>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "prontuario"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "prontuario"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            this.setState({horizontalTabs: "prontuario"})
                                                        }
                                                    >
                                                        <b>Prontuário</b>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "exames"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "exames"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            this.setState({horizontalTabs: "exames"})
                                                        }
                                                    >
                                                        <b>Exames</b>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "agendamentos"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "agendamentos"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() => {
                                                            this.setState({horizontalTabs: "agendamentos"});
                                                            this.getAppointments();
                                                        }}
                                                    >
                                                        <b>Agendamentos</b>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "dados-paciente"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "dados-paciente"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            this.setState({horizontalTabs: "dados-paciente"})
                                                        }
                                                    >
                                                        <b>Dados do Paciente</b>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "lembretes"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "lembretes"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() => {
                                                            this.setState({horizontalTabs: "lembretes"});
                                                            this.getStickyNotes();
                                                        }
                                                        }
                                                    >
                                                        <b>Lembretes</b>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </div>
                                    <TabContent
                                        className="text-center"
                                        id="my-tab-content"
                                        style={{backgroundColor: '#efefef'}}
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="prontuario" role="tabpanel"
                                                 style={{backgroundColor: '#efefef', padding: "20px"}}>
                                            <Row>
                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Objetivos</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {this.renderExamStatus('objetivos')}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/forms/objetivos`}>
                                                                        {this.state.examsActions.objetivos.buttonLabel}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Anamnese</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {this.renderExamStatus('anamnese')}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/anamnese`}>
                                                                        {this.state.examsActions.anamnese.buttonLabel}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Antropometria</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {this.renderExamStatus('antropometria')}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/antropometria`}>
                                                                        {this.state.examsActions.antropometria.buttonLabel}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Resultado e Gastos Energéticos</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {this.renderExamStatus('resultado_antropometria')}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/resultado-antropometria`}>
                                                                        {this.state.examsActions.resultado_antropometria.buttonLabel}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Plano Alimentar</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {this.renderExamStatus('plano_alimentar')}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/plano-alimentar`}>
                                                                        {this.state.examsActions.plano_alimentar.buttonLabel}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Perfil e Conduta</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {this.renderExamStatus('perfil_conduta')}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/forms/perfil_conduta`}>
                                                                        {this.state.examsActions.perfil_conduta.buttonLabel}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Metas e Combinados</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {this.renderExamStatus('metas_combinados')}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/forms/metas_combinados`}>
                                                                        {this.state.examsActions.metas_combinados.buttonLabel}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Mensagens</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Mensagens para o usuário
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/messages`}>
                                                                        Visualizar
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md='6'>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <b>Índice de Saúde e nutrição</b>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Pontuação: {this.state.exams.health_nutrition_index.data.total_points} /
                                                                    1000
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button color="primary"
                                                                            tag={Link}
                                                                            to={`/admin/patient/${this.id}/health-index`}>
                                                                        Visualizar
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent
                                        className="text-center"
                                        id="exames"
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="exames" role="tabpanel"
                                        >
                                            <ExamIndex patient_id={this.id}/>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent
                                        id="dados-paciente"
                                        style={{backgroundColor: '#efefef'}}
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="dados-paciente" role="tabpanel">
                                            <Form>
                                                <Card>
                                                    <CardBody>

                                                        <Row>
                                                            <Col md='6'>
                                                                <FormGroup>
                                                                    <label>Nome Completo:</label>
                                                                    <Input type="text" name="name"
                                                                           style={{
                                                                               backgroundColor: 'transparent',
                                                                               border: '0'
                                                                           }}
                                                                           value={this.state.patient.name}
                                                                           disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md='6'>
                                                                <FormGroup>
                                                                    <label>Data de Nascimento / Idade:</label>
                                                                    <Input type="text" name="birth"
                                                                           style={{
                                                                               backgroundColor: 'transparent',
                                                                               border: '0'
                                                                           }}
                                                                           value={this.state.patient.birth}
                                                                           disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md='6'>
                                                                <FormGroup>
                                                                    <label>E-mail:</label>
                                                                    <Input type="text" name="email"
                                                                           style={{
                                                                               backgroundColor: 'transparent',
                                                                               border: '0'
                                                                           }}
                                                                           value={this.state.patient.email}
                                                                           disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md='6'>
                                                                <FormGroup>
                                                                    <label>Telefone:</label>
                                                                    <Input type="text" name="phone"
                                                                           style={{
                                                                               backgroundColor: 'transparent',
                                                                               border: '0'
                                                                           }}
                                                                           value={this.state.patient.phone}
                                                                           disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md='6'>
                                                                <FormGroup>
                                                                    <label>Profissão:</label>
                                                                    <Input type="text" name="occupation"
                                                                           style={{
                                                                               backgroundColor: 'transparent',
                                                                               border: '0'
                                                                           }}
                                                                           value={this.state.patient.occupation}
                                                                           disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md='6'>
                                                                <FormGroup>
                                                                    <label>Gênero:</label>
                                                                    <Input type="text" name="gender"
                                                                           style={{
                                                                               backgroundColor: 'transparent',
                                                                               border: '0'
                                                                           }}
                                                                           value={this.state.patient.gender}
                                                                           disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="12">
                                                                <FormGroup>
                                                                    <label>Endereço:</label>
                                                                    <Input type="textarea" name="address"
                                                                           style={{
                                                                               backgroundColor: 'transparent',
                                                                               border: '0'
                                                                           }}
                                                                           value={this.state.patient.address}
                                                                           disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12} style={{textAlign: 'right'}}>
                                                                <Button tag={Link} to={`/admin/patient/${this.id}/edit`}
                                                                        color='primary'>
                                                                    <i className='fas fa-user-edit'/>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Form>
                                        </TabPane>

                                    </TabContent>

                                    <TabContent
                                        id="agendamentos"
                                        style={{backgroundColor: '#efefef'}}
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="agendamentos" role="tabpanel"
                                                 style={{backgroundColor: '#FFF', paddingBottom: '30px'}}>
                                            <Form>
                                                <br/>
                                                <h5>Próximos</h5>
                                                {this.renderAppointments(this.state.appointments)}

                                                <h5>Concluídos</h5>
                                                {this.renderAppointments(this.state.appointmentsDone)}
                                                <br/>
                                                <br/>
                                            </Form>
                                        </TabPane>

                                    </TabContent>

                                    <TabContent
                                        id="lembretes"
                                        style={{backgroundColor: '#efefef'}}
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="lembretes" role="tabpanel">
                                            <Form>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col sm='9'>
                                                                <h5>Seus Lembretes para {this.state.patient.name}</h5>
                                                            </Col>
                                                            <Col sm='3'>
                                                                <div className='float-right clearfix'
                                                                     style={{textAlign: 'right'}}>
                                                                    <Button color="primary" type="button" outline
                                                                            style={{alignContent: 'right'}}
                                                                            onClick={this.newStick}
                                                                    >
                                                                        <i className='nc-icon nc-simple-add'/>
                                                                        Novo Lembrete
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md='10'>
                                                                <FormGroup>
                                                                    <InputGroup>
                                                                        <Input type="textarea" name="stickyNote"
                                                                               id="stickyNote"
                                                                               value={this.state.stickyNote}
                                                                               onChange={this.handleChange}
                                                                        />
                                                                        <InputGroupAddon addonType="append">
                                                                            <InputGroupText>
                                                                                <Button size='sm'
                                                                                        color="default"
                                                                                        outline
                                                                                        className='default'
                                                                                        onClick={() => {
                                                                                            this.setState({stickyNote: ''});
                                                                                        }}
                                                                                >
                                                                                    Cancelar
                                                                                </Button>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <div className='float-right clearfix'
                                                                     style={{textAlign: 'right'}}>
                                                                    <Button color="primary" type="button"
                                                                            style={{alignContent: 'right'}}
                                                                            onClick={this.addStickyNote}
                                                                    >
                                                                        <i className="fas fa-save"/>
                                                                        Salvar
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <h6>Lembretes Pendentes</h6>
                                                        <div>
                                                            {this.state.stickyNotes.length > 0 ? this.state.stickyNotes.map((stickyNote) => {
                                                                    if (stickyNote.done === false) {
                                                                        return this.renderStickyNotes(stickyNote)
                                                                    }
                                                                }
                                                            ) : ''}
                                                        </div>
                                                        <hr/>
                                                        <h6>Lembretes Concluídos</h6>
                                                        <div>
                                                            {this.state.stickyNotes.length > 0 ? this.state.stickyNotes.map((stickyNote) => {
                                                                    if (stickyNote.done === true) {
                                                                        return this.renderStickyNotes(stickyNote)
                                                                    }
                                                                }
                                                            ) : ''}
                                                        </div>
                                                    </CardBody>

                                                </Card>
                                            </Form>
                                        </TabPane>

                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default withNotify(PatientIndex);
