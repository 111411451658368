import React from "react";
import AsyncSelect from "react-select/lib/Async";
import ReactDatetime from "react-datetime";
import moment from 'moment';
import ptBr from 'moment/locale/pt-br';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";

class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      product_id: '',
      price_paid: '',
      quantity: '',
      purchased_at: '',
      working: false,
      errors: {}
    }
  }

  componentDidMount() {
    if (this.id) {
      this.fetchItem();
    }
  }

  fetchItem() {
    return apiRequest("/purchases/" + this.id, {method: "GET"})
        .then(response => {
          let purchase = response.data.data;
          purchase.user_id = {value: purchase.user.id, label: purchase.user.name};
          purchase.product_id = {value: purchase.product.id, label: purchase.product.name};
          this.setState(purchase);
        });
  }

  get id() {
    if (this.props.match.params) {
      return this.props.match.params.id;
    }
    return null;
  }

  get formTitle() {
    if (this.id) {
      return 'Alteração de compras';
    }
    return 'Cadastro de compras';
  }

  clearForm() {
    this.setState({
      user_id: '',
      product_id: '',
      price_paid: '',
      quantity: '',
      purchased_at: '',
      errors: {}
    });
  }

  loadUsers = searchTerm => {
    let params = {
      searchRole: 'student',
      search: searchTerm
    };
    return apiRequest("/users", {data: params, method: 'GET'})
      .then(response => {
        return response.data.data.map(user => ({value: user.id, label: user.name}));
      });
  }

  loadProducts = searchTerm => {
    let params = {
      search: searchTerm
    };
    return apiRequest("/products", {data: params, method: 'GET'})
      .then(response => {
        return response.data.data.map(item => ({value: item.id, label: item.name, price: item.price}));
      });
  }

  handleChange = (name, value) => {
    if (typeof name == 'object' && typeof name.target == 'object') {
      let event = name;
      event.preventDefault();
      name = event.target.name;
      value = event.target.value;
    }
    
    this.setState({
      [name]: value
    });

    let newErrors = {...this.state.errors, [name]: ''};
    let resetErrors = false;
    
    if (name == 'product_id') {
      this.setState({price_paid: value.price});
      
      if (this.state.errors.price_paid) {
        newErrors.price_paid = '';
        resetErrors = true;
      }
    }

    if (this.state.errors[name]) {
      resetErrors = true;
    }

    if (resetErrors) {
      this.setState({errors: newErrors});
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {user_id, product_id, price_paid, quantity, purchased_at} = this.state;
    let data = {
      user_id: user_id ? user_id.value : '',
      product_id: product_id ? product_id.value : '',
      price_paid,
      quantity,
      purchased_at: moment.isMoment(purchased_at) ? purchased_at.format('DD/MM/YYYY') : purchased_at
    };
    
    this.setState({working: true});
    
    let request;
    if (this.id) {
      request = apiRequest("/purchases/" + this.id, {data, method: 'PUT'})
    }
    else {
      request = apiRequest("/purchases", {data, method: 'POST'});
    }
    
    request.then(response => {
        this.clearForm();
        this.props.notify({type: 'success', message: 'Compra armazenada com sucesso'});
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }
  
  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);
    
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{this.formTitle}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">Usuário</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.user_id}>
                          <AsyncSelect
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="user_id"
                            value={this.state.user_id}
                            onChange={value =>
                              this.handleChange("user_id", value)
                            }
                            defaultOptions
                            loadOptions={this.loadUsers}
                            placeholder="Selecione um usuário"
                          />
                          <InputError name="user_id" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Produto</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.product_id}>
                          <AsyncSelect
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="product_id"
                            value={this.state.product_id}
                            onChange={value =>
                              this.handleChange("product_id", value)
                            }
                            defaultOptions
                            loadOptions={this.loadProducts}
                            placeholder="Selecione um produto"
                          />
                          <InputError name="product_id" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Quantidade</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.quantity}>
                          <Input type="number" name="quantity" step="1" value={this.state.quantity}
                            onChange={this.handleChange}
                          />
                          <InputError name="quantity" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Valor cobrado</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.price_paid}>
                          <Input type="number" name="price_paid" step="0.01" value={this.state.price_paid}
                            onChange={this.handleChange}
                          />
                          <InputError name="price_paid" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Data da compra</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.purchased_at}>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              name: "purchased_at",
                              placeholder: "Selecione uma data"
                            }}
                            timeFormat={false}
                            dateFormat={true}
                            locale="pt-br"
                            value={this.state.purchased_at}
                            onChange={value => 
                                this.handleChange('purchased_at', value)
                            }
                          />
                          <InputError name="purchased_at" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(ProductForm);
