/*eslint-disable*/
import React from "react";
import {Col, Container, Row} from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <Col>
            <div style={{textAlign: 'center'}}>

                Todos os direitos reservados à Nutrideck™, {1900 + new Date().getYear()}
            </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
