import Services from "./Services";
import Appointments from "./Appointments";

import {getUserData} from "../lib/Auth";

/**
 * Wizard helper
 * 
 * Flow is: choose product -> checkout -> user data -> specialist -> dashboard
 */
class Wizard {
  beforeCheckout() {
    let user = getUserData();
    if (user.role == 'student' && !user.organization) {
      return true;
    }
    return '/admin/dashboard';
  }
  
  async afterCheckout() {
    return '/admin/dashboard';
    
    // let specialistCalls = await Services.hasSpecialistCall();
    // if (specialistCalls) {
    //   return '/setup/choose-specialist/' + specialistCalls[0].service.id;
    // }
    // else {
    //   return '/admin/dashboard';
    // }
  }

  async afterUserProfile() {
    return '/admin/dashboard';
    
    // let user = getUserData();
    // if (user.role != 'student' || user.adminOrganization) {
    //   return '/admin/dashboard';
    // }

    // let promiseS = Services.getPurchasedServices();
    // let promiseA = Appointments.getNextAppointment();

    // return Promise.all([promiseS, promiseA])
    //   .then(([purchaseServices, appointment]) => {
    //     if (appointment || !purchaseServices.length) {
    //       return '/admin/dashboard';
    //     }
    //     return '/setup/choose-specialist/' + purchaseServices[0].service.id;
    //   });
  }
}

let singleton = new Wizard;
export default singleton;