import React from 'react';

// reactstrap components
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const MAX_VISIBLE_PAGES = 10;

class PaginationCtrl extends React.Component {
  goPage = (event, page) => {
    event.preventDefault();
    this.props.requestPage(page);
  }

  render() {
    let meta = this.props.responseMeta;
    if (!meta) {
      return (
        <></>
      );
    }

    let currentPage = meta.current_page;
    let lastPage = meta.last_page;
    let fromPage = (Math.floor(currentPage / MAX_VISIBLE_PAGES) * MAX_VISIBLE_PAGES) + 1;
    let toPage = Math.min(lastPage, fromPage + MAX_VISIBLE_PAGES - 1);
    let previous = null;
    let next = null;

    if (fromPage > MAX_VISIBLE_PAGES) {
      previous = fromPage - MAX_VISIBLE_PAGES;
    }
    if (lastPage > toPage) {
      next = Math.min(lastPage, toPage + MAX_VISIBLE_PAGES);
    }

    let pages = [];
    for (let i = fromPage; i <= toPage; i++) {
      pages.push(i);
    }

    return (
      <Pagination
        className="pagination pagination-primary"
        listClassName="pagination-primary"
      >
        {previous && (
        <PaginationItem>
          <PaginationLink
            aria-label="Previous"
            href="#"
            onClick={e => this.goPage(e, previous)}requestData
          >
            <span aria-hidden={true}>
              <i aria-hidden={true} className="fa fa-angle-double-left" />
            </span>
          </PaginationLink>
        </PaginationItem>
        )}

        {pages.map(page => (
        <PaginationItem key={page} className={page == currentPage ? 'active' : ''}>
          <PaginationLink
            href="#"
            onClick={e => this.goPage(e, page)}
          >
            {page}
          </PaginationLink>
        </PaginationItem>
        ))}

        {next && (

        <PaginationItem>
          <PaginationLink
            aria-label="Next"
            href="#"
            onClick={e => this.goPage(e, next)}
          >
            <span aria-hidden={true}>
              <i aria-hidden={true} className="fa fa-angle-double-right" />
            </span>
          </PaginationLink>
        </PaginationItem>
        )}
      </Pagination>
    );
  }
}

export default PaginationCtrl;
