import React from "react";
import moment from 'moment';
import {Link} from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

import apiRequest from "lib/Api";
import OrganizationUsage from "views/components/OrganizationUsage";
import defaultAvatar from "assets/img/default-avatar.png";

class HomeAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overview: false
    };
  }
  
  componentDidMount() {
    apiRequest("/organizations/mine/usage-overview", {method: "GET"})
      .then(response => {
        this.setState({overview: response.data})
      });
  }

  render() {
    const {overview} = this.state;
    if (!overview) {
      return (
        <>
          <div className="content dashboard-admin-container">
            Carregando dashboard...
          </div>
        </>
      );
    }

    return (
      <>
        <div className="content dashboard-admin-container">
          <Row>
          <Col lg="3" md="4" sm="4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-02 text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Usuários</p>
                        <CardTitle tag="p">{overview.users.total}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <Button tag={Link} to="/admin/users/list" color="default" className="btn-link">
                      <i className="fa fa-info-circle" />
                      Gerenciar usuários
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="5" md="4" sm="4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-cart-simple text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Atividades</p>
                        <CardTitle tag="p">
                          <span title="compradas">{overview.purchases.total}</span> &nbsp;
                          <i className="fa fa-thumb-tack" title="associadas"></i>{overview.purchaseUsers.total} &nbsp;
                          <i className="fa fa-check-square-o" title="finalizadas"></i>{overview.purchaseUsers.done}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <Button tag={Link} to="/admin/users/services" color="default" className="btn-link">
                      <i className="fa fa-info-circle" />
                      Associar atividades
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-calendar-60 text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Agendamentos</p>
                        <CardTitle tag="p">
                          {overview.appointments.total} &nbsp; 
                          <i className="fa fa-check-square-o" title="finalizados"></i>{overview.appointments.done}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <Button tag={Link} to="/admin/appointments/calendar" color="default" className="btn-link">
                      <i className="fa fa-info-circle" />
                      Ver agendamentos
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="card-tasks">
                <CardHeader>
                  <CardTitle tag="h4">Atividades Cadastradas</CardTitle>
                  <h5 className="card-category">Resumo para cada usuário</h5>
                </CardHeader>
                <CardBody>
                  <OrganizationUsage />
                </CardBody>
              </Card>
            </Col>
          </Row>  
        </div>
      </>
    );
  }
}

export default HomeAdmin;