import Content from "../views/pages/nutrideck/Content";
import Partners from "../views/pages/nutrideck/Partners";
import Challange from "../views/pages/nutrideck/Challange";

const routesNutrideck = [
    {
        path: "/nutrideck/content",
        component: Content,
        layout: "/admin",
        permissions: 'patient',
        redirect: true
    },
    {
        path: "/nutrideck/partners",
        component: Partners,
        layout: "/admin",
        permissions: 'patient',
        redirect: true
    },
    {
        path: "/nutrideck/challenge",
        component: Challange,
        layout: "/admin",
        permissions: 'patient',
        redirect: true
    },
]

export default routesNutrideck;
