    import React from "react";
    import AsyncSelect from "react-select/lib/Async";
    import Select from "react-select";
    import InputMask from 'react-input-mask';
    import {Redirect} from "react-router-dom";

    // reactstrap components
    import {
        Button,
        Card,
        CardBody,
        CardFooter,
        CardHeader,
        CardTitle,
        Col,
        Form,
        FormGroup,
        FormText,
        Input,
        Label, Nav, NavItem, NavLink,
        Row, TabContent, TabPane
    } from "reactstrap";

    import apiRequest from "../../../lib/Api";
    import withNotify from "../../../lib/NotificationWrapper";
    import InputError from "../../components/InputError";
    import {getUserData} from "../../../lib/Auth";
    import {removeMask} from "../../../lib/Formatter";
    import statesDef from "../../../variables/states";
    import Wizard from "models/Wizard";
    import ExtendedDataFields from "../../../components/ExtendedData/ExtendedDataFields";

    class EspecialistForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                name: '',
                email: '',
                cpf: '',
                address: '',
                city: '',
                state: '',
                cep: '',
                phone: '',
                mobile: '',
                linkedin: '',
                bio: '',
                organization_id: '',
                organization_role: '',
                curriculum: null,
                role: '',
                address_number: '',
                address_complement: '',
                neighborhood: '',
                working: false,
                extendedFieldType: null,
                errors: {},
                loggedUserData: getUserData(),
                redirect: '',
                extended_data: {},
                bank: '',
                agency: '',
                account_number: '',
                type:'',
                banks: [],
                horizontalTabs: 'formuser',
                payment_token: ''
            };
            this.statesDef = statesDef.map(state =>
                ({label: state.name, value: state.id})
            );
            this.types = [
                {label: 'Conta Corrente', value: 1},
                {label: 'Conta Poupança', value: 2},
            ]
        }

        componentWillMount() {
            this.fetchBanks();
            if (this.id) {
                this.fetchUser();
            }

        }

        fetchUser() {
            return apiRequest("/users/" + this.id, {method: "GET"})
                .then(response => {
                    let {data} = response.data;

                    for (let field of Object.keys(data)) {
                        if (data[field] === null) {
                            data[field] = '';
                        }
                    }

                    if (data.organization) {
                        let {organization} = data;
                        delete data.organization;

                        data.organization_id = {value: organization.id, label: organization.name};

                        let foundRole = this.orgUserRoles.find(item => item.value == organization.user_role);
                        data.organization_role = {...foundRole};
                    }


                    data.state = this.statesDef.find(option => option.value == data.state);
                    data.bank = this.state.banks.find(option => option.value == data.bank);
                    data.type = this.types.find(option => option.value == data.type);
                    this.setState(data);
                }).catch(error => {
                    this.props.notify({type: 'error', message: 'Sem permissão de acesso'});
                });
        }

        async fetchBanks () {
            let response = await apiRequest("/banks", {method: "GET"});
            let bankList = [];
            for (let bank of response.data.data) {
                 bankList.push({label: String(bank.compensation_code).padStart(3, '0')+'-'+ bank.name, value: bank.id});
            }
            this.setState({banks: bankList});
        }
        get id() {
            if (this.props.match.params) {
                let {id} = this.props.match.params;
                if (id === 'me') {
                    id = this.state.loggedUserData.id;
                }
                return id;
            }
            return null;
        }

        get isSetup() {
            return !!this.props.match.path.match(/setup/);
        }

        get scope() {
            if (this.props.match.params.scope) {
                return this.props.match.params.scope;
            }
            if (this.props.match.path.match(/users/)) {
                return 'users';
            } else if (this.props.match.path.match(/specialists/)) {
                return 'specialists';
            }
            throw new Error("Invalid context");
        }

        get role() {
            if (this.scope === 'users') {
                return 'student';
            }
            if (this.scope === 'specialists') {
                return 'specialist';
            }
            return null;
        }

        get formTitle() {
            if (this.isSetup) {
                return 'Preencha seus dados';
            }
            if (this.scope === 'users') {
                if (this.id) {
                    return 'Alteração de usuário';
                }
                return 'Cadastro de usuário';
            }
            if (this.scope === 'specialists') {
                if (this.id) {
                    return 'Alteração de nutricionistas';
                }
                return 'Cadastro de nutricionistas';
            }
            return '';
        }

        clearForm() {
            this.setState({
                name: '',
                email: '',
                cpf: '',
                address: '',
                city: '',
                state: '',
                cep: '',
                phone: '',
                mobile: '',
                linkedin: '',
                bio: '',
                curriculum: null,
                address_number: '',
                address_complement: '',
                neighborhood: '',
                bank: '',
                agency: '',
                account_number: '',
                type:''
            });
        }

        loadOrganizations = searchTerm => {
            let params = {
                search: searchTerm
            };
            return apiRequest("/organizations", {data: params, method: 'GET'})
                .then(response => {
                    return response.data.data.map(item => ({value: item.id, label: item.name}));
                });
        }

        get orgUserRoles() {
            return [
                {value: "admin", label: "Administrador da empresa"},
                {value: "member", label: "Membro da empresa"},
            ];
        }

        handleChange = (name, value) => {
            if (typeof name == 'object' && typeof name.target == 'object') {
                let event = name;
                event.preventDefault();
                name = event.target.name;
                value = event.target.value;
            }

            this.setState({[name]: value});

            if (this.state.errors[name]) {
                this.setState({errors: {...this.state.errors, [name]: ''}});
            }
        }

        handleSubmit = (e) => {
            e.preventDefault();

            let {
                name,
                email,
                cpf,
                address,
                city,
                state,
                cep,
                phone,
                mobile,
                linkedin,
                curriculum,
                bio,
                organization_id,
                organization_role,
                extended_data,
                address_number,
                address_complement,
                neighborhood,
                bank,
                agency,
                account_number,
                type,
                payment_token
            } = this.state;

            cpf = removeMask(cpf);
            cep = removeMask(cep);
            phone = removeMask(phone);
            mobile = removeMask(mobile);
            state = state ? state.value : '';
            type = type ? type.value : '';
            bank = bank ? bank.value : '';

            let data = {name, email, cpf, address, city, state, cep, phone, mobile, linkedin, bio, extended_data,
                address_number,
                address_complement,
                neighborhood,
                bank,
                agency,
                account_number, type, payment_token
            };


            if (organization_id) {
                data.organization_id = organization_id.value;
                if (organization_role) {
                    data.organization_role = organization_role.value;
                }
            }

            // use form data because we need to attach a file together
            let formData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
                if (key === 'extended_data') {
                    let jsonValue = JSON.stringify(value);
                    formData.set(key, jsonValue);
                }
            }
            if (curriculum) {
                formData.append('curriculum', curriculum);
            }

            this.setState({working: true});

            let request;
            let isEdit = !!this.id;
            if (this.id) {
                request = apiRequest("/users/" + this.id, {data: formData, method: 'PUT'})
            } else {
                formData.append('role', this.role);
                request = apiRequest("/users", {data: formData, method: 'POST'});
            }

            request.then(response => {
                if (!isEdit) {
                    this.clearForm();
                }
                this.props.notify({type: 'success', message: 'Usuário armazenado com sucesso'});
                if (this.isSetup) {
                    this.setupRedirect();
                }
            })
                .catch(err => {
                    console.log(err.data.errors);
                    this.setState({errors: err.data.errors});
                })
                .finally(
                    response => this.setState({working: false})
                );
        }

        attachCurriculum = (e) => {
            this.setState({curriculum: e.target.files[0]});
        }

        setupRedirect() {
            Wizard.afterUserProfile().then(redirect => {
                this.setState({redirect})
            });
        }

        setExtendedData = (data) => {
            this.setState({extended_data: data});
        }

        handleCep  = (name, value) => {
            if (typeof name == 'object' && typeof name.target == 'object') {
                let event = name;
                event.preventDefault();
                name = event.target.name;
                value = event.target.value;

            }
            this.setState({cep: value});
            this.setCep(value)
        }
        setCep = (cep) => {

            cep = cep.replaceAll('-', '');
            cep = cep.replaceAll('_', '');

            if (cep.length < 8) {
                return;
            }

            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then(response => response.json())
                .then(data => {
                    const userState = data.state = this.statesDef.find(option => option.value === data.uf);
                    this.setState({
                        city: data.localidade,
                        address: data.logradouro,
                        state: userState,
                        neighborhood: data.bairro,
                    })
                })
                .catch(err => {
                    console.log('cep Nao encontrado')
                })
        }

        renderPaymentToken() {
            if (this.state.loggedUserData.role === 'super_admin') {
                return  (
                    <>
                    <Row >
                       <Label sm="2">Token de Pagamento (JUNO)</Label>
                        <Col sm={10}>
                            <Input type="textarea" name="payment_token" value={this.state.payment_token}
                                   onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    <br />
                    </>
                );
            }
        }

        render() {
            let hasError = InputError.convertToHasClass(this.state.errors);

            if (this.state.redirect) {
                return <Redirect to={this.state.redirect}/>;
            }

            return (
                <>
                    <div className="content">
                        <Row>
                            <Col md="12">
                                <Form action="" className="form" method="POST"
                                      onSubmit={this.handleSubmit}
                                >
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">{this.formTitle}</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="nav-tabs-navigation" >
                                                <div className="nav-tabs-wrapper">
                                                    <Nav id="tabs" role="tablist" tabs >
                                                        <NavItem>
                                                            <NavLink
                                                                aria-expanded={this.state.horizontalTabs === "formuser"}
                                                                data-toggle="tab"
                                                                href="#"
                                                                role="tab"
                                                                className={
                                                                    this.state.horizontalTabs === "formuser"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.setState({horizontalTabs: "formuser"})
                                                                }
                                                            >
                                                                <b>Dados do nutricionista</b>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                aria-expanded={this.state.horizontalTabs === "bankdata"}
                                                                data-toggle="tab"
                                                                href="#"
                                                                role="tab"
                                                                className={
                                                                    this.state.horizontalTabs === "bankdata"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.setState({horizontalTabs: "bankdata"})
                                                                }
                                                            >
                                                                <b>Dados bancários</b>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </div>
                                            <TabContent
                                                id="my-tab-content"
                                                activeTab={this.state.horizontalTabs}
                                            >
                                                <TabPane tabId="formuser" role="tabpanel"
                                                         style={{padding: "20px"}}>
                                            <Row>
                                                <Label sm="2">Nomes</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.name}>
                                                        <Input type="text" name="name" value={this.state.name}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="name" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">E-mail</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.email}>
                                                        <Input type="text" name="email" value={this.state.email}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="email" errors={this.state.errors}/>
                                                        <FormText color="default" tag="span">
                                                            Você poderá alterar a qualquer momento, se mudar de empresa ou
                                                            de e-mail, mas fique atento que se alterar esse campo,
                                                            automaticamente,
                                                            altera seu login.
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Label sm="2">CPF</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.cpf}>
                                                        <InputMask mask="999.999.999-99" value={this.state.cpf}
                                                                   onChange={this.handleChange} name="cpf"
                                                        >
                                                            {(inputProps) => <Input type="text" {...inputProps} />}
                                                        </InputMask>
                                                        <InputError name="cpf" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">CEP</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.cep}>
                                                        <InputMask mask="99999-999" value={this.state.cep}
                                                                   onChange={this.handleCep} name="cep"
                                                        >
                                                            {(inputProps) => <Input type="text" {...inputProps} />}
                                                        </InputMask>
                                                        <InputError name="cep" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">Endereço</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.address}>
                                                        <Input type="text" name="address" value={this.state.address}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="address" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm={2}>Bairro:</Label>
                                                <Col md="10">
                                                    <FormGroup className={hasError.neighborhood}>
                                                        <Input type="text" name="neighborhood" value={this.state.neighborhood}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="neighborhood" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm={2}>Número:</Label>
                                                <Col md={4}>

                                                    <FormGroup className={hasError.address_number}>
                                                        <Input type="text" name="address_number" value={this.state.address_number}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="address_number" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                                <Label sm={2}>Complemento:</Label>
                                                <Col md={4}>
                                                    <FormGroup className={hasError.address_complement}>
                                                        <Input type="text" name="address_complement" value={this.state.address_complement}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="address_complement" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">Cidade</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.city}>
                                                        <Input type="text" name="city" value={this.state.city}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="city" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">Estado</Label>
                                                <Col sm="4">
                                                    <FormGroup className={hasError.state}>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="state"
                                                            value={this.state.state}
                                                            onChange={value =>
                                                                this.handleChange("state", value)
                                                            }
                                                            options={[
                                                                {
                                                                    value: "",
                                                                    label: "Selecione um estado",
                                                                    isDisabled: true
                                                                },
                                                                ...this.statesDef
                                                            ]}
                                                            placeholder="Selecione um estado"
                                                        />
                                                        <InputError name="state" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">Telefone</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.phone}>
                                                        <InputMask mask="(99) 9999tt999?"
                                                                   formatChars={{
                                                                       "9": "[0-9]",
                                                                       "t": "[0-9\-]",
                                                                       "?": "[0-9 ]"
                                                                   }}
                                                                   maskChar={null}
                                                                   value={this.state.phone}
                                                                   onChange={this.handleChange} name="phone"
                                                        >
                                                            {(inputProps) => <Input type="text" {...inputProps} />}
                                                        </InputMask>
                                                        <InputError name="phone" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">Celular</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.mobile}>
                                                        <InputMask mask="(99) 9999tt999?"
                                                                   formatChars={{
                                                                       "9": "[0-9]",
                                                                       "t": "[0-9\-]",
                                                                       "?": "[0-9 ]"
                                                                   }}
                                                                   maskChar={null}
                                                                   value={this.state.mobile}
                                                                   onChange={this.handleChange} name="mobile"
                                                        >
                                                            {(inputProps) => <Input type="text" {...inputProps} />}
                                                        </InputMask>
                                                        <InputError name="mobile" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">LinkedIn</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.linkedin}>
                                                        <Input type="text" name="linkedin" value={this.state.linkedin}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="linkedin" errors={this.state.errors}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            {this.role == 'specialist' &&
                                            <Row>
                                                <Label sm="2">Bio</Label>
                                                <Col sm="10">
                                                    <FormGroup className={hasError.bio}>
                                                        <Input type="textarea" name="bio" value={this.state.bio}
                                                               onChange={this.handleChange}
                                                        />
                                                        <InputError name="bio" errors={this.state.errors}/>
                                                        <FormText color="default" tag="span">
                                                            Biografia resumida
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            }


                                            {this.state.loggedUserData.role == 'super_admin' && (this.state.role == 'student' || this.role == 'student') &&
                                            <React.Fragment>
                                                <Row>
                                                    <Label sm="2">Empresa</Label>
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <AsyncSelect
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="organization_id"
                                                                value={this.state.organization_id}
                                                                onChange={value =>
                                                                    this.handleChange("organization_id", value)
                                                                }
                                                                defaultOptions
                                                                loadOptions={this.loadOrganizations}
                                                                placeholder="Selecione uma empresa"
                                                            />
                                                            <InputError name="organization_id" errors={this.state.errors}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label sm="2">Papel do usuário</Label>
                                                    <Col sm="4">
                                                        <FormGroup className={hasError.organization_role}>
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="organization_role"
                                                                value={this.state.organization_role}
                                                                onChange={value =>
                                                                    this.handleChange("organization_role", value)
                                                                }
                                                                options={[
                                                                    {
                                                                        value: "",
                                                                        label: "Selecione um papel",
                                                                        isDisabled: true
                                                                    },
                                                                    ...this.orgUserRoles
                                                                ]}
                                                                placeholder="Selecione um papel de usuário"
                                                            />
                                                            <InputError name="organization_role"
                                                                        errors={this.state.errors}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                            }
                                            {(this.role == 'specialist' || this.state.loggedUserData.role == 'specialist') &&
                                            <ExtendedDataFields type="specialist" data={this.state.extended_data} fillFields={this.setExtendedData}/>
                                            }
                                                </TabPane>
                                            </TabContent>
                                            <TabContent
                                                id="tab-bank-data"
                                                activeTab={this.state.horizontalTabs}
                                            >
                                                <TabPane tabId="bankdata" role="tabpanel"
                                                         style={{padding: "20px"}}>
                                                    {this.renderPaymentToken()}
                                                    <Row>
                                                        <Label sm="2">Banco</Label>
                                                        <Col sm="4">
                                                            <FormGroup className={hasError.bank}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="bank"
                                                                    value={this.state.bank}
                                                                    onChange={value =>
                                                                        this.handleChange("bank", value)
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: "",
                                                                            label: "Selecione um banco",
                                                                            isDisabled: true
                                                                        },
                                                                        ...this.state.banks
                                                                    ]}
                                                                    placeholder="Selecione um banco"
                                                                />
                                                                <InputError name="bank" errors={this.state.errors}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Tipo de conta</Label>
                                                        <Col sm="4">
                                                            <FormGroup className={hasError.type}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="type"
                                                                    value={this.state.type}
                                                                    onChange={value =>
                                                                        this.handleChange("type", value)
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: "",
                                                                            label: "Selecione um tipo de conta",
                                                                            isDisabled: true
                                                                        },
                                                                        ...this.types
                                                                    ]}
                                                                    placeholder="Selecione um tipo de conta"
                                                                />
                                                                <InputError name="type" errors={this.state.errors}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={2}>Agência com dígito:</Label>
                                                        <Col md={4}>

                                                            <FormGroup className={hasError.agency}>
                                                                <Input type="text" name="agency" value={this.state.agency}
                                                                       onChange={this.handleChange}
                                                                />
                                                                <InputError name="agency" errors={this.state.errors}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Label sm={2}>Conta com dígito:</Label>
                                                        <Col md={4}>
                                                            <FormGroup className={hasError.account_number}>
                                                                <Input type="text" name="account_number" value={this.state.account_number}
                                                                       onChange={this.handleChange}
                                                                />
                                                                <InputError name="account_number" errors={this.state.errors}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                        <CardFooter style={{position:'sticky', bottom:'15px'}}>
                                            <Row>
                                                <Col md="2"/>
                                                <Col md="10" style={{textAlign:'right'}}>
                                                    <Button className="btn" color="primary" type="submit"
                                                            disabled={this.state.working}
                                                    >
                                                        <i className="fas fa-save"/>
                                                        Salvar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </>
            );
        }
    }

    export default withNotify(EspecialistForm);
