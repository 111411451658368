import Home from "views/pages/dashboard/Home.jsx";
import Login from "views/pages/Login.jsx";
import Register from "views/pages/Register.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
import UserList from "views/pages/users/UserList";
import UserForm from "views/pages/users/UserForm";
import ProductForm from "views/pages/products/ProductForm";
import ProductPurchase from "views/pages/products/ProductPurchase";
import PurchaseForm from "views/pages/purchases/PurchaseForm";
import ExceptionForm from "views/pages/exceptions/ExceptionForm";
import ExceptionList from "views/pages/exceptions/ExceptionList";
import StandardsCalendar from "views/pages/standards/StandardsCalendar";
import AppointmentCreate from "views/pages/appointments/AppointmentCreate";
import AppointmentCreateAdmin from "views/pages/appointments/AppointmentCreateAdmin";
import AppointmentCalendar from "views/pages/appointments/AppointmentCalendar";
import AppointmentShow from "views/pages/appointments/AppointmentShow";
import AppointmentForm from "views/pages/appointments/AppointmentForm";
import OrganizationForm from "views/pages/organizations/OrganizationForm";
import OrganizationUsage from "views/pages/organizations/OrganizationUsage";
import SystemLogList from "views/pages/admin/SystemLogList";
import Chat from "views/pages/Chat";
import ForgotPassword from "views/pages/ForgotPassword";
import ResetPassword from "views/pages/ResetPassword";
import EmailTemplateForm from "views/pages/admin/EmailTemplateForm";
import ChooseSpecialist from "views/pages/appointments/ChooseSpecialist";
import ProductCheckout from "views/pages/products/ProductCheckout";
import PatientIndex from "views/pages/patient/Index";

import UploadCv from "views/pages/users/UploadCv";
import Ancora from "views/pages/services/Ancora";
import ChangePasswordForm from "views/pages/users/ChangePassword";
import ExtendedData from "./views/pages/admin/ExtendedData";
import ListExtendedData from "./views/pages/admin/ListExtendedData";
import Anamnese from "./views/pages/patient/Ananmese/Anamnese";
import Antropometria from "./views/pages/patient/Antropometria/Antropometria";
import ResultadoAntropometria from "./views/pages/patient/Antropometria/ResultadoAntropometria";
import PlanoAlimentarIndex from "./views/pages/patient/PlanoAliementar/PlanoAlimentarIndex";
import LancamentoAlimento from "./views/pages/patient/PlanoAliementar/LancamentoAlimento";
import MySpecialist from "./views/pages/specialist/MySpecialist";
import AppointmentsList from "./views/pages/patient/Appointment/AppointmentsList";
import PatientPlanMeal from "./views/pages/patient/PlanoAliementar/PatientPlanMeal";
import TratamentoIndex from "./views/pages/patient/Tratamento/TratamentoIndex";
import SpecialistList from "./views/pages/specialist/SpecialistList";
import EspecialistForm from "./views/pages/specialist/EspecialistForm";
import AgendarPrimeiraConsulta from "./views/pages/AgendarPrimeiraConsulta";
import UserFormData from "./views/pages/patient/UserFormData";
import ImportFoods from "./views/pages/admin/ImportFoods";
import EmailTemplateList from "./views/pages/admin/EmailTemplateList";
import UserMessages from "./views/pages/users/UserMessages";
import AppointmentUserCreate from "./views/pages/appointments/AppointmentUserCreate";
import PatientList from "./views/pages/admin/PatientList";
import HealthIndexForm from "./views/pages/patient/IndiceSaude/IndiceSaudeForm";
import routesNutrideck from "./routes/nutrideck";

const routes = [
    {
        path: "/choose-specialist/:purchaseServiceId",
        component: ChooseSpecialist,
        layout: "/setup",
        redirect: true
    },
    {
        path: "/choose-specialist/:purchaseServiceId",
        component: ChooseSpecialist,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/dashboard",
        name: "Painel de Controle",
        icon: "fas fa-home",
        component: Home,
        layout: "/admin",
        permissions: ['specialist']
    },
    {
        name: 'Meu Programa',
        icon: "fa fa-utensils",
        component: TratamentoIndex,
        layout: "/admin",
        path: "/patient/meu-programa",
        permissions: 'patient'
    },
    {
        path: "/meu-nutricionista",
        name: "Meu Nutricionista",
        icon: "nc-icon nc-single-02",
        component: MySpecialist,
        layout: "/admin",
        permissions: 'patient'
    },
    {
        name: "Pacientes",
        icon: "nc-icon nc-single-02",
        component: UserList,
        layout: "/admin",
        path: "/patient/list",
        permissions: ["specialist"],
    },
    {
        name: "Consultas",
        icon: "nc-icon nc-single-02",
        component: AppointmentsList,
        layout: "/admin",
        path: "/appointment",
        permissions: 'patient'
    },

    {
        collapse: true,
        name: "Nutricionistas",
        icon: "nc-icon nc-hat-3",
        state: "specialistsCollapse",
        permissions: "super_admin",
        views: [
            {
                path: "/specialists/new",
                name: "Cadastrar Novo",
                mini: "NS",
                component: EspecialistForm,
                layout: "/admin"
            },
            {
                path: "/specialists/list",
                name: "Listar",
                mini: "LS",
                component: SpecialistList,
                layout: "/admin"
            }
        ]
    },
    {
        name: "Pacientes",
        icon: "nc-icon nc-single-02",
        component: PatientList,
        layout: "/admin",
        path: "/patients",
        permissions: 'super_admin'
    },

    {
        collapse: false,
        name: "Agendamentos",
        icon: "nc-icon nc-calendar-60",
        state: "scheduleCollapse",
        component: AppointmentCalendar,
        path: "/appointments/calendar",
        layout: "/admin",
        permissions: ['specialist'],
        views: [
            {
                path: "/choose-user-product-specialist",
                name: "Agendar horário",
                mini: "AH",
                component: AppointmentCreateAdmin,
                layout: "/admin",
                permissions: "super_admin"
            },
            {
                path: "/appointments/calendar",
                name: "Horários agendados",
                mini: "HA",
                component: AppointmentCalendar,
                layout: "/admin",
                permissions: ['specialist', 'super_admin'],
            },
            {
                path: "/schedule-standards",
                name: "Horários disponíveis",
                mini: "HD",
                component: StandardsCalendar,
                layout: "/admin",
                permissions: "specialist",
            },
            {
                path: "/schedule-exceptions/new",
                name: "Cadastrar Exceção",
                mini: "NX",
                component: ExceptionForm,
                layout: "/admin",
                permissions: "specialist",
            },

        ]
    },
    {
        path: "/schedule-exceptions/list",
        name: "Listar Exceções",
        mini: "LX",
        component: ExceptionList,
        layout: "/admin",
        permissions: "specialist",
        redirect: true,
    },
    {
        collapse: true,
        name: "Administrativo",
        icon: "nc-icon nc-settings",
        state: "administrativeCollapse",
        permissions: "super_admin",
        views: [
            {
                path: "/system-log",
                name: "Registro de ações",
                mini: "RA",
                component: SystemLogList,
                layout: "/admin"
            },
            {
                path: "/email-templates/list",
                name: "Templates de e-mail",
                mini: "TE",
                component: EmailTemplateList,
                layout: "/admin",
            },
            {
                path: "/informacoes-adicionais/new",
                name: "Informações Adicionais",
                mini: "IA",
                component: ExtendedData,
                layout: "/admin"
            },
            {
                path: "/informacoes-adicionais/list/",
                name: "listar Informações Adicionais",
                mini: "LI",
                component: ListExtendedData,
                layout: "/admin"
            },
            {
                path: "/informacoes-adicionais/:id/edit",
                name: "listar Informações Adicionais",
                mini: "LI",
                component: ExtendedData,
                layout: "/admin",
                redirect: true,
            },
            {
                path: "/tabelas-alimentos",
                name: "Importar Tabela de Alimentos",
                mini: "IT",
                component: ImportFoods,
                layout: "/admin",
                permissions: "super_admin"
            }
        ]
    },
    {
        path: "/:scope(patient)/:id/edit",
        component: UserForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/:scope(specialists)/:id/edit",
        component: EspecialistForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/:scope(specialists)/new",
        component: EspecialistForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/:scope(patient)/new",
        component: UserForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/edit",
        component: UserForm,
        layout: "/setup",
        redirect: true
    },
    {
        path: "/product/checkout/:id",
        component: ProductCheckout,
        layout: "/setup",
        redirect: true
    },
    {
        path: "/product/:code",
        component: ProductPurchase,
        layout: "/setup",
        redirect: true
    },
    {
        path: "/products/:id/edit",
        component: ProductForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/purchases/:id/edit",
        component: PurchaseForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/organizations/:id/edit",
        component: OrganizationForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/schedule-exceptions/:id/edit",
        component: ExceptionForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/appointments/new-with/:specialistId/:purchaseServiceId",
        component: AppointmentCreate,
        layout: "/setup",
        redirect: true
    },
    {
        path: "/appointments/with/:specialistId/:purchaseServiceId/:userId?",
        component: AppointmentCreate,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/appointments/user",
        component: AppointmentUserCreate,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/appointments/reschedule/:previousAppointmentId",
        component: AppointmentCreate,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/appointments/:id/chat",
        component: Chat,
        layout: "/chat",
        redirect: true
    },
    {
        path: "/appointments/:id/edit",
        component: AppointmentForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/appointments/:id",
        component: AppointmentShow,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/primeiro-agendamento/:id_nutri",
        name: "primeiro-agendamento",
        component: Register,
        layout: "/auth",
        redirect: true
    },
    {
        path: "/escolher-horario/:id_nutri",
        name: "escolher-horario",
        component: AgendarPrimeiraConsulta,
        layout: "/auth",
        redirect: true
    },

    {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth",
        redirect: true
    },

    {
        path: "/forgot-password",
        name: "Esqueci a senha",
        component: ForgotPassword,
        layout: "/auth",
        redirect: true
    },
    {
        path: "/reset-password/:token",
        name: "Resetar a senha",
        component: ResetPassword,
        layout: "/auth",
        redirect: true
    },
    {
        path: "/patient/:id/details",
        name: "Dados do Paciente",
        component: PatientIndex,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/user-profile",
        name: "Meu perfil",
        component: UserProfile,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/change-password",
        name: "Troca de senha",
        component: ChangePasswordForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/upload-cv",
        name: "Upload CV",
        component: UploadCv,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/organizations/:id/usage",
        name: "Uso da organização",
        component: OrganizationUsage,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/ancora",
        name: "Âncora de carreira",
        component: Ancora,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/anamnese",
        name: "Anamnese",
        component: Anamnese,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/forms/:form",
        name: "user-form",
        component: UserFormData,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/antropometria",
        name: "Antropometria",
        component: Antropometria,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/resultado-antropometria",
        name: "Resultados - Antropometria",
        component: ResultadoAntropometria,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/plano-alimentar",
        name: "Plano Aliementar",
        component: PlanoAlimentarIndex,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/plano-alimentar",
        name: "Plano Alimentar",
        component: PatientPlanMeal,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/refeicao/:refeicao/plano/:plano/alimentos",
        name: "Alimentos",
        component: LancamentoAlimento,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/patient/:id/messages",
        name: "Alimentos",
        component: UserMessages,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/email-templates/edit/:id",
        name: "Edição de  template de  e-mail",
        mini: "TE",
        component: EmailTemplateForm,
        layout: "/admin",
        redirect: true,
    },
    {
        path: "/email-templates/new",
        name: "Novo template de e-mail",
        mini: "TE",
        component: EmailTemplateForm,
        layout: "/admin",
        redirect: true,
    },
    {
        path: "/patient/:id/health-index",
        name: "Alimentos",
        component: HealthIndexForm,
        layout: "/admin",
        redirect: true
    },
];
routesNutrideck.map(route => routes.push(route));

export default routes;
