import React from "react";
import {Link, Redirect} from "react-router-dom";
import queryString from 'query-string';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";

import apiRequest from "lib/Api";
import {
    saveAuth,
    getUserData,
    getRedirectUrl,
    clearRedirect,
    shouldCompleteProfile
} from "lib/Auth";
import InputError from "views/components/InputError";

class AgendarPrimeiraConsulta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            working: false,
            errors: {},
            loggedIn: !!localStorage.getItem('accessToken'),
            completeProfile: false
        }
    }

    render() {
       return (
           <div className="login-page">
               <Container>
                   <Row>
                       <Col className="ml-auto mr-auto" lg="6" md="6">
                           <Form action="" className="form" method="POST"
                                 onSubmit={this.handleSubmit}
                           >
                               <Card className="card-login text-center">
                                   <CardHeader>
                                       <CardHeader>
                                           <h3 className="header text-center">Escolha o horario para sua conversa com</h3>
                                           <div className="social">
                                               Nutricionista
                                           </div>
                                       </CardHeader>
                                   </CardHeader>
                               </Card>
                           </Form>
                       </Col>
                   </Row>
               </Container>
               <div
                   className="full-page-background"
                   style={{
                       backgroundImage: `url(${require("assets/img/bg/background-login.png")})`
                   }}
               />
           </div>

       )

    }
}
export default AgendarPrimeiraConsulta;
