import React from 'react';

class InputError extends React.Component {
    render() {
        const {name, errors} = this.props;
        if (!errors[name]) {
            return '';
        }

        let errorText = errors[name];
        if ( typeof errors[name] == 'object') {
            errorText = errors[name].join(" ");
        }
        return (
            <label className="error">
                {errorText}
            </label>
        );
    }
}

InputError.defaultProps = {
    name: '',
    errors: {}
}

InputError.convertToHasClass = function(errors) {
    if (!errors) {
        return {};
    }
    let result = {};
    for (let [key, error] of Object.entries(errors)) {
        if (Array.isArray(error)) {
            error = error[0];
        }
        result[key] = error ? 'has-danger' : '';
    }
    return result;
}

export default InputError;
