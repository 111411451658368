import React from "react";
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create sweet alerts
import {calendarMessages} from "../../../lib/Locale";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import HeaderProntuario from "../../../components/Header/HeaderProntuario";
import uuid from "react-uuid";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import Select from "react-select";

const localizer = BigCalendar.momentLocalizer(moment);
const DEFAULT_VIEW = 'week';


const FINISHED = 'finished';
const PENDING = 'pending';
const serviceStatusOptions = [
    {label: 'Não-finalizado', value: PENDING},
    {label: 'Finalizado', value: FINISHED}
];

class UserMessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: '',
            user: {
                name: ''
            },
            emails: [],
            events: [],
            working: false,
            errors: {},
            openModalEvents: false,
            currentBlock: {
                start: ""
            },
            categories: [],
            category: {
                value: '',
                label: 'Todas as categorias'
            },
            filter: '',
            showDeleteConfirmation: false,
            selectedEvent: null
        }
    }

    get id() {

        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    async componentWillMount() {
        await this.fetchCategories();
        await this.requestData(1);
        if (this.id) {
            await this.getUserData();
            await this.getUserMessages();
        }
    }

    async fetchCategories() {
        return await apiRequest("/email-templates-categories", {method: "GET"})
            .then(response => {
                let categories = response.data.data.map(category => {
                    return {
                        value: category,
                        label: category
                    }
                });

                categories.unshift({
                    value: '',
                    label: 'Todas as categorias'
                })
                this.setState({categories});
            });
    }

    getUserMessages = async () => {
        await apiRequest(`/user-notifications/user/${this.id}`, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                let events = data.map(event => {
                    console.log(event)
                    let start = moment(event.send_at);
                    let endData = start.clone();
                    let end = endData.add('+1 hour');
                    return {
                        start: start.toDate(),
                        end: end.toDate(),
                        uuid: event.uuid,
                        title: event.email.subject,
                        email: event.email
                    };
                });
                this.setState({events: events});
            });
    }

    requestData = async page => {
        let data = {
            page: page || 1,
            search: [],
        };


        data.search.push('type:2');
        if (this.state.filter !== '') {
            data.search.push(`text:${this.state.filter}`);
        }
        if (this.state.category.value !== '') {
            data.search.push(`category:${this.state.category.value}`);
        }
        data.search = data.search.join(';');
        await apiRequest("/email-templates", {method: "GET", data})
            .then(response => {
                let {data, meta} = response.data;
                this.setState({emails: data, meta});
            });
    }

    getUserData = async () => {
        const response = await apiRequest(`/patient/${this.id}`, {method: "GET"})
        const user = response.data.data;

        let dateBegin = moment(user.created_at);
        let userState = {
            name: user.name,
            pictureUrl: user.pictureUrl,
            date_start: dateBegin,
            program_status: user.program_status
        }
        this.setState({user: userState});
    }

    saveEvent = async (message) => {

        let data = {...message};
        data.user_id = this.id;
        data.start = moment(data.start).format('YYYY-MM-DD HH:mm');
        await apiRequest("/user-notifications", {data, method: "POST"}).then(response => {
            this.getUserMessages();
            this.setState({
                openModalEvents: false,
            })
        }).catch(err => {
            this.props.notify({type: 'error', message: err.data.message});
        })
    }

    addNewBlock = (slotInfo) => {

        let now = Date.now();
        if (now > slotInfo.start) {
            this.props.notify({type: 'error', message: 'Não é possível agendar em data anterior.'});
            return;
        }
        let block = {
            title: '',
            start: slotInfo.start,
            end: slotInfo.end,
            weekday: moment(slotInfo.start).format('E')  // ISO day of week
        };
        this.setState({
            openModalEvents: true,
            currentBlock: block
        });
    }

    toggleModalEvents = () => {
        this.setState({openModalEvents: !this.state.openModalEvents});
    }

    selectBlock = (event) => {
        this.setState({deleteEmailId: event.uuid, selectedEvent: event});
        this.toggleConfirmation(true);
    }

    handleSelect = (name, value) => {
        this.setState({[name]: value});
        setTimeout(() => {
            this.requestData(1);
        }, 400)
    }

    filterChange = (event) => {
        let value = event.target.value;
        this.setState({filter: value});
    }

    executeFilter = (event) => {
        event.preventDefault();
        this.requestData();
    }

    addMessage = (email) => {
        let block = this.state.currentBlock;
        block.title = email.subject + '(' + email.category + ')';
        block.uuid = uuid();
        block.email_template_id = email.id;
        this.saveEvent(block);

    }

    deleteEmail = () => {
        const id = this.state.deleteEmailId;
        apiRequest("/user-notifications/" + this.state.deleteEmailId, {method: "DELETE"})
            .then(() => {
                this.props.notify({type: 'success', message: 'Mensagem removida com sucesso'});

                let events = this.state.events.filter(email => email.uuid !== id);
                this.setState({events: events, selectedEvent: null});
            })
            .catch(() => {
                this.props.notify({type: 'error', message: 'Não foi possível remover a mensagem.'});
            });
    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    ///
    // Render functions
    //
    renderMessages = () => {
        if (this.state.emails.length === 0) {
            return  (
                <Row>
                    <Col sm={12} md={12} className={'text-center'} >
                        <h3>
                        <i className="fa fa-times-circle"/> {' ' }
                            {this.state.filter === '' && this.state.category.value === '' && 'Não foram encontradas mensagens'}
                            {(this.state.filter !== '' || this.state.category.value !== '')  && 'Não foram encontradas mensagens com os filtros informados'}

                        </h3>
                    </Col>
                </Row>
            )
        }
        return (
            <Row>
                {this.state.emails.map(email => {
                    return (
                        <Col sm={12} md={4} key={email.id}>
                            <Card>
                                <CardHeader style={{textAlign: 'left'}}>
                                    <CardTitle tag={'div'}>
                                        <b>{email.subject}</b>
                                    </CardTitle>
                                    <CardSubtitle tag={'span'}>{email.category}</CardSubtitle>
                                </CardHeader>
                                <hr/>
                                <CardBody style={{
                                    backgroundColor: '#F0F0F0 !important',
                                    maxHeight: '100px', height: '100px', overflow: 'scroll'
                                }}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: email.body
                                        }}
                                        style={{backgroundColor: '#efefefe'}}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <Button color="primary" onClick={() => {
                                        this.addMessage(email)
                                    }}>
                                        Selecionar
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    renderFilters() {
        return (
            <>
                <Row style={{textAlign: 'left'}}>
                    <Label md="1"><b>Filtros</b></Label>
                    <Col md="4">
                        <FormGroup>
                            <Input placeholder="Assunto ou conteúdo" type="text"
                                   onChange={this.filterChange} value={this.state.filter}
                                   onKeyPress={event => event.which === 13 && event.preventDefault()}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup>
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="type"
                                isDisabled={this.state.disabled}
                                value={this.state.category}
                                onChange={(evt) => {
                                    this.handleSelect('category', evt);
                                }}
                                options={this.state.categories}
                                placeholder="selecione uma Categoria"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="1">
                        <Button color="info" type="button" style={{margin: '1px -15px'}}
                                onClick={this.executeFilter}
                        ><i className="fa fa-search"/>
                        </Button>
                    </Col>
                    <hr/>
                </Row>
            </>
        )
    }

    getMessageEvent = () => {
        if (!this.state.selectedEvent) {
            return;
        }
        console.log(this.state.selectedEvent)
        let message = `<b>Assunto:</b> ${this.state.selectedEvent.email.subject}` + "<br />";
        message += `<b>Categoria:</b> ${this.state.selectedEvent.email.category}` + "<br />";
        message += `<b>Mensagem:</b> ${this.state.selectedEvent.email.body}` + "<br />";
        message += `<hr />`;
        message += `<b>Deseja excluir esta mensagem?</b>`;
        return message;
    }

    render() {
        let link = `/admin/patient/${this.id}/details`;
        let textoLink = 'Voltar para o perfil do paciente.';
        let formats = {
            dayHeaderFormat: 'dddd'
        };
        let min = new Date()
        min.setHours(6, 0);
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <HeaderProntuario
                                        title={`Mensagens para ${this.state.user.name}`}
                                        link={link}
                                        buttonText={textoLink}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <BigCalendar
                                        selectable
                                        interval='60'
                                        className={`appointment-list-calendar ${this.state.view}-view`}
                                        localizer={localizer}
                                        culture='pt-BR'
                                        step={60}
                                        min={min}
                                        events={this.state.events}
                                        defaultView={DEFAULT_VIEW}
                                        timeslots={1}
                                        formats={formats}
                                        onSelectSlot={slotInfo => this.addNewBlock(slotInfo)}
                                        onDoubleClickEvent={slotInfo => this.selectBlock(slotInfo)}
                                        scrollToTime={new Date(1970, 1, 1, 6)}
                                        defaultDate={new Date()}
                                        messages={calendarMessages}
                                        views={['week']}
                                        onView={this.onView}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={this.state.openModalEvents} size="xl" scrollable={"true"} className={'modal-fullscreen'}
                       toggle={this.toggleModalEvents}>
                    <ModalHeader style={{textAlign: 'left'}} toggle={this.toggleModalEvents}>
                        <Row>
                            <Col>
                                <h5>Selecionar mensagem</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Envio em: </b>
                                {moment(this.state.currentBlock.start).format('dddd, MMMM DD/YYYY [as] HH:mm')}
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody style={{maxHeight: '70vh', height: '70vh', overflow: 'scroll'}}>
                        {this.renderFilters()}
                        {this.renderMessages()}
                    </ModalBody>
                    <ModalFooter>
                        <Row className='text-center'>
                            <Col className='text-center'>
                                <Button color="default" onClick={this.toggleModalEvents}>
                                    Fechar
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
                <ConfirmModal isHtml={true}
                              message={this.getMessageEvent()}
                              class="modal-md"
                              onConfirm={() => this.deleteEmail()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />
            </>
        )
    }
}

export default withNotify(UserMessages);
