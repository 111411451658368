import React from "react";
import {Card, CardHeader, CardTitle, Col, Row} from "reactstrap";
import NutrideckCard from "../../../components/Cards/NutrideckCard";

import logoBring from "../../../assets/img/logos/partners/bring.png";
import logoLivUp from "../../../assets/img/logos/partners/livup.png";
import logoVitaminese from "../../../assets/img/logos/partners/vitaminese.png";

class Partners extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Parceiros Nutrideck</CardTitle>
                                    <br/>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <NutrideckCard
                                logo={logoBring}
                                title="Bring"
                                description="<b>Bring! Lista de Compras</b> é um app que serve para você montar <b>listas de compras</b>, evitando se esquecer de algum produto quando for ao supermercado. O app ainda permite que você compartilhe a lista com quem quiser.<br /><br />App gratuito para <a href='https://play.google.com/store/apps/details?id=ch.publisheria.bring&hl=pt_BR&gl=US'><b>Android</b></a> e <a href='https://apps.apple.com/br/app/bring-lista-de-compras/id580669177'><b>iOS</b></a>"
                                isHtml={true}
                                heightContent='220px'
                            />
                        </Col>
                        <Col md={4}>
                            <NutrideckCard
                                logo={logoLivUp}
                                title="Liv up"
                                description="A Liv Up é uma plataforma para quem se importa com comida gostosa que faz bem pro mundo e pra todo mundo. Delivery de comida congelada. Entregas com dia e hora agendados com diversas opções pra você se alimentar bem.<br/><br/>Com a Nutrideck você tem vantagens!!!<br/><br/>30% de desconto na PRIMEIRA COMPRA<br/>Cupom: nutrideck<br/><br/>10% de desconto para as próximas 2 COMPRAS (válido para 2 usos por CPF).<br/>Cupom: nutrideck10<br/><br/>Acesse o site e aproveite!"
                                isHtml={true}
                                heightContent='160px'
                                titleButton="Acessar"
                                routeButton="https://www.livup.com.br/"
                            />
                        </Col>
                        <Col md={4}>
                            <NutrideckCard
                                logo={logoVitaminese}
                                title="Vitamine-se"
                                description="Vitamine-se é um jeito inteligente de se vitaminar com os ingredientes certos para você!<br/><br/>Aqui na Nutrideck você tem um voucher de R$ 50,00 para compra de qualquer produto.<br/>Cupom: nutri50<br/><br/>Acesse o site e aproveite!"
                                isHtml={true}
                                heightContent='160px'
                                titleButton="Acessar"
                                routeButton="https://vitaminese.com.br/"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Partners;
