import React from 'react'

import Spinner from 'react-spinkit'

const Loading = ({ loading, message }) => {
    return  (
        <div className='overlay-content' id='Teste' style={{display:"none"}}>
            <div className='wrapper'>
                <Spinner
                    name='cube-grid'
                    fadeIn='none'
                    color='green'
                    style={{width: '60px', height:'60px'}}
                />
                <span className='message'>
                    {message}
                </span>
            </div>
        </div>
    )
}

export default Loading
