import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      working: false,
      errors: {}
    }
  }

  clearForm() {
    this.setState({
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      errors: {}
    });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value
    });

    let newErrors = {...this.state.errors, [name]: ''};
    this.setState({errors: newErrors});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {old_password, new_password, new_password_confirmation} = this.state;
    let data = {
      old_password,
      new_password,
      new_password_confirmation
    };
    
    this.setState({working: true});
    
    let request = apiRequest("/auth/change-password", {data, method: 'POST'})
    
    request.then(response => {
        this.clearForm();
        this.props.notify({type: 'success', message: 'Senha alterada com sucesso'});
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }
  
  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);
    
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Alterar senha</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">Senha atual</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.old_password}>
                          <Input
                            value={this.state.old_password}
                            name="old_password"
                            onChange={this.handleChange}
                            type="password"
                          />
                          <InputError name="old_password" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Senha nova</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.new_password}>
                          <Input
                            value={this.state.new_password}
                            name="new_password"
                            onChange={this.handleChange}
                            type="password"
                          />
                          <InputError name="new_password" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Confirme senha nova</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.new_password_confirmation}>
                          <Input
                            value={this.state.new_password_confirmation}
                            name="new_password_confirmation"
                            onChange={this.handleChange}
                            type="password"
                          />
                          <InputError name="new-new_password_confirmation" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(ChangePasswordForm);
