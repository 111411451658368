// TODO use react intl instead
function toCurrency(value) {
  if (typeof value == 'string') {
    value = Number(value);
  }
  return value.toLocaleString('pt-BR', {currency: 'BRL', style: 'currency'});
}

function formatCnpj(value) {
  return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

function formatPhone(value) {
  return value.replace(/^(\d{2})(\d+)$/, "($1) $2");
}

function removeMask(value) {
  return value.replace(/[\.\-\/]+/g, '');
}

export {toCurrency, formatCnpj, formatPhone, removeMask};