import React from "react";
import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Link, Redirect} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";
import {getUserData} from "lib/Auth";

import defaultAvatar from "assets/img/default-avatar.png";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button, Modal, ModalHeader, ModalBody, Input, FormGroup, Label, Table, ModalFooter
} from "reactstrap";
import InputError from "../../components/InputError";
import ButtonChat from "../../../components/ButtonChat/ButtonChat";

class AppointmentShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: false,
      userData: getUserData(),
      redirect: '',
      openModalPopUp: false,
      guest: '',
      guests: [],
      errors: {},
      working: false
    };
    for (const alert of this.alerts) {
      this.state[alert] = '';
    }
  }

  get id() {
    if (this.props.appointment_id) {
      return this.props.appointment_id;
    }

    if (this.props.match.params) {
      return this.props.match.params.id;
    }

    return null;
  }

  get embeded() {
    if (this.props.embeded) {
      return this.props.embeded;
    }
    return false;
  }

  componentDidMount() {
    this.fetchAppointment();
  }

  fetchAppointment() {
    return apiRequest("/appointments/" + this.id, {method: "GET"})
        .then(response => this.setState({appointment: response.data.data}))
        .catch(err => this.state({error: err.data.errors[0]}));
  }

  downloadIcs = (e) => {
    e.preventDefault();
    apiRequest("/appointments/" + this.id + "/ics", {method: "GET"}, {blob: true})
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invite.ics');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
  }

  performAction(action, successMsg, errorMsg, data) {
    this.setState({working: true});

    return apiRequest(`/appointments/${this.id}/${action}`, {method: 'PUT', data}).then(response => {
        this.props.notify({type: 'success', message: successMsg});
        return response.data.data;
      })
      .catch(err => {
        let errorMessage = errorMsg;
        if (err.data && err.data.errors.length > 0) {
          errorMessage = err.data.errors[0];
        }
        this.props.notify({type: 'error', message: errorMessage});
        throw err;
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  cancelAppointment = (notes) => {
    return this.performAction(
      'cancel',
      'Agendamento foi cancelado',
      'Não foi possível cancelar agendamento. Tente mais tarde.'
    );
  }

  startAppointment = () => {
    return this.performAction(
      'start',
      'Agendamento foi iniciado',
      'Não foi possível iniciar agendamento. Tente mais tarde.'
    );
  }

  completeAppointment = (notes) => {
    return this.performAction(
      'complete',
      'Agendamento foi finalizado',
      'Não foi possível finalizar agendamento. Tente mais tarde.',
      {notes}
    );
  }

  markMissedAppointment = (notes) => {
    return this.performAction(
      'missed',
      'Agendamento foi marcado como não-realizado',
      'Não foi possível marcar como não-realizado. Tente mais tarde.',
      {notes}
    );
  }

  describeStatus(appointment) {
    switch(appointment.situation) {
      case 'pending':
      case 'approved':
        return 'Atendimento agendado';
      case 'started':
        return 'Iniciou atendimento';
      case 'done':
        return 'Atendimento realizado';
      case 'canceled':
        return 'Atendimento cancelado';
      case 'missed':
        return 'Atendimento não-realizado';
    }
  }

  colorStatus(appointment) {
    switch(appointment.situation) {
      case 'pending':
      case 'approved':
        return 'info';
      case 'started':
        return 'success';
      case 'done':
        return 'success';
      case 'canceled':
        return 'danger';
      case 'missed':
        return 'warning';
    }
  }

  get buttons() {
    return {
      cancel: () => (
        <Button key="cancel" color="danger" onClick={this.confirmCancel}>
          <i className="fa fa-times" />
          Cancelar
        </Button>
      ),
      start: () => (
        <Button key="start" color="info" onClick={this.confirmStart}>
          <i className="fa fa-play" />
          Iniciar Atendimento
        </Button>
      ),
      complete: () => (
        <Button key="complete" color="info" onClick={this.confirmComplete}>
          <i className="fa fa-check" />
          Finalizar
        </Button>
      ),
      markMissed: () => (
        <Button key="markMissed" color="warning" onClick={this.confirmMarkMissed}>
          <i className="fa fa-exclamation" />
          Não realizada
        </Button>
      ),
      chat: appointment => this.buttonReStart(),

      reschedule: () => (
        <Button key="reschedule" color="info" onClick={this.rescheduleAppointment}>
          <i className="fa fa-calendar" />
          Re-agendar
        </Button>
      ),

    }
  }

  buttonReStart = () => {
    if (!isMobile) {
      return (
          <Button id="btnAtendimento" key="chat" color="primary" tag={Link} to={`/chat/appointments/${this.id}/chat`}
                  target={`${this.id}_chat`}
          >
            <i className="fa fa-comments-o" />
            Retomar Atendimento
          </Button>
      )
    } else {
      return (
          <Button id="btnAtendimento" key="chat" color="primary" onClick={this.comecarConsulta}>
            <i className="fa fa-comments-o"/>
            Retomar Atendimento
          </Button>
      )
    }
  }

  comecarConsulta = () => {
    this.props.history.push(`/admin/appointment/${this.id}/mobile`);
  }

  onlineChat = () => {

    let user = this.state.userData;
    if (this.state.userData.role === 'specialist') {
      user = {
        id: this.state.appointment.user_id,
        name: this.state.appointment.user_name
      }
    }

    return (
        <ButtonChat
            user={user}
            label="Mensagens"
        />
    )

  }

  get stateActions() {
    let defaultActions = {
      canceled: [],
      done: [],
      missed: [],
      started: []
    };
    let actions = {};

    if (this.state.userData.role === 'patient') {
      actions = {
        approved: ['cancel', 'start'],
      };
    }
    else if (this.state.userData.role === 'specialist' || this.state.userData.role === 'super_admin') {
      actions = {
        approved: ['cancel', 'start', 'markMissed'],
        started: ['complete', 'cancel', 'markMissed'],
        missed: ['cancel'],
        canceled: ['reschedule']
      };
    }

    if (this.state.appointment.product_rules && this.state.appointment.product_rules.enable_guest) {
      actions.approved.push('guests');
    }

    // return {...defaultActions, ...actions, ...product_rules};
    return {...defaultActions, ...actions};
  }

  renderButtons() {
    let {appointment} = this.state;
    let {situation} = appointment;
    let buttons = this.buttons;

    let actions = this.stateActions[situation];
    if (situation === 'started') {
      actions.push('chat');
    }
    return actions.map(action => buttons[action](appointment));
  }

  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);

    const appointment = this.state.appointment;
    if (!appointment) {
      return this.renderEmpty("Carregando...");
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    let className = 'content appointment-show-container';
    let labels = true;
    let sizes = {
      md: 12,
      lg:6
    }
    let showSpecialist = true;
    if (this.embeded) {
      labels = false;
      showSpecialist = false;
      className = 'content card-container';
      sizes = {
        md: 12,
        lg: 12
      }
    }
    return (
      <>
        {this.state.openModalPopUp}
        {this.alerts.map(alert =>
          this.state[alert]
        )}
        <div className={className}>
          <Row>
            <Col md={sizes.md} lg={sizes.lg}>
              <Card>
                <CardHeader style={{display:(labels ? '': 'none')}}>
                  <h5>
                    Informações do agendamento
                    <br/>
                    <small>
                      <a href='#' onClick={this.downloadIcs}>
                        <i className='fa fa-calendar'></i> Download .ics
                      </a>
                    </small>
                  </h5>
                </CardHeader>
                <CardBody>
                  <dl>
                    <dt>Usuário</dt>
                    <dd>{appointment.user_name}</dd>
                    <dt style={{display:(labels ? '': 'none')}}>Especialista</dt>
                    <dd style={{display:(labels ? '': 'none')}}>{appointment.specialist_name}</dd>
                    <dt>Quando</dt>
                    <dd>{moment(appointment.datetime_start).format('LLL')}</dd>
                    <dt style={{display:(labels ? 'none': '')}}>Tipo</dt>
                    <dd style={{display:(labels ? 'none': '')}}>{appointment.service_type === 'personal_meeting' ? 'Presencial' : 'Online'} {}</dd>
                    <dt>Status</dt>
                    <dd className={'text-' + this.colorStatus(appointment)}>
                      <b>{this.describeStatus(appointment)}</b>
                    </dd>
                  </dl>
                </CardBody>
                <CardFooter>
                  {this.renderButtons()}
                  {this.onlineChat()}
                </CardFooter>
              </Card>
            </Col>

            {appointment.notes && (

            <Col md="12" lg="6">
              <Card className="card-testimonial">
                <CardBody>
                  <div className="icon icon-primary">
                    <i className="fa fa-quote-right" />
                  </div>
                  <p className="card-description">
                    {appointment.notes}
                  </p>
                </CardBody>
                <CardFooter>
                  <CardTitle tag="h4">{appointment.specialist_name}</CardTitle>
                  <div className="card-avatar">
                    <a href="#" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img"
                        src={appointment.specialist_picture || defaultAvatar}
                      />
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </Col>

            )}

          </Row>
        </div>
      </>
    )
  }

  handleChange = (event) => {
    this.setState({'guest': event.target.value})
  }

  renderEmpty(message) {
    return (
      <div className="content">
        <Row>
          <Col md="12" lg="6">
            <Card>
              <CardHeader>
                <h5>{message}</h5>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }


  confirmCancel = () => {
    this.showConfirmation(
      'cancel',
      {
        title: 'Cancelar atendimento',
        confirmBtnText: "Confirmar cancelamento",
        cancelBtnText: "Manter atendimento",
        confirmBtnBsStyle: 'danger'
      },
      'Deseja mesmo cancelar este atendimento?'
    );
  }

  confirmStart = () => {
    this.showConfirmation(
      'start',
      {
        title: 'Iniciar atendimento'
      },
      'Deseja iniciar este atendimento?'
    );
  }

  confirmComplete = () => {
    this.showConfirmation(
      'complete',
      {
        type: 'input',
        title: 'Finalizar atendimento',
        placeholder: 'Insira aqui notas sobre o atendimento'
      }
    );
  }

  confirmMarkMissed = () => {
    this.showConfirmation(
      'markMissed',
      {
        type: 'input',
        title: 'Não-realizado',
        placeholder: 'Você pode descrever aqui os motivos de não ter sido realizado',
        confirmBtnBsStyle: 'danger'
      }
    );
  }

  rescheduleAppointment = () => {
    let {appointment: ap} = this.state;
    let url = `/admin/appointments/reschedule/${ap.id}`;
    this.setState({redirect: url});
  }

  get alerts() {
    const actions = ['cancel', 'start', 'markMissed', 'complete', 'working'];
    return actions.map(action => action + 'Alert');
  }

  showConfirmation = (action, props, content = '') => {

    let actionAlertVar = action + 'Alert';
    let hide = () => {
      this.setState({[actionAlertVar]: null});
    }

    // TODO showConfirm vs working...

    let defaultProps = {
      type: 'warning',
      inputType: 'textarea',
      onCancel: () => hide(),
      confirmBtnBsStyle: "info",
      cancelBtnBsStyle: "default",
      confirmBtnText: "Confirmar",
      cancelBtnText: "Cancelar",
      showCancel: true,
      onConfirm: (input) => {
        hide();
        this.showWorking();
        this[action + 'Appointment'](input)
          .then(appointment => this.setState({appointment}))
          .catch(err => {})
          .finally(() => {
            this.hideWorking()

            if (action === 'start' && isMobile) {
              this.props.history.push(`/admin/appointment/${this.state.appointment.id}/mobile`);
              return;
            }

            if (action === 'start') {
              const windowChat = window.open(`/chat/appointments/${this.state.appointment.id}/chat`, '_blank');
              if(!windowChat || windowChat.closed || typeof windowChat.closed==='undefined') {
                this.setState({
                  openModalPopUp: (
                      <ReactBSAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          title="Aviso!"
                          onConfirm={() => this.setState({openModalPopUp: null })}
                          onCancel={() => this.setState({openModalPopUp: null })}
                          confirmBtnBsStyle="primary"
                      >
                        Seu navegador está configurado para não permitir a abertura de PopUp. Você deve clicar no botão em Retomar Atendimento.
                      </ReactBSAlert>
                  )
                });
              }
              if (windowChat) {
                windowChat.focus();
              }
            }
          });
      }
    };
    props = {...defaultProps, ...props};

    this.setState({
      [actionAlertVar]: (
        <ReactBSAlert {...props} >
          {content}
        </ReactBSAlert>
      )
    });
  }

  showWorking = () => {
    this.showConfirmation('working',
      {showConfirm: false, showCancel: false, disabled: true},
      'Aguarde um momento...'
    );
  }

  hideWorking = () => {
    this.setState({workingAlert: null});
  }
}

export default withNotify(AppointmentShow);
