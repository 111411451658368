import React from "react";
import {Redirect} from "react-router-dom";
import queryString from 'query-string';

// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";

import apiRequest from "lib/Api";
import {clearRedirect, getRedirectUrl, saveAuth} from "lib/Auth";
import withNotify from "lib/NotificationWrapper";
import InputError from "views/components/InputError";
import DateField from "../../components/Date/DateField";
import FormGroupLabel from "../components/FormGroupLabel";
import Select from "react-select";
import moment from "moment";


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            birth: '',
            gender: '',
            password: '',
            address: '',
            occupation: '',
            phone: '',
            nutricionist: '',
            password_confirmation: '',
            seenTerms: true,
            checkedTerms: false,
            working: false,
            errors: {},
            redirect: null,
            loggedIn: !!localStorage.getItem('accessToken')
        }
    }

    get nutritionist() {

        if (this.props.match.params.id_nutri) {
            return this.props.match.params.id_nutri;
        }
        return null;
    }

    async componentDidMount() {
        let data = {
            method: "GET"
        }
        if (!this.nutritionist) {
            this.setState({redirect: '/auth/login'})
        }

        document.body.classList.toggle("register-page");
        await apiRequest(`/nutritionist/check/${this.nutritionist}`, data)
            .then(response => {
                this.setState({nutritionist : response.data});
            }).catch(e => {
                this.setState({redirect: '/auth/login'})
            })

    }

    componentWillUnmount() {
        document.body.classList.toggle("register-page");
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({[name]: value});

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let {name, email, password, password_confirmation, birth, gender} = this.state;
        let data = {name, email, password, password_confirmation, birth, gender};
        if (birth) {
            if (moment.isMoment(birth)) {
                data.birth = birth.format('YYYY-MM-DD');
            } else {
                data.birth = moment(birth, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
        }
        data.gender = data.gender.value
        data.nutritionist = this.nutritionist;
        this.setState({working: true});


        apiRequest("/guest", {data})
            .then(response => {
                saveAuth(response.data);
                this.setState({loggedIn: true});
            })
            .catch(err => {
                this.setState({errors: err.data.errors || {password: 'E-mail ou senha inválidos'}});
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    getRedirectUrl() {
        let qs = this.props.location.search || '';
        if (qs) {
            let params = queryString.parse(qs);
            if (params.redirect == 1) {
                return getRedirectUrl();
            }
        }
        return '/admin/dashboard';
    }

    terms = e => {
        e.preventDefault();
        window.open("https://nutrideck.com.br/termos-e-condicoes/", "blank");
        this.setState({seenTerms: true});
    }

    checkTerms = e => {
        let {checked} = e.target;
        if (!this.state.seenTerms) {
            this.props.notify({
                type: 'warning',
                message: 'Você ainda não leu os termos e condições da nossa plataforma.'
            });
        } else {
            this.setState({checkedTerms: true});
        }
    }

    render() {
        if (this.state.loggedIn) {
            let redirectTo = this.getRedirectUrl();
            clearRedirect();
            return <Redirect to={redirectTo}/>;
        }
        if (this.state.redirect) {
            return  <Redirect to={this.state.redirect}/>;
        }

        let hasError = InputError.convertToHasClass(this.state.errors);

        return (
            <div className="register-page">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="6" md="6">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card className="card-signup p-4">
                                    <Alert color="secondary" style={{color: '#000000'}}>
                                        <Row>
                                            <Col md={1} className="align-middle">
                                                <i className="fas fa-info-circle" style={{fontSize: '15pt'}}/></Col>
                                            <Col md={10} style={{fontSize: '12px', textAlign: "left"}}>
                                                Cadastro exclusivo para Pacientes Nutrideck. Se você é uma nutricionista
                                                e deseja trabalhar com a gente, mande um email
                                                para <b>contato@nutrideck.com.br</b>
                                            </Col>
                                        </Row>
                                    </Alert>
                                    <CardHeader>
                                        <CardTitle tag="h4">Antes de continuar, informe seus dados.</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroupLabel className={hasError.name}>

                                                    <Input id="name" placeholder="Nome" type="text" name="name"
                                                           onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="name">Nome</label>
                                                    <InputError name="name" errors={this.state.errors}/>
                                                </FormGroupLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroupLabel className={hasError.email}>

                                                    <Input id='email' placeholder="E-mail" type="email" name="email"
                                                           onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="email">E-mail</label>
                                                    <InputError name="email" errors={this.state.errors}/>
                                                </FormGroupLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroupLabel className={hasError.birth}>
                                                    <DateField
                                                        name="birth"
                                                        id="birth"
                                                        label="Data de Nascimento"
                                                        value={this.state.birth}
                                                        placeholder="Data de Nascimento"
                                                        onChange={value =>
                                                            this.handleChange('birth', value)
                                                        }
                                                    />

                                                    <InputError name="birth" errors={this.state.errors}/>
                                                </FormGroupLabel>
                                            </Col>
                                            <Col><FormGroup className={hasError.state}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="gender"
                                                    id="gender"
                                                    textFieldProps={{
                                                        label: 'Genero',
                                                        InputLabelProps: {
                                                            shrink: true,
                                                        },
                                                    }}
                                                    placeholder={'Genero'}
                                                    value={this.state.gender}
                                                    onChange={value =>
                                                        this.handleChange("gender", value)
                                                    }
                                                    options={[
                                                        {
                                                            value: "Masculino",
                                                            label: "Masculino",
                                                        },
                                                        {
                                                            value: "Feminino",
                                                            label: "Feminino",
                                                        }
                                                    ]}
                                                />
                                                <InputError name="gender" errors={this.state.errors}/>

                                            </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>

                                                <FormGroupLabel className={hasError.password}>
                                                    <label htmlFor="senha">Senha</label>
                                                    <Input id="senha" placeholder="Senha" type="password"
                                                           name="password"
                                                           onChange={this.handleChange}
                                                    />

                                                    <InputError name="password" errors={this.state.errors}/>
                                                </FormGroupLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroupLabel className={hasError.password_confirmation}>

                                                    <Input id={"password_confirmation"} placeholder="Confirme a senha"
                                                           type="password"
                                                           name="password_confirmation"
                                                           onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="password_confirmation">Confirme a sua Senha</label>
                                                    <InputError name="password_confirmation"
                                                                errors={this.state.errors}/>
                                                </FormGroupLabel>
                                            </Col>
                                        </Row>
                                        <FormGroup check className="text-left">
                                            <Label check>
                                                <Input type="checkbox" onChange={this.checkTerms}
                                                       checked={this.state.checkedTerms}
                                                />
                                                <span className="form-check-sign"/>
                                                Li e concordo com os {" "}
                                                <b><a onClick={this.terms}>
                                                    termos e condições
                                                </a></b>
                                                .
                                            </Label>
                                        </FormGroup>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            className="btn"
                                            color="primary"
                                            disabled={this.state.working || !this.state.checkedTerms}
                                        >
                                            Continuar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/fundo-registar.png")})`,
                        backgroundColor: '#B09631',
                        backgroundSize: 'cover'
                    }}
                />
            </div>
        )
            ;
    }
}

export default withNotify(Register);
