import React from "react";
import {Redirect} from "react-router-dom";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

import apiRequest from "lib/Api";
import {calendarMessages} from "lib/Locale";
import withNotify from "lib/NotificationWrapper";

// reactstrap components
import {Button, Card, CardBody, Col, FormGroup, Row} from "reactstrap";
import {getUserData} from "../../../lib/Auth";
import InputError from "../../components/InputError";
import Select from "react-select";

const localizer = BigCalendar.momentLocalizer(moment);
const DEFAULT_VIEW = 'agenda';
const DEFAULT_DURATION = 30;

let initDate = moment().add(2, 'day').toDate();

class AppointmentUserCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slots: [],
            allSlotsFounded: [],
            actualMonth: null,
            actualYear: null,
            titleCalendar: '',
            programs: [],
            selectedProgram: null,
            allowAppointment: false,
            slotDuration: DEFAULT_DURATION,
            selectedSpecialist: '',
            nutritionist: null,
            wizard: false,
            redirect: '',
            activeUser: getUserData(),
            confirmAlert: '',
            appointmentId: null,
            working: false,
            view: DEFAULT_VIEW,
            openModalGuest: false,
            confirmDate: '',
            slotSelected: '',
        };
    }

    get userId() {
        return this.state.activeUser.id;
    }

    async componentDidMount() {
        calendarMessages.noEventsInRange = 'não há disponibilidade neste intervalo';
        await this.getPrograms();
        await apiRequest("/user/my-specialist", {method: 'GET'})
            .then(response => {
                this.setState({
                        selectedSpecialist: {value: response.data.data.id},
                        wizard: true,
                        nutritionist: response.data.data
                    },
                    () => this.requestData()
                );
            });
    }

    getPrograms = async () => {

        await apiRequest(`/purchase-services?user_id=${this.state.activeUser.id}`, {
            method: 'GET'
        }).then(success => {
            let programs = []
            for (let program of success.data.data) {
                programs.push({
                    value: program.id,
                    label: program.service.displayName,
                    isDisabled: program.quota === program.usage
                });
            }
            let selectedProgram = programs.find(program => !program.isDisabled);
            const allowAppointment = selectedProgram ? true : false;
            this.setState({programs: programs, selectedProgram: selectedProgram, allowAppointment: allowAppointment});
        }).catch(error => {
            this.props.notify({
                type: 'error',
                message: 'Não foi encontrado nenhum programa para o paciente informado.'
            });
        });
    }

    // to stop the warning of calling setState of unmounted component
    componentWillUnmount() {
        let id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    selectSlot = (slot, disabled = false) => {

        if (!this.state.selectedProgram) {
            return false;
        }

        let hide = () => {
            this.setState({confirmAlert: null});
        }
        let confirmDate = moment(slot.start).format('LLLL');

        this.setState({
            confirmAlert: (
                <ReactBSAlert
                    warning
                    style={{display: "block", marginTop: "-200px"}}
                    title="Confirmação"
                    onConfirm={() => {
                        if (disabled) {
                            return;
                        }
                        this.createAppointment(slot)
                            .finally(() => hide());

                        // call again, to render modal with disabled button
                        this.selectSlot(slot, true);
                    }}
                    onCancel={() => hide()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={disabled ? "Agendando..." : "Sim, agendar!"}
                    disabled={disabled}
                    cancelBtnText="Cancelar"
                    showCancel
                >
                    Deseja agendar para {confirmDate}?
                </ReactBSAlert>
            )
        });
    }

    createAppointment = slot => {

        let data = {
            specialist_id: this.state.selectedSpecialist.value,
            datetime_start: moment(slot.start).format('YYYY-MM-DD HH:mm'),
            datetime_end: moment(slot.end).format('YYYY-MM-DD HH:mm'),
            duration: this.state.slotDuration,
            purchase_service_id: this.state.selectedProgram.value
        };
        this.setState({errors: {}, working: true});

        /**
         * post to create a appointment
         */
        return apiRequest("/appointments", {data, method: 'POST'}).then(response => {
            this.props.notify({type: 'success', message: 'Agendamento realizado com sucesso'});
            this.state.appointmentId = response.data.data.id;
            if (this.state.wizard) {
                setTimeout((response) => {
                    let redirect = '/admin/dashboard';
                    this.setState({redirect});
                }, 500);
            }
        })
            .catch(err => {
                let message = 'Não foi possível agendar. Tente mais tarde.';
                if (err.data && err.data.errors) {
                    message = err.data.errors[0];
                }
                this.props.notify({type: 'error', message});
            })
            .finally(
                response => {
                    this.setState({working: false});
                }
            );
    };

    requestData() {
        let specialist = this.state.selectedSpecialist;
        if (!specialist) {
            return;
        }

        let params = {
            specialist_id: specialist.value,
        };

        return apiRequest('/calendar', {method: 'GET', data: params})
            .then(response => {
                let slots = [];
                let allSlotsFounded = [];
                let {duration} = response.data.data;

                for (let dayData of response.data.data.slots) {
                    for (let block of dayData.blocks) {
                        if (!block.available) {
                            continue;
                        }

                        let datetimeStart = moment(dayData.dayNum + ' ' + block.timeStart);
                        let datetimeEnd = moment(dayData.dayNum + ' ' + block.timeEnd);

                        let newSlot = {
                            start: datetimeStart.toDate(),
                            end: datetimeEnd.toDate()
                        };
                        slots.push(newSlot);
                        allSlotsFounded.push(newSlot)
                    }
                }

                let slotsToShow = []
                let actualMonth = null;
                let actualYear = '';
                if (slots.length > 0) {
                    actualMonth = (slots[0].start.getMonth() + 1);
                    actualYear = slots[0].start.getFullYear();
                    slotsToShow = this.selectSlotToShow(allSlotsFounded, actualMonth)
                }

                this.setState({
                    slots: slotsToShow,
                    actualMonth: actualMonth,
                    actualYear: actualYear,
                    titleCalendar: `${this.getMonthName(actualMonth)} ${actualYear}`,
                    slotDuration: duration,
                    allSlotsFounded: allSlotsFounded
                });
            });
    }

    selectSlotToShow(allSlots, month) {
        let slotsToShow = [];
        if (allSlots.length > 0) {
            allSlots.forEach(slot => {
                if (slot.start >= initDate && month === (slot.start.getMonth() + 1)) {
                    slotsToShow.push(slot);
                }
            });
        }
        return slotsToShow;
    }

    onView = view => {
        this.setState({view});
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        let formats = {
            dayHeaderFormat: 'dddd'
        };

        return (
            <>
                {this.state.confirmAlert}
                <div className="content appointment-create-page">

                    {this.state.selectedSpecialist && (

                        <Row>
                            <Col className="ml-auto mr-auto" md="10">
                                <Card className="card-calendar">
                                    <CardBody>
                                        <BigCalendar
                                            selectable
                                            className={`appointment-create-calendar ${this.state.view}-view`}
                                            localizer={localizer}
                                            culture='pt-BR'
                                            events={this.state.slots}
                                            defaultView={DEFAULT_VIEW}
                                            timeslots={1}
                                            step={this.state.slotDuration}
                                            formats={formats}
                                            messages={calendarMessages}
                                            scrollToTime={new Date(1970, 1, 1, 6)}
                                            defaultDate={initDate}
                                            onView={this.onView}
                                            titleAccessor={event => {
                                                return (
                                                    <React.Fragment>
                                                        <Button className="m-0 btn-schedule" color="primary" size="sm"
                                                                onClick={() => this.selectSlot(event)}
                                                                disabled={!this.state.allowAppointment}
                                                        >
                                                            Agendar
                                                        </Button>
                                                    </React.Fragment>
                                                );
                                            }}
                                            components={{
                                                toolbar: this.renderToolbar
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    )}

                </div>
            </>
        );
    }

    getMonthName(month) {
        if(!month) {
            return '';
        }

        const months = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ]
        return months[(month - 1)];
    }

    renderToolbar = toolbar =>
    {
        const goTo = (action, month) => {
            this.setState({slots: []});

            let year = this.state.actualYear;
            if (month >= 13) {
                month = 1;
                year = (year + 1);
            }
            if (month <= 0) {
                month = 12;
                year = (year - 1);
            }


            let slotsNow = this.selectSlotToShow(this.state.allSlotsFounded, month);
            let titleCalendar = `${this.getMonthName(month)} ${year}`;
            this.setState({slots: slotsNow, actualMonth: month, actualYear: year, titleCalendar: titleCalendar});
            if (slotsNow.length > 0) {
                toolbar.onNavigate(action, slotsNow[0].start);
            }
        }
        const goNext = () => {
            let nextMonth = (this.state.actualMonth + 1);
            goTo('next', nextMonth);
        }
        const goBack = () => {
            let previousMonth = (this.state.actualMonth - 1);
            goTo('prev', previousMonth);
        }

        return (
            <>
                <div className="rbc-toolbar">
                    <div className="rbc-btn-group">
                        <button type="button" className="nutrideck-btn-display-none"
                                style={{borderRadius: '5px', backgroundColor: '#408000'}}
                                onClick={goBack}>&laquo; Anterior
                        </button>
                    </div>
                    <div className="rbc-toolbar-label">
                        {this.state.titleCalendar}
                    </div>
                    <div className="rbc-btn-group">
                        <button type="button" className="nutrideck-mobile-none"
                                style={{borderRadius: '5px', backgroundColor: '#408000'}}
                                onClick={goBack}>&laquo; Anterior
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" style={{borderRadius: '5px', backgroundColor: '#408000'}}
                                onClick={goNext}>Próximo &raquo;</button>
                    </div>
                </div>
                <div>
                    <FormGroup>
                        <label className='nutrideck label'>Etapas do Programa:</label>
                        <Select
                            name="program"
                            value={this.state.selectedProgram}
                            onChange={(value) => {
                                this.setState({selectedProgram: value})
                            }}
                            options={this.state.programs}
                            placeholder="Selecione..."
                        />
                    </FormGroup>
                </div>
            </>
        );
    }
}

export default withNotify(AppointmentUserCreate);
