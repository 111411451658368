import React from "react";
import {Button, Card, CardBody, CardHeader, CardImg, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import logoInstagram from "../../assets/img/logos/instagram.png";

class SimpleCard extends React.Component {
    constructor(props) {
        super(props);
    }

    clickButton(link, target) {
        const element = document.createElement('a');
        element.href = link;
        element.target = target || '_blank';
        element.click();
    }

    renderDescription() {

        if (this.props.isHtml) {
            return (
                <span dangerouslySetInnerHTML={{__html: this.props.description}} />
            );
        }
        return this.props.description;
    }

    renderButton() {
        if (this.props.titleButton) {
            return (
                <Button color="primary" onClick={() => this.clickButton(this.props.routeButton, this.props.targetButton || null)}>
                    {this.props.titleButton}
                </Button>
            );
        }
    }

    render() {

        return (
            <>
                <Card>
                    <br />
                    <div className={'text-center'}>
                        <img src={this.props.logo} width={this.props.width || null} height={this.props.height || null} />
                    </div>
                    <CardHeader>
                        <div className={'nutrideck-card-title nutrideck-text-align-center'}>
                            {this.props.title}
                        </div>
                    </CardHeader>
                    <CardBody className={'text-center'}>
                        <div style={{minHeight: this.props.heightContent || '80px'}}>
                            <p className={'text-center'}>
                                {this.renderDescription()}
                            </p>
                        </div>
                        {this.renderButton()}
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default SimpleCard;
