import React, { Component } from 'react';
import InputMask from "react-input-mask";
import {Input} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";

export default class DateField extends Component {

    constructor(props) {
        super(props);
        this.id = props.id ? props.id : "";
        this.label = props.label ? props.label : "";
        this.state =  {
            value: props.value
        }
    }
    render(){

        return <ReactDatetime
            timeFormat={false}
            dateFormat={'DD/MM/YYYY'}
            locale="pt-br"
            id={this.id}
            onChange={this.onDateChange.bind(this)}
            renderInput={ this.renderInput.bind(this) } />;
    }
    onFieldChange(event) {
        // for a regular input field, read field name and value from the event
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        this.props.onChange(fieldName, fieldValue);
    }

    onDateChange(dateValue) {
        // for a date field, the value is passed into the change handler
        this.props.onChange(dateValue);
    }
    renderInput( props, openCalendar, closeCalendar ){

        if (moment.isMoment(this.props.value)) {
            props.value = this.props.value.format('DD/MM/YYYY');
        } else {
            props.value = this.props.value
        }
        return (
            <div>

                <InputMask mask="01/mf/yyyy"
                           formatChars={{
                               "0": "[0-3]",
                               "1": "[0-9]",
                               "m": "[0-1]",
                               "f": "[0-9]",
                               "y": "[0-9]",
                           }}
                           value={this.props.value}
                           id={this.id}
                           placeholder={this.label}
                           {...props}
                           maskChar={'_'}
                >
                    {(inputProps) => <Input type="text" {...inputProps} />}
                </InputMask>
                {this.renderLabel()}
            </div>
        );
    }

    renderLabel() {
     if (this.label) {
         return (<label htmlFor={this.id}>{this.label}</label>)
      }
     return <></>
    }

}
