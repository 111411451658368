import React from "react";

// reactstrap components
import {
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader,
} from "reactstrap";
import {Link} from "react-router-dom";

class AdvertiseJitsiModal extends React.Component
{
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.open
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({isOpen: this.props.open});
    }
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen},
      () => this.props.onToggle && this.props.onToggle(this.state.isOpen)
    );
  }

  cancel = () => {
    this.toggle();
  }

  render() {
    return (
      <Modal
        className="modal-sm"
        isOpen={this.state.isOpen}
        toggle={this.toggle}
      >
        <ModalHeader >
          Aviso!
        </ModalHeader>
        <ModalBody>
          <p>Você está acessando a plataforma através de um dispositivo móvel. Você deve clicar no botão <b>Iniciar na web</b> ou então baixar o aplicativo do Jitsi na loja do seu dispositivo.</p>
          <Link to={{pathname: "https://play.google.com/store/apps/details?id=org.jitsi.meet&hl=pt_BR&gl=US" }} target="_blank">
            <i className="fab fa-google-play" style={{fontSize: '24px'}}></i>
            Google
          </Link>
          &nbsp;
          &nbsp;
          &nbsp;
          <Link to={{pathname: "https://apps.apple.com/us/app/jitsi-meet/id1165103905" }} target="_blank">
            <i className="fab fa-apple" style={{fontSize: '24px'}}></i>
            Apple
          </Link>


        </ModalBody>
        <ModalFooter>
          <Button
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.cancel}
          >
            <span style={{fontSize: '20px'}}>Ok, entendi!</span>
          </Button>
        </ModalFooter>

      </Modal>
    );
  }
}

AdvertiseJitsiModal.defaultProps = {
  message: '',
  open: false
}

export default AdvertiseJitsiModal;
