import jwtDecode from 'jwt-decode';

import apiRequest from './Api';

// TODO redux
function saveAuth(data) {
  console.log('Saving auth...');

  const accessToken = data.access_token;
  localStorage.setItem('accessToken', accessToken);

  let decoded = jwtDecode(accessToken);
  let {id, name, role, adminOrganization, organization, pictureUrl} = decoded;
  localStorage.setItem('userData', JSON.stringify({id, name, role, adminOrganization, organization, pictureUrl}));
}

function getAuthToken() {
  return localStorage.getItem('accessToken');
}

function getUserData(field) {
  let data = JSON.parse(localStorage.getItem('userData') || 'null');
  if (!field) {
    return data;
  }
  return data[field] || null;
}

function updateUserData(field, value) {
  let data = getUserData();
  if (!data) {
    return;
  }
  data[field] = value;
  localStorage.setItem('userData', JSON.stringify(data));
}

function getUserFriendlyRole() {
  let data = getUserData();
  if (!data) {
    return '';
  }
  let {role, adminOrganization, organization} = data;
  if (role == 'super_admin') {
    return 'Super admin';
  }
  else if (role == 'specialist') {
    return 'Especialista';
  }
  else if (adminOrganization) {
    return 'Admin empresa';
  }
  else if (organization) {
    return 'Usuário empresa';
  }
  else {
    return 'Usuário';
  }
}

function logout() {
  console.log('Logging out');

  let promise = new Promise((resolve, reject) => {
    localStorage.removeItem('accessToken');
    logoutRocketchat();
    setTimeout(() => {
      resolve();
    }, 500);
  });
  return promise;
}

function logoutRocketchat() {
  console.log('Logging out Rocket Chat');

  let iframe = document.querySelector('iframe');
  if (!iframe) {
    return;
  }

  iframe.contentWindow.postMessage({
    externalCommand: 'logout'
  }, '*');
}

function redirectLogin(forgetOrigin = false) {
  let current = window.location.pathname;
  if (forgetOrigin) {
    window.location.href = '/auth/login';
  }
  else {
    window.localStorage.setItem('redirectUrl', current);
    window.location.href = '/auth/login?redirect=1';
  }
}

function redirectToLoginIfNeeded(forgetOrigin= false) {
  if (!getAuthToken() && window.location.href.match(/admin/) && false) {
    redirectLogin(forgetOrigin);
  }
}

function clearRedirect() {
  window.localStorage.removeItem('redirectUrl');
}

function getRedirectUrl() {
  return window.localStorage.getItem('redirectUrl');
}

function shouldCompleteProfile() {
  let user = getUserData();
  if (!user) {
    return Promise.resolve(false);
  }

  return apiRequest("/users/" + user.id, {method: 'GET'})
      .then(response => {
        let user = response.data.data;
        let should;
        if (user.role == 'super_admin' || user.role == 'specialist') {
          should = false;
        }
        else if (user.organization && user.organization.user_role == 'admin') {
          should = false;
        }
        else {
          should = !user.cpf;
        }
        return Promise.resolve(should);
      });
}

function checkToken() {
  return apiRequest("/auth/me", {method: 'POST'});
}

function checkUserProfile() {
  return shouldCompleteProfile()
    .then(yes => {
      return true;
    });
}

export {
  saveAuth,
  getAuthToken,
  logout,
  redirectLogin,
  getUserData,
  getUserFriendlyRole,
  redirectToLoginIfNeeded,
  getRedirectUrl,
  clearRedirect,
  shouldCompleteProfile,
  checkToken,
  checkUserProfile,
  updateUserData
};
