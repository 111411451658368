import React from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";

class SimpleCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <>
                <Card className="card-patient">
                    <CardBody>
                        <Row>
                            <Col>
                                <b>{this.props.title}</b>
                            </Col>
                        </Row>
                        <Row className="card-subject">
                            <Col>
                                {this.props.subject}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="primary"
                                        tag={Link}
                                        disabled={this.props.isDisabled || false}
                                        to={this.props.routeButton}>
                                    {this.props.titleButton}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default SimpleCard;
