import React from "react";
import {Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";
import defaultAvatar from "../../../assets/img/default-avatar.png";
import {Link} from "react-router-dom";

class SpecialistCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };


    render() {
        let avatar = this.props.specialist.pictureUrl || defaultAvatar;
        return (<>
            <div className="specialist-card" style={{height: "95%"}}>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="title-about">
                                            Mais Sobre o Nutricionista
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="12">
                                <div className="author">
                                    <img
                                        alt="..."
                                        style={{
                                            width: '124px',
                                            height: '124px',
                                            position: 'relative'
                                        }}
                                        className="avatar border-gray float-left"
                                        src={avatar}
                                    />
                                    <Row>
                                        <Col className="label">
                                            Nutricionista
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-content">
                                            {this.props.specialist.name}
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                        <Row>

                            <Col md>
                                <div>Sobre</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>{this.props.specialist.bio == 'null' ? '' : this.props.specialist.bio}</div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <Link to={'/admin/meu-nutricionista'}>
                                   Ver mais
                                </Link>
                            </Col>

                        </Row>
                    </CardFooter>
                </Card>
            </div>
        </>)
    }
}

export default SpecialistCard;
