import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import {Button, Col, Row} from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";


class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        let types = 'image/*, application/pdf';
        if (props.accept) {
            types = props.accept;
        }
        this.state = {
            file: null,
            fileTypes: types,
            imagePreviewUrl: this.props.originalUrl || (this.props.avatar ? defaultAvatar : defaultImage)
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.accept !== this.props.accept ) {
            this.setState({fileTypes: this.props.accept})
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.originalUrl && (prevState.imagePreviewUrl == defaultAvatar ||
            prevState.imagePreviewUrl == defaultImage)) {
            return {imagePreviewUrl: nextProps.originalUrl};
        } else if (!nextProps.originalUrl && !prevState.imagePreviewUrl) {
            return {imagePreviewUrl: nextProps.avatar ? defaultAvatar : defaultImage};
        }
        return {};
    }

    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);

        this.props.onChangeImage && this.props.onChangeImage(file);
    }

    handleSubmit(e) {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }

    readableFileSize(size) {
        var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = 0;
        while(size >= 1024) {
            size /= 1024;
            ++i;
        }
        return size.toFixed(1) + ' ' + units[i];
    }
    handleClick() {
        this.refs.fileInput.click();
    }

    handleRemove() {
        this.setState({
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
        });
        this.refs.fileInput.value = null;

        this.props.onChangeImage && this.props.onChangeImage(null);
    }

    renderFile = () => {

        if (!this.state.file) {
            return (
                <></>
            )
        }

        return (
            <div>
                <Row>
                    <Col md="2">
                        <i className="fa fa-file"/>

                    </Col>
                    <Col md="10">
                        {this.state.file.name}

                    <p className={"file-info"}>
                        {this.readableFileSize(this.state.file.size)}
                    </p>
                    </Col>
                </Row>
            </div>
        )
    }

    clear() {
        this.setState({file: null});
    }
    render() {
        return (
            <>
                <div className={"file-upload"}>
                    <div className="fileinput text-center ">
                        <input type="file" onChange={this.handleImageChange} ref="fileInput"
                        accept={this.state.fileTypes}/>
                        <div>
                            {this.state.file === null ? (
                                <Button outline color="primary" onClick={() => this.handleClick()}>
                                    <i className="fa fa-upload"/>
                                    Buscar arquivo
                                </Button>
                            ) : (
                                <Button color="danger"
                                        onClick={() => this.handleRemove()}>
                                    <i className="fa fa-times"/>
                                    Remover
                                </Button>
                            )}

                        </div>
                    </div>
                    {this.renderFile()}
                </div>
            </>
        );
    }
}

FileUpload.propTypes = {
    avatar: PropTypes.bool,
    originalUrl: PropTypes.string,
    onChangeImage: PropTypes.func
};

export default FileUpload;
