import React from "react";

// reactstrap components
import moment from "moment";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Input, InputGroup, InputGroupAddon,
    Row
} from "reactstrap";
import apiRequest from "../../../../lib/Api";
import withNotify from "../../../../lib/NotificationWrapper";
import {Link} from "react-router-dom";
import HeaderProntuario from "../../../../components/Header/HeaderProntuario";


class ResultadoAntropometria extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {name: '', date_start: moment()},
            working: false,
            idade: 0,
            kcal: 0,
            idForm: 0,
            model: {
                antropometria: {
                    imc: 0,
                    classificacao_estado_nutricional: 0,
                    porcentagem_de_gordura_corporal: 0,
                    peso_da_gordura: 0,
                    peso_livre_de_gordura: 0,
                    densidade_corporal: 0,
                    presenca_de_risco_cardiaco: 0,
                },
                gastos_energeticos: {
                    taxa_metabolica_basal: 0,
                    gasto_energetico_total: 0,
                    necessecidade_energetica_total: 0,
                    peso_atual: 0,
                },
            },
        }
    }


    get id() {

        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    async componentWillMount() {
        if (this.id) {
            await this.getUserData();
        }
    }

    getUserData = async () => {
        const response = await apiRequest(`/patient/${this.id}`, {method: "GET"})
        const user = response.data.data;
        let dateBegin = moment(user.created_at);
        let userState = {
            name: user.name,
            date_start: dateBegin
        }
        this.setState({user: userState});
        /**
         * return
         */
        try {
            const responseExam = await apiRequest(`/patient/${this.id}/forms/resultado_antropometria`, {method: "GET"})
            let {data} = responseExam.data;
            let subgroups = data.data.groups[0].subgroups;
            let model = {status: data.status};
            for (const subgroup of subgroups) {
                for (const field of subgroup.fields) {
                    if (!model[subgroup.name]) {
                        model[subgroup.name] = {};
                    }
                    let fieldValue = field.value;
                    if (field.name === 'fator_atividade_fisica') {
                        fieldValue = this.fatorAtividadeFisica.find(element => element.value === field.value);
                    }
                    model[subgroup.name][field.name] = fieldValue;
                }
            }
            this.setState({'model': model, 'idForm': data.id});

            const responseAntropometria = await apiRequest(`/patient/${this.id}/forms/antropometria`, {method: "GET"})
            let pesoAtual =  responseAntropometria.data.data.data.groups[0].subgroups[0].fields[0].value;
            model['gastos_energeticos']['peso_atual'] = pesoAtual;
            console.log(this.state.model.gastos_energeticos.kcal)
            this.setState({'model': model, 'kcal': this.state.model.gastos_energeticos.kcal});

        } catch (error) {
        }
    }
    handleChange = (event) => {
        const value = event.target.value;
        let model = this.state.model;
        let valorNet = Number((value * this.state.model.gastos_energeticos.peso_atual)).toFixed(2);
        model['gastos_energeticos']['necessecidade_energetica_total'] = valorNet;
        this.setState({'model': model});
        this.setState({kcal: value});
    }

    save = async () => {

        let dataToPost = {
            data: {kcal: this.state.kcal, "net": this.state.model.gastos_energeticos.necessecidade_energetica_total},
            method: "PUT"
        }
        try {
            const response = await apiRequest(`/patient/${this.id}/forms/${this.state.idForm}`, dataToPost)
            console.log(response)
            this.props.notify({type: 'success', message: 'Necessidade Energética Total salva com sucesso.'});
        } catch (e) {
            this.props.notify({type: 'error', message: e.data.message});
        }

        console.log('salvando')
    }

    render() {

        let link = `/admin/patient/${this.id}/details`;

        return (
            <>
                <div className="content exam-container">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                        <HeaderProntuario
                                            title={`Preenchimento de Antropometria de ${this.state.user.name}`}
                                            link={link}
                                            buttonText="Voltar para o perfil do paciente."
                                        />
                                </CardHeader>
                                <CardBody >
                                    <div style={{padding: "25px"}}>
                                        <Row style={{paddingBottom: "25px"}}>
                                            <Col>
                                                <Row className="label-exam">
                                                    IMC
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.antropometria.imc}
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="label-exam">
                                                    Classificação do estado nutricional
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.antropometria.classificacao_estado_nutricional}
                                                </Row>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                        <Row  style={{paddingBottom: "25px"}}>
                                            <Col>
                                                <Row className="label-exam">
                                                    Percentagem de gordura corporal
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.antropometria.porcentagem_de_gordura_corporal}
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="label-exam">
                                                    Peso de gordura
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.antropometria.peso_da_gordura}
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="label-exam">
                                                    Peso de  livre de gordura
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.antropometria.peso_livre_de_gordura}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: "25px"}}>
                                            <Col>
                                                <Row className="label-exam">
                                                    Densidade Corporal
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.antropometria.densidade_corporal}
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="label-exam">
                                                    Presença de risco cardíaco
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.antropometria.presenca_de_risco_cardiaco}
                                                </Row>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <h5 style={{fontWeight: 600}}>Gastos Energéticos</h5>
                                        </Row>
                                        <Row style={{paddingBottom: "25px"}}>
                                            <Col>
                                                <Row className="label-exam">
                                                    TMB - Taxa Metabólica Basal
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.gastos_energeticos.taxa_metabolica_basal}
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="label-exam">
                                                    GET - Gasto Energetico Total
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.gastos_energeticos.gasto_energetico_total}
                                                </Row>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: "25px"}}>
                                            <Col>
                                                <Row className="label-exam">
                                                    NET - Necessidade Energética Total
                                                </Row>
                                                <Row className="label-exam-value">
                                                    {this.state.model.gastos_energeticos.necessecidade_energetica_total}
                                                </Row>
                                                <Row className="label-exam-value">
                                                    <FormGroup>
                                                        <label style={{fontSize: '12px'}}>Kcal</label>
                                                        <InputGroup size={'md'}>
                                                            <Input type="number" name="kcal"
                                                                   step="0.1"
                                                                   value={this.state.kcal}
                                                                   onChange={this.handleChange}
                                                                   className={this.state.classInputs}
                                                                   style={{height: '40px'}}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <Button className={'md'}
                                                                        onClick={this.save}
                                                                        style={{margin: '0px', height:'40px'}}
                                                                >Salvar</Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>

                                                    </FormGroup>
                                                </Row>
                                            </Col>
                                            <Col>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default withNotify(ResultadoAntropometria);
