import React from "react";

// reactstrap components
import {Card, CardHeader, CardTitle, Col, Row,} from "reactstrap";
import withNotify from "lib/NotificationWrapper";
import apiRequest from "../../../lib/Api";
import StatusProgram from "../patient/StatusProgram/StatusProgram";
import SpecialistCard from "../specialist/SpecialistCard";
import AppointmentCard from "../appointments/AppointmentCard";
import ItensProntuario from "../patient/ItensProntuario/ItensProntuario";
import {getUserData} from "../../../lib/Auth";
import ButtonChat from "../../../components/ButtonChat/ButtonChat";
import Appointment from "../appointments/Appointment";

class HomeMember extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            me: getUserData(),
            specialist: {id: null},
            working: false,
            exams: {},
            appointmentModal: null
        };
    }


    async componentDidMount() {
        const responseMedicalRecord = await apiRequest(`/patient/${this.state.me.id}/medical-record`, {method: "GET"})
        this.setState({exams: responseMedicalRecord.data.exams})
        let dashboard = await apiRequest("/me/dashboard", {method: "GET"});
        this.setState({me: dashboard.data.me, specialist: dashboard.data.specialist});

        if (this.state.me.guest) {

            await apiRequest(`/purchase-services?user_id=${this.state.me.id}`, {
                method: 'GET'
            }).then(success => {
                const response = success.data.data;
                if (response.length > 0) {
                    if (response[0].available === true) {
                        this.openAppointment();
                    }
                }
            }).catch(error => {
            });

        }
    }

    renderStatusProgram() {
        if (!this.state.me.program_status) {
            return (<></>)
        }
        return (
            <StatusProgram resume={this.state.me.program_status}/>
        )
    }

    renderInitialAppointment(appointment) {

        if (!this.state.me.program_status) {
            return (<></>)
        }
        return (
            <AppointmentCard appointment={this.state.me.program_status.appointment}/>
        )
    }

    renderChatButton() {


        if (!this.state.specialist.id)
        {
            return (<></>)
        }
        return (
            <ButtonChat
                user={this.state.specialist}
                label="Fale com o Nutricionista"
            />
        )
    }

    renderDashBoardCards() {
        if (!this.state.me.program_status) {
            return (<></>)
        }
        switch (this.state.me.program_status.current) {

            case 0:
                return (
                    <>
                        <Row>
                            <Col md={6}>
                                <Card style={{height: '95%'}}>
                                    <CardHeader>
                                        Marque seu encontro Inicial com sua nutricionista
                                    </CardHeader>
                                </Card>
                            </Col>
                            <Col md={6}>

                                <SpecialistCard specialist={this.state.specialist}/>
                            </Col>
                        </Row>
                    </>)
                break;

            case 1:
                if (this.state.me.program_status.status === 'waiting') {
                    return (
                        <>
                            <Row>
                                <Col md={6}>
                                    {this.renderInitialAppointment()}
                                </Col>
                                <Col md={6}>
                                    <SpecialistCard specialist={this.state.specialist} style={{height: '95%'}}/>
                                </Col>
                            </Row>
                        </>
                    );
                }
                if (this.state.me.program_status.status === 'done') {
                    return (
                        <>
                            <Row style={{display: this.state.exams.plano_alimentar.status === 'done' ? 'none' : ''}}>
                                <Col md={12}>
                                    Sua avaliação foi realizada com sucesso. Em até 24hrs você receberá seu Plano
                                    Alimentar
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ItensProntuario exams={this.state.exams}/>
                                </Col>
                            </Row>
                        </>
                    );
                }
                break;
            case 2:
            case 3:

                return (
                    <Row>
                        <Col>
                            <ItensProntuario exams={this.state.exams}/>
                        </Col>
                    </Row>
                )
                break;
        }
    }

    openAppointment = () => {
        this.setState({
            appointmentModal: (
                <Appointment
                    open={true}
                    isSpecialist={false}
                    patient={this.state.me}
                    specialist={this.state.specialist}
                    appointment={false}
                    allowClose={false}
                    onHide={(success) => {
                        this.setState({appointmentModal: null});
                        this.props.history.push(`/admin/appointment`);
                        if (success) {
                            this.props.notify({type: 'success', message: 'Agendamento salvo com sucesso.'});
                        }
                    }}
                />
            )
        })
    }

    render() {

        let firstName = this.state.me.name.split(" ")[0];
        return (
            <>
                {this.state.appointmentModal}
                <div className="content home-member-content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <CardTitle tag="h4">Olá {firstName}, bem vindo à Nutrideck</CardTitle>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            Andamento do
                                            programa: {this.state.me.program_status ? this.state.me.program_status.service : " não iniciado"}
                                            {this.renderStatusProgram()}
                                        </Col>
                                    </Row>
                                    {this.renderDashBoardCards()}
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </>
        )
    }
}

export default withNotify(HomeMember);
