class UserFormData {
    constructor(data) {

        this.data = data;
    }

    toModel() {

        let model = {};
        let groups = this.data.data.groups;

        for (let group of groups) {
            if (!model[group.name]) {
                model[group.name] = {};
            }
            let subgroups = group.subgroups;

            for (const subgroup of subgroups) {
                for (const field of subgroup.fields) {
                    if (!model[group.name][subgroup.name]) {
                        model[group.name][subgroup.name] = {};
                    }
                    model[group.name][subgroup.name][field.name] = field.value;
                }
            }
        }

        return model;
    }

    getField(field) {


    }
}

export default UserFormData;



