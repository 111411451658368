import React from "react";
import {Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import apiRequest from "../../../lib/Api";
import * as moment from "moment";

class Appointment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: {value: '', label: ''},
            program: {value: '', label: ''},
            hour: {value: '', label: ''},
            date: {},
            calendars: [],


            patients: [],
            hours: [],
            programs: []
        }

    }

    clearForm = () => {
        this.setState({
            programs: [],
            patient: {value: '', label: ''},
            program: {value: '', label: ''},
        });
    }

    getPatients = async () => {
        if (this.props.isSpecialist === false) {
            this.setState({patients: [this.props.patient], patient: this.props.patient})
            await this.getPrograms(this.props.patient);
            return;
        }
        await apiRequest('/patients', {
            method: 'GET'
        }).then(success => {
            let patients = [];
            for (let patient of success.data.data) {
                const patientSelect = {
                    value: patient.id,
                    label: patient.name,
                };
                patients.push(patientSelect);
                if (parseInt(this.props.patient.id) === parseInt(patient.id)) {
                    this.setState({patient: patientSelect});
                    this.getPrograms(patientSelect);
                }
            }
            this.setState({patients: patients})
        }).catch(error => {
            this.patients = [];
            this.props.notify({type: 'error', message: "Não foi possível buscar os seus pacientes."});
        });
    }

    getPrograms = async (patient) => {
        if (!patient || patient.value === '' || patient.length === 0) {
            return this.clearForm();
        }
        if (patient.value === undefined && patient.id) {
            patient.value = patient.id;
        }
        await apiRequest(`/purchase-services?user_id=${patient.value}`, {
            method: 'GET'
        }).then(success => {
            let programs = []
            for (let program of success.data.data) {
                programs.push({
                    value: program.id,
                    label: program.service.displayName,
                    isDisabled: program.quota === program.usage
                });
            }

            if (this.props.appointment.purchase_service_id) {
                programs.map((program) => {
                    program.isDisabled = true;
                    if (program.value === this.props.appointment.purchase_service_id) {
                        program.isDisabled = false;
                        this.setState({program: program});
                    }
                    return program;
                });
            }
            this.setState({programs: programs});
        }).catch(error => {
            this.props.notify({
                type: 'error',
                message: 'Não foi encontrado nenhum programa para o paciente informado.'
            });
        });
    }

    getCalendar = async () => {
        let data = {};

        if (this.props.specialist) {
            data.specialist_id = this.props.specialist.id
        }
        await apiRequest(`/calendar`, {
            method: 'GET',
            data: data
        }).then(success => {
            this.setState({calendars: success.data.data});
        }).catch(error => {
            this.props.notify({type: 'error', message: 'Não foi encontrado nenhum calendário.'});
        });
    }

    componentDidMount() {
        this.getPatients();
        this.getCalendar()
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    searchAvaliableHours = (value) => {
        const date = moment.isMoment(value) ? value.format('YYYY-MM-DD') : false;
        let avaliableHours = [];
        if (date) {
            this.setState({date: value})

            for (const slot of this.state.calendars.slots) {
                if (slot.dayNum === date && slot.blocks.length > 0) {
                    for (const hour of slot.blocks) {
                        avaliableHours.push({
                            label: `${hour.timeStart} às ${hour.timeEnd}`,
                            value: `${hour.timeStart}|${hour.timeEnd}`
                        });
                    }
                }
            }
        }
        this.setState({hours: avaliableHours});
    }

    createAppointment = async () => {

        const hour = this.state.hour.value.split('|');
        const dateFormat = this.state.date.format('YYYY-MM-DD');
        const appointment = {
            purchase_service_id: this.state.program.value,
            datetime_start: `${dateFormat} ${hour[0].substring(0, 5)}`,
            datetime_end: `${dateFormat} ${hour[1].substring(0, 5)}`,
            duration: 60,
            user_id: this.state.patient.value,
        };

        if (this.props.specialist) {
            appointment.specialist_id = this.props.specialist.id
        }
        let route = '/appointments';
        let method = 'POST';
        if (this.props.appointment.id) {
            route = `/appointments/change/${this.props.appointment.id}`;
            method = 'PUT';
            appointment.situation = this.props.appointment.situation;
        }
        await apiRequest(route, {
            method: method,
            data: appointment
        }).then(success => {
            this.props.notify({type: 'success', message: 'Agendamento salvo com sucesso.'});
            this.props.onHide();
            if (this.props.callback) {
                this.props.callback();
            }
        }).catch(error => {
            let errorMessage = 'Não foi possível salvar o agendamento.';
            if (error.data && error.data.errors.length > 0) {
                errorMessage = error.data.errors[0];
            }
            this.props.notify({type: 'error', message: errorMessage});
        });
    }


    renderPatient = () => {

        return (
            <FormGroup>
                <label className='nutrideck label'>Paciente:</label>
                <Input type="text" name="name"
                       value={this.props.patient.name}
                       disabled={true}
                />
            </FormGroup>
        );

    }

    renderCloseButton = () => {
        if (this.props.allowClose) {
            return <>
                <Button onClick={() => {
                    this.props.onHide();
                }}>
                    Fechar
                </Button>
            </>;
        }
    }

    render = () => {

        return (
            <>
                <Modal size='md' isOpen={true}>
                    <div className='content'>
                        <ModalHeader>
                            Agendamento de Consulta
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                {this.renderPatient()}

                                <FormGroup>
                                    <label className='nutrideck label'>Andamento:</label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="program"
                                        value={this.state.program}
                                        onChange={(value) => {
                                            this.setState({program: value})
                                        }}
                                        options={this.state.programs}
                                        placeholder="Selecione..."
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='nutrideck label'>Data da Consulta:</label>
                                    <ReactDatetime
                                        closeOnSelect={true}
                                        onChange={this.searchAvaliableHours}
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Escolha a data da consulta"
                                        }}
                                        timeFormat={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='nutrideck label'>Horários Disponíveis:</label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="hour"
                                        value={this.state.hour}
                                        onChange={(value) => {
                                            this.setState({hour: value})
                                        }}
                                        options={this.state.hours}
                                        placeholder="Selecione..."
                                    />
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>

                            {this.renderCloseButton()}
                            <Button color="primary" onClick={this.createAppointment}>
                                Agendar
                            </Button>
                            &nbsp;&nbsp;
                        </ModalFooter>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withNotify(Appointment);
