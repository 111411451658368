import React from "react";

// reactstrap components
import moment from "moment";
import apiRequest from "../../../../lib/Api";
import withNotify from "../../../../lib/NotificationWrapper";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Link} from "react-router-dom";

import {Prompt} from 'react-router'
import {getUserData} from "../../../../lib/Auth";
import Select from "react-select";
import HeaderProntuario from "../../../../components/Header/HeaderProntuario";

class Anamnese extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            user: {name: '', date_start: moment()},
            currentUser: getUserData(),
            tabSelected: 'historic',
            isHistoric: true,
            isHabits: false,
            isFood: false,
            isGeneralObservation: false,
            isMealTab: true,
            isFreeTab: false,
            errors: [],
            confirmAlert: '',
            finishAlert: '',
            hasResponse: false,
            readOnly: false,
            haveToSave: false,
            isDone: true,
            classInputs : '',


            peso_atual: '',
            peso_habitual: '',
            peso_maximo: '',
            peso_minimo: '',

            queixas_problemas_de_saude: '',
            historico_familiar_de_doencas: '',
            cirurgias: '',
            internacoes: '',
            tratamentos: '',
            medicacoes_de_uso_regular: '',
            suplementos: '',

            pele: '',
            cabelo: '',
            unha: '',
            sono: '',
            tensao_emocional: '',
            ciclo_menstrual: '',

            alcool: '',
            fumo: '',
            habitos_intestinais: '',
            modalidade: '',
            frequencia: '',
            duracao_das_sessoes: '',

            preferencias_alimentares: '',
            intolerancias_aversoes: '',
            metodologia_de_preenchimento: 'Recordatório de 24 horas',
            options_metodologia_preenchimento: [
                {
                    value: 'Recordatório de 24 horas',
                    label: 'Recordatório de 24 horas'
                },
                {
                    value: 'Habitual',
                    label: 'Habitual'
                },
                {
                    value: 'Outros',
                    label: 'Outros'
                },
            ],

            cafe_da_manha: '',
            colacao: '',
            almoco: '',
            lanche_da_tarde: '',
            jantar: '',
            ceia: '',
            outras_refeicoes: '',
            inquerito_alimentar: '',

            observacoes_gerais: '',
        };
    }

    async componentWillMount() {
        await apiRequest("/patient/" + this.id, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                let readOnly = false;
                let classInputs = '';

                if (this.state.currentUser.role === 'patient') {
                    readOnly = true;
                    classInputs = 'form-exames-readonly';
                }
                this.setState({user: data, readOnly: readOnly, classInputs: classInputs});
            });

        const request = await apiRequest(`/patient/${this.id}/forms/anamnese`, {method: 'GET'})
            .then(success => {
                this.setState({hasResponse: true});
                this.fillForm(success.data.data)
            }).catch(error => {
                this.setState({hasResponse: false});
            });


    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    fillForm = (response) => {
        for (const group of response.data.groups) {
            for (const subgroup of group.subgroups) {
                for (const field of subgroup.fields) {
                    this.setState({[field.name]: field.value || ''});
                }
            }
        }
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        let haveToSave = false;
        let oldValue  = this.state[name].value;
        if (oldValue != value){
            haveToSave = true
        }
        this.setState({isDone: false, haveToSave: true});
        this.setState({[name]: value});
    }

    renderHistoric() {
        return (
            <>
                <div id='historico'>
                    <br/>
                    <p><b>Peso</b></p>
                    <Row>
                        <Col md='2'>
                            <FormGroup>
                                <label>Peso Atual:</label>
                                <InputGroup>
                                    <Input type="number" name="peso_atual" step="0.1"
                                           value={this.state.peso_atual}
                                           onChange={this.handleChange}
                                           className={this.state.classInputs}
                                           disabled={this.state.readOnly}
                                    />
                                    <InputGroupAddon addonType="append" >
                                        <InputGroupText className={this.state.classInputs}>Kg</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md='2'>
                            <FormGroup>
                                <label>Peso Habitual:</label>
                                <InputGroup>
                                    <Input type="text" name="peso_habitual"
                                           value={this.state.peso_habitual}
                                           onChange={this.handleChange}
                                           className={this.state.classInputs}
                                           disabled={this.state.readOnly}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className={this.state.classInputs}>Kg</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md='2'>
                            <FormGroup>
                                <label>Peso Máximo:</label>
                                <InputGroup>
                                    <Input type="text" name="peso_maximo"
                                           value={this.state.peso_maximo}
                                           onChange={this.handleChange}
                                           className={this.state.classInputs}
                                           disabled={this.state.readOnly}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className={this.state.classInputs}>Kg</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md='2'>
                            <FormGroup>
                                <label>Peso Mínimo:</label>
                                <InputGroup>
                                    <Input type="text" name="peso_minimo"
                                           value={this.state.peso_minimo}
                                           onChange={this.handleChange}
                                           className={this.state.classInputs}
                                           disabled={this.state.readOnly}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className={this.state.classInputs}>Kg</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <br/>
                    <p><b>Histórico Médico</b></p>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <label>Queixas/Problemas de Saúde:</label>
                                <Input type="textarea" name="queixas_problemas_de_saude"
                                       value={this.state.queixas_problemas_de_saude}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Histórico Familiar de Doenças</label>
                                <Input type="textarea" name="historico_familiar_de_doencas"
                                       value={this.state.historico_familiar_de_doencas}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Cirurgias:</label>
                                <Input type="textarea" name="cirurgias" value={this.state.cirurgias}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Internações:</label>
                                <Input type="textarea" name="internacoes"
                                       value={this.state.internacoes}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Tratamentos:</label>
                                <Input type="textarea" name="tratamentos"
                                       value={this.state.tratamentos}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Medicações de Uso Regular:</label>
                                <Input type="textarea" name="medicacoes_de_uso_regular"
                                       value={this.state.medicacoes_de_uso_regular}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <label>Suplementos:</label>
                                <Input type="textarea" name="suplementos"
                                       value={this.state.suplementos}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <p><b>Sinais Clínicos</b></p>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <label>Pele:</label>
                                <Input type="text" name="pele"
                                       value={this.state.pele}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Cabelo:</label>
                                <Input type="text" name="cabelo"
                                       value={this.state.cabelo}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Unha:</label>
                                <Input type="text" name="unha"
                                       value={this.state.unha}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Sono:</label>
                                <Input type="text" name="sono"
                                       value={this.state.sono}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Tensão Emocional:</label>
                                <Input type="text" name="tensao_emocional"
                                       value={this.state.tensao_emocional}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Ciclo Menstrual:</label>
                                <Input type="text" name="ciclo_menstrual"
                                       value={this.state.ciclo_menstrual}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </>
        )
            ;
    }

    renderHabits() {
        return (
            <>
                <div id="habitos">
                    <br/>
                    <p><b>Hábitos</b></p>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <label>Álcool:</label>
                                <Input type="text" name="alcool"
                                       value={this.state.alcool}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Fumo:</label>
                                <Input type="text" name="fumo"
                                       value={this.state.fumo}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Hábitos Intestinais:</label>
                                <Input type="text" name="habitos_intestinais"
                                       value={this.state.habitos_intestinais}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <br/>
                    <p><b>Exercício Físico</b></p>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <label>Modalidade:</label>
                                <Input type="text" name="modalidade"
                                       value={this.state.modalidade}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Frequência:</label>
                                <Input type="text" name="frequencia"
                                       value={this.state.frequencia}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Duração da Atividade:</label>
                                <Input type="text" name="duracao_das_sessoes"
                                       value={this.state.duracao_das_sessoes}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    renderFood() {
        return (
            <>
                <div id='alimentacao'>

                    <br/>
                    <p><b>Alimentação</b></p>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <label>Preferências Alimentares:</label>
                                <Input type="text" name="preferencias_alimentares"
                                       value={this.state.preferencias_alimentares}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <label>Intolerâncias / Aversões:</label>
                                <Input type="text" name="intolerancias_aversoes"
                                       value={this.state.intolerancias_aversoes}
                                       onChange={this.handleChange}
                                       className={this.state.classInputs}
                                       disabled={this.state.readOnly}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <p><b>Inquérito Alimentar</b></p>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <label className='nutrideck label'>Metodologia de Preenchimento:</label>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="metodologia_de_preenchimento"
                                    value={this.state.metodologia_de_preenchimento}
                                    options={this.state.options_metodologia_preenchimento}
                                    placeholder="Selecione..."
                                    onChange={(value) => {
                                        this.setState({metodologia_de_preenchimento: value})
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Col md='12'>
                        <br/>
                        <ButtonGroup>
                            <Button active={this.state.isMealTab} style={{'width': '525px'}} className="btn"
                                    color="primary" outline type="button" onClick={() => {
                                this.changeTabFood('meal')
                            }}>
                                Preenchimento por Refeição
                            </Button>
                            <Button active={this.state.isFreeTab} style={{'width': '525px'}} className="btn"
                                    color="primary" outline type="button" onClick={() => {
                                this.changeTabFood('free')
                            }}>
                                Preenchimento Livre
                            </Button>
                        </ButtonGroup>
                    </Col>

                    {this.state.isMealTab ? this.renderMealTab() : ''}
                    {this.state.isFreeTab ? this.renderFreeTab() : ''}


                </div>
            </>
        );
    }

    renderFreeTab() {
        return (
            <>
                <Col md='12'>
                    <FormGroup>
                        <label>Inquérito Alimentar:</label>
                        <Input type="textarea" name="inquerito_alimentar"
                               value={this.state.inquerito_alimentar}
                               onChange={this.handleChange}
                               className={this.state.classInputs}
                               disabled={this.state.readOnly}
                        />
                    </FormGroup>
                </Col>
            </>
        );
    }

    renderMealTab() {
        return (
            <>
                <Row>
                    <Col md='6'>
                        <FormGroup>
                            <label>Café da Manhã:</label>
                            <Input type="textarea" name="cafe_da_manha"
                                   value={this.state.cafe_da_manha}
                                   onChange={this.handleChange}
                                   className={this.state.classInputs}
                                   disabled={this.state.readOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col md='6'>
                        <FormGroup>
                            <label>Colação:</label>
                            <Input type="textarea" name="colacao"
                                   value={this.state.colacao}
                                   onChange={this.handleChange}
                                   className={this.state.classInputs}
                                   disabled={this.state.readOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col md='6'>
                        <FormGroup>
                            <label>Almoço:</label>
                            <Input type="textarea" name="almoco"
                                   value={this.state.almoco}
                                   onChange={this.handleChange}
                                   className={this.state.classInputs}
                                   disabled={this.state.readOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col md='6'>
                        <FormGroup>
                            <label>Lanche da Tarde:</label>
                            <Input type="textarea" name="lanche_da_tarde"
                                   value={this.state.lanche_da_tarde}
                                   onChange={this.handleChange}
                                   className={this.state.classInputs}
                                   disabled={this.state.readOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col md='6'>
                        <FormGroup>
                            <label>Jantar:</label>
                            <Input type="textarea" name="jantar"
                                   value={this.state.jantar}
                                   onChange={this.handleChange}
                                   className={this.state.classInputs}
                                   disabled={this.state.readOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col md='6'>
                        <FormGroup>
                            <label>Ceia:</label>
                            <Input type="textarea" name="ceia"
                                   value={this.state.ceia}
                                   onChange={this.handleChange}
                                   className={this.state.classInputs}
                                   disabled={this.state.readOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <label>Outras Refeições:</label>
                            <Input type="textarea" name="outras_refeicoes"
                                   value={this.state.outras_refeicoes}
                                   onChange={this.handleChange}
                                   className={this.state.classInputs}
                                   disabled={this.state.readOnly}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }

    renderGeneralObservation() {
        return (
            <>
                <Col md='12'>
                    <FormGroup>
                        <label>Observações Gerais:</label>
                        <Input type="textarea" name="observacoes_gerais"
                               value={this.state.observacoes_gerais}
                               onChange={this.handleChange}
                               className={this.state.classInputs}
                               disabled={this.state.readOnly}
                        />
                    </FormGroup>
                </Col>
            </>
        );
    }

    changeTab(tab) {
        if (!this.state.readOnly) {
            this.save();
        }
        this.setState({
            tabSelected: tab,
            isHistoric: tab === 'historic' || false,
            isHabits: tab === 'habits' || false,
            isFood: tab === 'food' || false,
            isGeneralObservation: tab === 'generalObservation' || false
        })
    }

    changeTabFood(tab) {
        if (!this.state.readOnly) {
            this.save();
        }
        this.setState({
            isMealTab: tab === 'meal' || false,
            isFreeTab: tab === 'free' || false,
        })
    }

    complete = () => {

        this.setState({
            confirmAlert: (
                <ReactBSAlert
                    warning
                    style={{display: "block", marginTop: "-200px"}}
                    title="Confirmação"
                    onConfirm={() => {
                        this.save('done');
                    }}
                    onCancel={() => {
                        this.setState({confirmAlert: null});
                    }}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText='Concluir'
                    cancelBtnText="Cancelar"
                    showCancel
                >
                    Deseja concluir a Anamnese para <b>{this.state.user.name}</b>?
                </ReactBSAlert>
            )
        });
    }

    save = async (status) => {

        if (!this.state.haveToSave && status !== 'done' ) {
            return;
        }
        let statusForm = status === undefined ? 'draft' : status;
        const groups = this.prepareData();
        const request = await apiRequest(`/patient/${this.id}/forms/anamnese`,
            {data: {data: {'groups': groups}, 'status': statusForm}, method: 'POST'}
        ).then(response => {
            this.setState({isDone: true,haveToSave: false});
            this.props.notify({type: 'success', message: 'Questionário salvo com sucesso.'});
            this.setState({confirmAlert: null});
        }).finally(
            response => this.setState({working: false})
        );
        document.documentElement.scrollTop = 0;
    }

    prepareData = () => {
        const historicTab = {
            "name": 'historico',
            "subgroups": [
                {
                    "name": "peso",
                    "fields": [
                        {"name": 'peso_atual', "value": this.state.peso_atual},
                        {"name": 'peso_habitual', "value": this.state.peso_habitual},
                        {"name": 'peso_maximo', "value": this.state.peso_maximo},
                        {"name": 'peso_minimo', "value": this.state.peso_minimo}
                    ]
                },
                {
                    "name": "historico_medico",
                    "fields": [
                        {"name": 'queixas_problemas_de_saude', "value": this.state.queixas_problemas_de_saude},
                        {"name": 'historico_familiar_de_doencas', "value": this.state.historico_familiar_de_doencas},
                        {"name": 'cirurgias', "value": this.state.cirurgias},
                        {"name": 'internacoes', "value": this.state.internacoes},
                        {"name": 'tratamentos', "value": this.state.tratamentos},
                        {"name": 'medicacoes_de_uso_regular', "value": this.state.medicacoes_de_uso_regular},
                        {"name": 'suplementos', "value": this.state.suplementos}
                    ]
                },
                {
                    "name": "sinais_clinicos",
                    "fields": [
                        {"name": 'pele', "value": this.state.pele},
                        {"name": 'cabelo', "value": this.state.cabelo},
                        {"name": 'unha', "value": this.state.unha},
                        {"name": 'sono', "value": this.state.sono},
                        {"name": 'tensao_emocional', "value": this.state.tensao_emocional},
                        {"name": 'ciclo_menstrual', "value": this.state.ciclo_menstrual},
                    ]
                }
            ]
        };

        const habitsTab = {
            "name": 'habitos',
            "subgroups": [
                {
                    "name": "habitos",
                    "fields": [
                        {"name": 'alcool', "value": this.state.alcool},
                        {"name": 'fumo', "value": this.state.fumo},
                        {"name": 'habitos_intestinais', "value": this.state.habitos_intestinais},
                    ]
                },
                {
                    "name": "exercicio_fisico",
                    "fields": [
                        {"name": 'modalidade', "value": this.state.modalidade},
                        {"name": 'frequencia', "value": this.state.frequencia},
                        {"name": 'duracao_das_sessoes', "value": this.state.duracao_das_sessoes},
                    ]
                }
            ]
        };

        const foodTab = {
            "name": 'alimentacao',
            "subgroups": [
                {
                    "name": "alimentacao",
                    "fields": [
                        {"name": 'preferencias_alimentares', "value": this.state.preferencias_alimentares},
                        {"name": 'intolerancias_aversoes', "value": this.state.intolerancias_aversoes},
                    ]
                },
                {
                    "name": "inquerito_alimentar",
                    "fields": [
                        {"name": 'metodologia_de_preenchimento', "value": this.state.metodologia_de_preenchimento},
                    ]
                },
                {
                    "name": "preenchimento_por_refeicao",
                    "fields": [
                        {"name": 'cafe_da_manha', "value": this.state.cafe_da_manha},
                        {"name": 'colacao', "value": this.state.colacao},
                        {"name": 'almoco', "value": this.state.almoco},
                        {"name": 'lanche_da_tarde', "value": this.state.lanche_da_tarde},
                        {"name": 'jantar', "value": this.state.jantar},
                        {"name": 'ceia', "value": this.state.ceia},
                        {"name": 'outras_refeicoes', "value": this.state.outras_refeicoes},
                    ]
                },
                {
                    "name": "preenchimento_livre",
                    "fields": [
                        {"name": 'inquerito_alimentar', "value": this.state.inquerito_alimentar},
                    ]
                }
            ]
        };

        const generalObservationTab = {
            "name": 'observacoes_gerais',
            "subgroups": [
                {
                    "name": "observacoes_gerais",
                    "fields": [
                        {"name": 'observacoes_gerais', "value": this.state.observacoes_gerais},
                    ]
                }
            ]
        };

        return [historicTab, habitsTab, foodTab, generalObservationTab];
    }

    backNavButton = () => {
        let tabMov = '';
        switch (this.state.tabSelected) {
            case 'historic':
                tabMov = ''
                break;
            case 'habits':
                tabMov = 'historic'
                break;
            case 'food':
                tabMov = 'habits'
                break;
            case 'generalObservation':
                tabMov = 'food'
                break;
        }
        return tabMov;
    }

    frontNavButton = () => {
        let tabMov = '';
        switch (this.state.tabSelected) {
            case 'historic':
                tabMov = 'habits'
                break;
            case 'habits':
                tabMov = 'food'
                break;
            case 'food':
                tabMov = 'generalObservation'
                break;
        }
        return tabMov;
    }

    render() {
        let link = `/admin/patient/${this.id}/details`;
        let textoLink = 'Voltar para o perfil do paciente.';
        if (this.state.readOnly) {
            link = `/admin/dashboard`;
            textoLink = 'Voltar para o painel.';
        }
        return (
            <>
                {this.state.confirmAlert}
                {this.state.finishAlert}

                <React.Fragment>
                    <Prompt
                        when={(this.state.haveToSave) && !this.state.readOnly}
                        message='Você possui algumas informações não salvas. Deseja mesmo sair?'
                    />
                    <div className="content">
                        <Form action="" className="form" method="POST" id="formAnamnese">
                            <Row>
                                <Col md="12">
                                    <Card>
                                        <CardHeader>
                                                <HeaderProntuario
                                                    title={` Preenchimento de Anamnese de ${this.state.user.name}`}
                                                    link={link}
                                                    buttonText="Voltar para o perfil do paciente."
                                                />
                                            </CardHeader>
                                        <CardBody>

                                            <Col md='12'>
                                                <ButtonGroup>
                                                    <Button active={this.state.isHistoric} style={{'width': '250px'}}
                                                            className="btn" color="primary" type="button"
                                                            onClick={() => {
                                                                this.changeTab('historic')
                                                            }
                                                            }>
                                                        Histórico
                                                    </Button>
                                                    <Button active={this.state.isHabits} style={{'width': '250px'}}
                                                            className="btn" color="primary" type="button"
                                                            onClick={() => {
                                                                this.changeTab('habits')
                                                            }
                                                            }>
                                                        Hábitos
                                                    </Button>
                                                    <Button active={this.state.isFood} style={{'width': '250px'}}
                                                            className="btn" color="primary" type="button"
                                                            onClick={() => {
                                                                this.changeTab('food')
                                                            }
                                                            }>
                                                        Alimentação
                                                    </Button>
                                                    <Button active={this.state.isGeneralObservation}
                                                            style={{'width': '250px'}}
                                                            className="btn" color="primary" type="button"
                                                            onClick={() => {
                                                                this.changeTab('generalObservation')
                                                            }
                                                            }>
                                                        Observações Gerais
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>

                                            {this.state.isHistoric ? this.renderHistoric() : ''}

                                            {this.state.isHabits ? this.renderHabits() : ''}

                                            {this.state.isFood ? this.renderFood() : ''}

                                            {this.state.isGeneralObservation ? this.renderGeneralObservation() : ''}

                                            <Row>
                                                <Col sm='10' style={{'textAlign': 'right'}}>
                                                    <Button color="primary" outline type="button" onClick={() => {
                                                        this.changeTab(this.backNavButton());
                                                    }}
                                                            disabled={this.state.isHistoric}>
                                                        Anterior
                                                    </Button>
                                                </Col>
                                                <Col sm='2' style={{display: this.state.isGeneralObservation && this.state.readOnly ? 'none': ''}}>
                                                    <Button color="primary" type="button" onClick={() => {
                                                        if (this.state.isGeneralObservation) {
                                                            this.complete()
                                                        } else {
                                                            this.changeTab(this.frontNavButton());
                                                        }
                                                    }}>
                                                        {this.state.isGeneralObservation ? 'Concluir' : 'Avançar'}
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </React.Fragment>

            </>
        )
    }
}

export default withNotify(Anamnese);
