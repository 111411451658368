import React from "react";
import {Link, Redirect} from "react-router-dom";
import queryString from 'query-string';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

import apiRequest from "lib/Api";
import {
  saveAuth,
  getUserData,
  getRedirectUrl,
  clearRedirect,
  shouldCompleteProfile
} from "lib/Auth";
import InputError from "views/components/InputError";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      working: false,
      errors: {},
      loggedIn: !!localStorage.getItem('accessToken'),
      completeProfile: false
    }
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  handleChange = (e) => {
    let {name, value} = e.target;
    this.setState({[name]: value});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {email, password} = this.state;
    let data = {email, password};

    this.setState({working: true});

    apiRequest("/auth/login", {data})
      .then(response => {
        saveAuth(response.data);
        return this.redirectAfterLogin();
      })
      .catch(() => {
        this.setState({errors: {password: 'E-mail ou senha estão inválidos'}});
      })
      .finally(
          () => this.setState({working: false})
      );
  }

  redirectAfterLogin() {
    let user = getUserData();

    if (user.role === 'super_admin' || user.role === 'specialist') {
      this.setState({loggedIn: true});
      return true;
    }
    if (!user.organization || user.adminOrganization) {
      this.setState({loggedIn: true});
      return true;
    }

    return shouldCompleteProfile()
      .then(result => {
        if (result) {
          this.setState({completeProfile: user.id});
        }
        else {
          this.setState({loggedIn: true});
        }
      });
  }

  getRedirectUrl() {
    let qs = this.props.location.search || '';
    if (qs) {
      let params = queryString.parse(qs);
      if (Number(params.redirect) === 1) {
        return getRedirectUrl();
      }
    }
    let user = getUserData();
    console.log(user);
    if (user.role === 'patient') {
      return '/admin/patient/meu-programa';
    }
    return '/admin/dashboard';
  }

  render() {
    if (this.state.loggedIn) {
      const redirectTo = this.getRedirectUrl();
      clearRedirect();
      return <Redirect to={redirectTo} />;
    }

    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card className="card-login text-center">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                      <div className="social">
                      </div>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className={hasError.email}>
                      <InputGroup style={{marginBottom:0}}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email..." type="text"
                          name="email"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="email" errors={this.state.errors} />
                    </FormGroup>
                    <FormGroup className={hasError.password}>
                      <InputGroup style={{marginBottom:0}}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Senha"
                          type="password"
                          autoComplete="off"
                          name="password"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputError name="password" errors={this.state.errors} />
                    </FormGroup>
                    <Row>
                    <Col style={{textAlign: 'right'}}>
                    <Link to={"/auth/forgot-password"} style={{color: '#1479F6'}}>
                      Esqueci minha Senha
                    </Link>
                    </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      disabled={this.state.working}
                    >
                      Entrar
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/background-login.png")})`
          }}
        />
      </div>
    );
  }
}

export default Login;
