import React, {useState} from "react";
import {Button, Card, CardBody, CardFooter, Col, Modal, ModalFooter, Row} from "reactstrap";

class ChallengeFront extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div style={{margin: '20px 20px 0 20px '}}>
                    <Row>
                        <Col md={12}>
                            <div className={'text-center'}>
                                <img src={this.props.logo} width="380" height="180" alt="Nutrideck"/>
                            </div>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className={'challenge title-card-front'}>
                                            {this.props.title}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="challenge title-card-content">
                                            {this.props.description}
                                        </Col>
                                        <div style={{minHeight: '200px'}}>&nbsp;</div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ChallengeFront;
