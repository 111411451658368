import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {mapToCssModules} from "reactstrap/lib/utils";


const propTypes = {
    children: PropTypes.node,
    row: PropTypes.bool,
    check: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    tag: PropTypes.string,
    className: PropTypes.string,
    cssModule: PropTypes.object,
};

const defaultProps = {
    tag: 'div',
};

const FormGroupLabel = (props) => {
    const {
        className,
        cssModule,
        row,
        disabled,
        check,
        inline,
        tag: Tag,
        ...attributes
    } = props;

    const classes = mapToCssModules(classNames(
        className,
        'position-relative',
        row ? 'row' : false,
        check ? 'form-check' : 'form-label-group',
        check && inline ? 'form-check-inline' : false,
        check && disabled ? 'disabled' : false
    ), cssModule);

    return (
        <Tag {...attributes} className={classes} />
    );
};

FormGroupLabel.propTypes = propTypes;
FormGroupLabel.defaultProps = defaultProps;

export default FormGroupLabel;
