import React from "react";

import apiRequest from "../../../../lib/Api";
import {getUserData} from "../../../../lib/Auth";
import {Link} from "react-router-dom";
import StatusProgram from "../StatusProgram/StatusProgram";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import ExamIndex from "../Files/ExamIndex";
import ItensProntuario from "../ItensProntuario/ItensProntuario";
import ButtonChat from "../../../../components/ButtonChat/ButtonChat";

class TratamentoIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            me: getUserData(),
            specialist: {id: null},
            working: false,
            horizontalTabs: 'prontuario',
            exams: {}
        };
    }

    async componentDidMount() {

        let dashboard = await apiRequest("/me/dashboard", {method: "GET"});
        const responseMedicalRecord = await apiRequest(`/patient/${this.state.me.id}/medical-record`, {method: "GET"})
        this.setState({me: dashboard.data.me, specialist: dashboard.data.specialist, exams: responseMedicalRecord.data.exams});

    }

    renderStatusProgram() {
        if (!this.state.me.program_status) {
            return (<></>)
        }
        return (
            <StatusProgram resume={this.state.me.program_status}/>
        )
    }

    renderExams() {
        if (!this.state.me.program_status) {
            return (<></>)
        }
        return (
          <ItensProntuario exams={this.state.exams}/>
        )
    }
    renderChatButton() {


        if (!this.state.specialist.id)
        {
            return (<></>)
        }
        return (
            <ButtonChat
                user={this.state.specialist}
                label="Fale com o Nutricionista"
            />
        )
    }
    render() {
        return (
            <>
                <div className="content home-member-content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md={8} sm={6}>
                                            <CardTitle tag="h4">Meu Programa</CardTitle>
                                        </Col>
                                        <Col md={4} sm={6} style={{textAlign: 'right'}}>
                                            {this.renderChatButton()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>Data de Entrada: {' '}</Col>

                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            Andamento do
                                            programa: {this.state.me.program_status ? this.state.me.program_status.service : " não iniciado"}
                                            {this.renderStatusProgram()}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="nav-tabs-navigation" >
                                        <div className="nav-tabs-wrapper">
                                            <Nav id="tabs" role="tablist" tabs >
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "prontuario"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "prontuario"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            this.setState({horizontalTabs: "prontuario"})
                                                        }
                                                    >
                                                        <b>Jornada Nutricional</b>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "exames"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "exames"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            this.setState({horizontalTabs: "exames"})
                                                        }
                                                    >
                                                        <b>Exames</b>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>


                                    </div>
                                    <TabContent
                                        className="text-center"
                                        id="my-tab-content"
                                        style={{backgroundColor: '#efefef'}}
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="prontuario" role="tabpanel"
                                                 style={{backgroundColor: '#efefef', padding: "20px"}}>
                                            {this.renderExams()}
                                        </TabPane>
                                    </TabContent>
                                    <TabContent
                                        className="text-center"
                                        id="exames"
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="exames" role="tabpanel"
                                        >
                                            <ExamIndex patient_id={this.state.me.id}/>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default TratamentoIndex
