import React from "react";
import {Redirect} from "react-router-dom";
import {getUserData} from "lib/Auth";
import Select from "react-select";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";
import situations from "../../../variables/situations";

class AppointmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: '',
      userData: getUserData(),
      situation: '',
      working: false,
      redirect: '',
      errors: {}
    }
    this.situationsDef = situations.map(situation =>
        ({label: situation.label, value: situation.id})
    );
  }

  componentDidMount() {
    if (this.id) {
      this.fetchItem();
    }
  }

  fetchItem() {
    return apiRequest("/appointments/" + this.id, {method: "GET"})
        .then(response => {
          let appointment = response.data.data;
          appointment.situation = this.situationsDef.find(option => option.value === appointment.situation);
          this.setState(appointment);
        });
  }

  get id() {
    return this.props.match.params.id;
  }

  get formTitle() {
    return 'Alteração do agendamento';
  }

  clearForm() {
    this.setState({
      notes: '',
      situation: '',
      errors: {}
    });
  }

  handleChange = (name, value) => {
    if (typeof name == 'object' && typeof name.target == 'object') {
      let event = name;
      event.preventDefault();
      name = event.target.name;
      value = event.target.value;
    }
    
    this.setState({
      [name]: value
    });

    let newErrors = {...this.state.errors, [name]: ''};
    this.setState({errors: newErrors});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {notes, situation} = this.state;
    situation = situation ? situation.value : null;
    let data = {
      notes,
      situation
    };
    this.setState({working: true});
    let request = apiRequest("/appointments/" + this.id, {data, method: 'PUT'});
    request.then(response => {
        this.clearForm();
        this.props.notify({type: 'success', message: 'Agendamento alterado com sucesso'});
        this.setState({redirect: `/admin/appointments/${this.id}/show`});
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }
  
  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const permitirCampoSituacao = () => {
      if (this.state.userData.role === 'super_admin') {
        return <Row>
          <Label sm="2">Situação:</Label>
          <Col sm="10">
            <FormGroup className={hasError.notes}>
              <Select name="situation" value={this.state.situation || ''}
                      onChange={value =>
                          this.handleChange("situation", value)
                      }
                      className="react-select primary"
                      classNamePrefix="react-select"
                      options={[
                        {
                          value: "",
                          label: "Selecione uma Situação",
                          isDisabled: true
                        },
                        ...this.situationsDef
                      ]}>


              </Select>
              <InputError name="situation" errors={this.state.errors}/>
            </FormGroup>
          </Col>
        </Row>
      }
    }

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{this.formTitle}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">Notas</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.notes}>
                          <Input type="textarea" name="notes" value={this.state.notes || ''}
                            onChange={this.handleChange}
                          />
                          <InputError name="notes" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                      {permitirCampoSituacao()}
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                                  disabled={this.state.working} onChange={this.handleChange}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(AppointmentForm);
