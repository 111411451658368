import React from "react";
import {Link} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import apiRequest from "../../../../lib/Api";
import {getUserData} from "../../../../lib/Auth";
import AppointmentShow from "../../appointments/AppointmentShow";
import Slider from "react-slick";
import AppointmentCard from "../../appointments/AppointmentCard";
import Appointment from "../../appointments/Appointment";

// reactstrap components
function SliderArrows(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}
        />
    );
}

class AppointmentsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: getUserData(),
            appointmentModal: null,
            currentAppointments: [],
            completeAppointments: [],
            specialist: {id:null}
        };

        this.settingsSlider = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            nextArrow: <SliderArrows />,
            prevArrow: <SliderArrows />
        }
    }

    getAppointments = async () => {

        await apiRequest(`/appointments?user_id=${this.state.userData.id}&only_next=true`, {
            method: 'GET'
        }).then(success => {
            console.log(success);
            let currentAppointments = [];
            let completeAppointments = [];
            for (const appointment of success.data.data) {
                if (appointment.situation === 'approved' || appointment.situation === 'started') {
                    currentAppointments.push(appointment);
                }
                if (appointment.situation === 'done') {
                    completeAppointments.push(appointment);
                }
            }
            this.setState({currentAppointments: currentAppointments});
            this.setState({completeAppointments: completeAppointments});
        }).catch(error => {});
    }

    async componentDidMount() {

        await this.getAppointments();
        await apiRequest('/user/my-specialist', {
            method: 'GET'
        }).then(success => {
            const specialist = success.data.data;
            this.setState({specialist: specialist});
        })
    }

    renderCard = (appointment) => {
        return (
            <AppointmentCard
                key={appointment.id}
                appointment={appointment}
                label=' '
                buttonOnTop={true}
            />
        );
    }

    renderAppointmentCards(cards) {

        if (cards.length === 0) {
            return (
                <Row>
                    <Col>
                        Sem agendamentos.
                    </Col>
                </Row>
            )
        }
        if (window.outerWidth < 600)  {
            return (cards.map(this.renderCard))
        }
        return (
            <Slider {...this.settingsSlider}>
                {cards.map(this.renderCard)}
            </Slider>
        )
    }

    openAppointment = (appointment) => {

        if (this.state.userData.role === "patient") {
            this.props.history.push(`/admin/appointments/user`);
            return;
        }

        if (!appointment) {
            appointment = false;
        }
        this.setState({
            appointmentModal: (
                <Appointment
                    open={true}
                    isSpecialist={false}
                    patient={this.state.userData}
                    specialist={this.state.specialist}
                    appointment={appointment}
                    allowClose={true}
                    onHide={(success) => {
                        this.setState({appointmentModal: null});
                        this.getAppointments();
                        if (success) {
                            this.props.notify({type: 'success', message: 'Agendamento salvo com sucesso.'});
                        }
                    }}
                />
            )
        })
    }
    render() {
        return (
            <>
                {this.state.appointmentModal}
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={8} md={8}>
                                    <CardTitle tag="h4">Agendamentos</CardTitle>
                                        </Col>
                                    <Col  sm={4} md={4}>
                                        <div className="float-right" style={{textAlign: 'right'}}>
                                            <Button color='primary'
                                                    onClick={this.openAppointment}>
                                                <i className="fa fa-check"></i>
                                                Nova Consulta
                                            </Button>
                                        </div>
                                    </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h5>Próximos</h5>
                                </CardHeader>
                                <CardBody>
                                    {this.renderAppointmentCards(this.state.currentAppointments)}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h5>Concluídos</h5>
                                </CardHeader>
                                <CardBody>
                                    {this.renderAppointmentCards(this.state.completeAppointments)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </>
        );
    }
}

export default withNotify(AppointmentsList);
