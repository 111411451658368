import React from "react";
import {Link} from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText
} from "reactstrap";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import apiRequest from "../../lib/Api";
import {updateUserData} from "../../lib/Auth";
import withNotify from "../../lib/NotificationWrapper";
import {toCurrency} from "../../lib/Formatter";
import InputError from "../components/InputError";

import defaultAvatar from "assets/img/default-avatar.png";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: '',
        email: '',
        role: '',
        picture: ''
      },
      originalPicture: '',
      purchases: [],
      working: false,
      errors: {}
    };
  }

  componentDidMount() {
    this.fetchProfile()
      .then(user => {
        if (user.role == 'student') {
          this.fetchPurchases();
        }
      });
  }

  fetchProfile() {
    return apiRequest("/auth/me", {method: "POST"})
        .then(response => {
          this.setProfile(response.data);
          return response.data;
        });
  }

  setProfile(user) {
    this.setState({
      user: {...user, picture: user.pictureUrl},
      originalPicture: user.pictureUrl
    });
  }

  fetchPurchases() {
    return apiRequest("/purchases", {method: "GET"})
        .then(response => {
          this.setState({purchases: response.data.data});
        });
  }

  handleChange = (e) => {
    let {name, value} = e.target;

    this.setState({
      user: {...this.state.user, [name]: value}
    });

    if (this.state.errors[name]) {
      this.setState({errors: {...this.state.errors, [name]: ''}});
    }
  }

  handleChangeImage = file => {
    this.setState({
      user: {...this.state.user, picture: file}
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {id: userId} = this.state.user;
    if (!userId) {
      return;
    }

    let {name, email, bio, picture} = this.state.user;
    let data = {name, email, bio};
    if (picture) {
      data.picture = picture;
    }

    let formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    // indicates api to not require all fields
    formData.append('update_profile', 1);

    this.setState({working: true});

    apiRequest("/users/" + userId, {data: formData, method: 'PUT'})
      .then(response => {
        let user = response.data.data;
        this.setProfile(user);
        updateUserData('pictureUrl', user.pictureUrl);
        this.props.notify({type: 'success', message: 'Informações armazenadas com sucesso'});
        window.location.reload();
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  renderPurchases() {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Compras</CardTitle>
        </CardHeader>
        <CardBody>

          {!this.state.purchases.length &&
            <p>Nenhuma compra até o momento</p>
          }

          <ul className="list-unstyled team-members">

            {this.state.purchases.map(purchase => {
              return (
                <li key={purchase.id}>
                  {this.renderPurchase(purchase)}
                </li>
              );
            })}

          </ul>
        </CardBody>
      </Card>
    );
  }
  get role() {
    switch (this.state.user.role) {
      case 'patient':
        return 'patient';
      case 'specialist':
        return 'specialists';
      default:
        return 'patient';
    }
  }
  renderPurchase(item) {
    return (
      <Row>
        <Col md="7" xs="7">
          {item.product.name} <br />
          <span className="text-muted">
            <small>Desde {item.purchased_at}</small>
          </span>
        </Col>
        <Col className="text-right" md="5" xs="5">
          {toCurrency(item.price_paid)}
        </Col>
      </Row>
    );
  }

  render() {
    let avatar = this.state.user.pictureUrl || defaultAvatar;
    let hasError = InputError.convertToHasClass(this.state.errors);

    return (
      <>
        <div className="content">
          <Row>
            <Col md="4">
              <Card className="card-user">
                <div className="image">

                </div>
                <CardBody>
                  <div className="author">
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={avatar}
                      />
                  </div>
                </CardBody>
              </Card>

              {this.state.role == 'student' && this.renderPurchases()}

            </Col>

            <Col md="8">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <h5 className="title">
                      Editar informações
                      <small>
                        <Button tag={Link} to={'/admin/'+this.role+'/me/edit'} color="default" className="btn-link"
                          size="sm"
                        >
                          Ver todos os campos
                        </Button>
                      </small>
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup className={hasError.name}>
                          <label>Nome</label>
                          <Input
                            value={this.state.user.name}
                            name="name"
                            onChange={this.handleChange}
                            type="text"
                            placeholder="Seu nome completo"
                          />
                          <InputError name="name" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className={hasError.email}>
                          <label>E-mail</label>
                          <Input
                            value={this.state.user.email}
                            name="email"
                            disabled={true}
                            placeholder="Seu e-mail"
                            type="text"
                          />
                          <InputError name="email" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    {this.state.user.role == 'specialist' &&
                    <Row>
                      <Col md="12">
                        <FormGroup className={hasError.bio}>
                          <label>Bio</label>
                          <Input type="textarea" name="bio" value={this.state.user.bio}
                            onChange={this.handleChange}
                            placeholder="Digite um resumo breve sobre você"
                            style={{height: '120px', maxHeight: '120px'}}
                          />
                          <InputError name="bio" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    }

                    <Row>
                      <Label sm="2">Imagem de perfil</Label>
                      <Col md="10" sm="10">
                        <ImageUpload onChangeImage={this.handleChangeImage}
                          originalUrl={this.state.originalPicture}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter style={{position:"sticky", bottom:"0px", textAlign:"right" }}>
                    <Button className="btn" color="primary" type="submit"
                      disabled={this.state.working}
                    >
                      Salvar
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(UserProfile);
