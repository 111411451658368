class ApiError extends Error {
  constructor(status = 0, data = {}, ...params) {
    super(...params);
    
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.status = status;
    this.data = data;
    this.name = 'ApiError';
  }
}

export default ApiError;
