import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Table,
} from "reactstrap";


import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import withNotify from "../../../lib/NotificationWrapper";

import PaginationCtrl from "components/Pagination/PaginationCtrl";
import moment from "moment";
import {getUserData} from "../../../lib/Auth";


class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            meta: {},
            filter: '',
            exportInterval: 30,
            currentUser: getUserData()
        };
    }

    get scope() {
        if (this.props.match.path.match(/patient/)) {
            return 'patients';
        } else if (this.props.match.path.match(/specialists/)) {
            return 'specialists';
        }
        throw new Error("Invalid scope");
    }

    get role() {
        if (this.scope === 'patients') {
            return 'patient';
        }
        if (this.scope === 'specialists') {
            return 'specialist';
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        // TODO use params instead
        if (this.props.match.path !== prevProps.match.path) {
            this.requestData();
        }
    }

    componentDidMount() {
        if (this.state.currentUser.role === 'patient') {
            return;
        }
        this.requestData();
    }

    requestData = page => {
        let data = {
            page: page || 1
        };

        if (this.props.match.params.organizationId) {
            data.organization_id = this.props.match.params.organizationId;
        } else {
            data.searchRole = this.role;
        }

        if (this.state.filter) {
            data.search = this.state.filter;
        }

        apiRequest("/users", {method: "GET", data})
            .then(response => {
                let {data, meta} = response.data;
                this.setState({users: data, meta});
            });
    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    filterChange = (event) => {
        let value = event.target.value;
        this.setState({filter: value});
    }

    executeFilter = (event) => {
        event.preventDefault();
        this.requestData();
    }

    maybeDeleteUser = (id) => {
        this.setState({deleteUserId: id});
        this.toggleConfirmation(true);
    }

    deleteUser = () => {
        const id = this.state.deleteUserId;
        apiRequest("/users/" + this.state.deleteUserId, {method: "DELETE"})
            .then(() => {
                this.props.notify({type: 'success', message: 'Usuário removido com sucesso'});

                let users = this.state.users.filter(user => user.id !== id);
                this.setState({users});
            })
            .catch(() => {
                this.props.notify({type: 'error', message: 'Não foi possível remover o usuário'});
            });
    }

    changeInterval = event => {
        let exportInterval = event.target.value;
        this.setState({exportInterval});
    }

    export = () => {
        let data = {
            interval: this.state.exportInterval
        };
        apiRequest("/users/export", {method: "GET", data}, {blob: true})
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'users.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    }

    renderStatus(user) {
        return user.user_program_status ? user.user_program_status.current_service.display_name : " Nenhuma consulta Agendada ";
    }

    renderLastAppointment(user) {
        if (user.user_program_status && user.user_program_status.current_appointment) {
            if (user.user_program_status.current_appointment.date) {
                return moment(user.user_program_status.current_appointment.date).format('DD/MM/YYYY');
            }
        }
        return " - ";
    }

    render() {

        if (this.state.currentUser.role === 'patient') {
            return (<></>);
        }
        let {users} = this.state;


        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Pacientes</CardTitle>
                                    <Button color="white"
                                            tag={Link}
                                            to={'/admin/patient/new'}
                                            className='float-right white-button'>
                                        <i className="fas fa-plus"></i>
                                        Cadastrar paciente</Button>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal">
                                        <Row>
                                            <Col md="10">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <Input placeholder="Nome ou e-mail" type="text"
                                                               onChange={this.filterChange} value={this.state.filter}
                                                               onKeyPress={event => {
                                                                   if (event.which === 13) {
                                                                       event.preventDefault();
                                                                       this.executeFilter(event);
                                                                   }
                                                                 }
                                                               }
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText style={{borderColor:'#848484'}}>
                                                                <i className="fa fa-search"
                                                                   onClick={this.executeFilter}/>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </Form>
                                    <Table responsive striped hover>
                                        <thead>
                                        <tr>

                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Andamento no Programa</th>
                                            <th>Último Agendamento</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {users.map(user => {

                                            return <tr key={user.id}>
                                                <td><Link to={`/admin/patient/${user.id}/details`}>{user.name}</Link>
                                                </td>
                                                <td>{user.email}</td>
                                                <td>{this.renderStatus(user)}</td>
                                                <td>{this.renderLastAppointment(user)}</td>
                                            </tr>;

                                        })}

                                        </tbody>
                                    </Table>
                                    <PaginationCtrl responseMeta={this.state.meta}
                                                    requestPage={this.requestData}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <ConfirmModal message="Tem certeza que deseja remover este usuário?"
                              onConfirm={() => this.deleteUser()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />
            </>
        );
    }
}

export default withNotify(UserList);
