import React from "react";
import {Link} from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import FormatText from 'components/Text/FormatText.jsx';

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import {toCurrency} from "../../../lib/Formatter";

class ProductPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null
    }
  }

  componentDidMount() {
    this.fetchItem();
  }

  fetchItem() {
    return apiRequest("/products/code/" + this.code, {method: "GET"})
        .then(response => this.setState({product: response.data.data}));
  }

  get code() {
    if (this.props.match.params) {
      return this.props.match.params.code;
    }
    return null;
  }

  render() {
    let {product} = this.state;
    if (!product) {
      return (
        <>
          <div className="content">
            Carregando...
          </div>
        </>
      );
    }

    return (
      <>
        <div className="content product-purchase-container">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="product-title text-selfguru">
                    <b>{product.name}</b>
                  </CardTitle>
                  <Row noGutters>
                    <Col sm="auto">
                      <img className="product-image" src={product.image} />
                    </Col>
                    <Col sm="10" md="8">
                      <FormatText className="product-desc1" content={product.description1} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <div className="product-desc2 text-selfguru">
                        <FormatText content={product.description2} />
                      </div>
                      <div className="product-desc3">
                        <FormatText content={product.description3} />
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="product-payment-block text-center">
                        
                        {product.price > 0.00 &&
                        <React.Fragment>
                          <h3 className="text-selfguru product-price">
                            {product.installments} x {toCurrency(product.price)}
                          </h3>
                          <h5 className="text-selfguru">
                            {product.payment}
                          </h5>
                        </React.Fragment>
                        }

                        {product.price == 0.00 &&
                        <h3 className="text-selfguru product-price">
                          GRÁTIS
                        </h3>
                        }
                        
                        <Button className="btn-round" color="selfguru"
                          to={`/setup/product/checkout/${product.id}`}
                          tag={Link}
                        >
                          {product.price > 0.00 ? 'Clique para comprar' : 'Clique para continuar'}
                        </Button>
                        <p>{product.next_step}</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col sm="12" className="text-right">
                      <Link className="text-selfguru" to="/admin/dashboard">Não comprar agora</Link>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(ProductPurchase);
