import React from "react";
import {Link} from "react-router-dom";
import {Button, CardTitle} from "reactstrap";

class HeaderProntuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            link: this.props.link,
            buttonText: this.props.buttonText,
            title: this.props.title,
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.title !== this.props.title ) {
            this.setState({title: this.props.title})
        }
        if (prevProps.buttonText !== this.props.buttonText ) {
            this.setState({buttonText: this.props.buttonText})
        }
        if (prevProps.link !== this.props.link ) {
            this.setState({link: this.props.link})
        }
    }

    renderTitle() {
        if (!this.state.title || this.state.title === '') {
            return (<></>);
        }
        let title = this.state.title;
        return (
            <>
                <CardTitle>
                    {title}
                </CardTitle>
            </>
        )
    }
    render() {
        let link = this.state.link;
        let text = this.state.buttonText;
        return (
            <>
                { this.renderTitle() }
                <Button outline color="primary"
                        tag={Link}
                        to={link}
                >
                    <i className="fa fa-arrow-circle-left"/>
                    {text}
                </Button>
            </>
        );
    }
}

export default HeaderProntuario;
