import React from "react";
import './StatusProgram.scss';

export default class StatusProgram extends React.Component {

    constructor(props) {
        super(props);
        console.log(props.resume);

        let totalItens = props.resume.total_itens ? props.resume.total_itens : 0;
        let current = props.resume.current ? props.resume.current : 0;
        let userStatus = props.resume.status ? props.resume.status : 'status';
        console.log(props.resume);
        const items = [];
        for (let i = 0; i < totalItens; i++) {
            let status = 'li';
            if (i < current - 1) {
                status = 'li complete'
            }
            if (i === current - 1) {
                console.log("aqui - " + userStatus);
                status = "li " + userStatus;
            }
            let item = {
                id: i,
                status: status,
                timestampClass: i < totalItens - 1 ? 'timestamp' : 'timestamp-last'
            }
            items.push(item);

        }
        this.state = {
            items: items,
        }
    }


    render() {
        return (
            <>
                <div className="status-program">
                    <ul className="timeline">
                        {this.state.items.map((item) => {
                            return (
                                <li className={item.status} key={item.id}>
                                    <div className={item.timestampClass}>
                                    </div>
                                    <div className="status">
                                    </div>
                                </li>
                            )
                        })}

                    </ul>
                </div>
            </>
        );
    }

}
