import React from "react";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create sweet alerts
import {Link} from 'react-router-dom';

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import {calendarMessages} from "../../../lib/Locale";

// reactstrap components
import {Button, Card, CardBody, Col, Row} from "reactstrap";

const localizer = BigCalendar.momentLocalizer(moment);
const DEFAULT_VIEW = 'agenda';

class AppointmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            userData: getUserData(),
            view: DEFAULT_VIEW,
            confirmAlert: ''
        };
    }

    componentDidMount() {
        this.requestData();
    }

    requestData() {
        return apiRequest('/appointments', {method: 'GET'})
            .then(response => {
                let events = [];

                for (let appointment of response.data.data) {
                    let newEvent = {
                        id: appointment.id,
                        start: moment(appointment.datetime_start).toDate(),
                        end: moment(appointment.datetime_end).toDate(),
                        title: this.getEventTitle(appointment),
                        shortTitle: this.getShortEventTitle(appointment),
                        situation: appointment.situation
                    };
                    events.push(newEvent);
                }

                this.setState({events});
            });
    }

    getEventTitle(event) {
        const user = this.state.userData;
        let title = event.product_title || 'Agendamento';
        if (user.role == 'specialist') {
            return title + ` com ${event.user_name}`;
        }
        if (user.id != event.user_id) {
            return title + ` de ${event.user_name} com ${event.specialist_name}`;
        }
        return title + ` com ${event.specialist_name}`;
    }

    getShortEventTitle(event) {
        if (this.state.userData.role == 'specialist') {
            return event.user_name;
        }
        return event.specialist_name;
    }

    onView = view => {
        this.setState({view});
    }

    renderChipNoExecuted(event) {

        if (event.situation === 'missed') {
            return (
                <>
                    &nbsp;&nbsp;
                    <span className={'nutrideck-chip-missed'}>Não Realizada</span>
                </>
            )
        }
    }

    render() {
        let formats = {
            dayHeaderFormat: 'dddd'
        };

        return (
            <>
                <div className="content" style={{backgroundColor: 'white'}}>
                    <Row style={{borderBottom: '1px solid #E0E0E0'}}>
                        <Col md="6">
                            <div style={{paddingTop: '10px'}}>
                                <span className={'nutrideck-card-title'}>Horários agendados</span>
                            </div>
                        </Col>
                        <Col md="6">
                            <div style={{textAlign: 'right'}}>
                                <Button color="primary"

                                        to={"/admin/schedule-exceptions/list"}
                                        tag={Link}
                                >
                                    Alterar Disponibilidade
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card className="card-calendar" style={{width: '100%'}}>
                                <CardBody>
                                    <BigCalendar
                                        selectable
                                        className={`appointment-list-calendar ${this.state.view}-view`}
                                        localizer={localizer}
                                        culture='pt-BR'
                                        events={this.state.events}
                                        defaultView={DEFAULT_VIEW}
                                        timeslots={1}
                                        formats={formats}
                                        scrollToTime={new Date(1970, 1, 1, 6)}
                                        defaultDate={new Date()}
                                        messages={calendarMessages}
                                        views={['week', 'day', 'agenda']}
                                        onView={this.onView}
                                        titleAccessor={event => {
                                            return (
                                                <React.Fragment>
                          <span className={event.situation == 'canceled' ? 'cancelled' : ''}>
                            {this.state.view != 'week' ? event.title : event.shortTitle}
                          </span>
                                                    {this.renderChipNoExecuted(event)}
                                                    &nbsp;&nbsp;
                                                    <Link to={`/admin/appointments/${event.id}`}>
                                                        <b>Ver mais</b>
                                                    </Link>
                                                </React.Fragment>
                                            );
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}

export default withNotify(AppointmentList);
