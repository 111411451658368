import React from 'react';
import ReactMarkdown from 'react-markdown';

class FormatText extends React.Component {
  render() {
    return (
      <ReactMarkdown className={this.props.className || ''} source={this.props.content} />
    );
  }
}

export default FormatText;