import React from "react";

// reactstrap components
import moment from "moment";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import apiRequest from "../../../../lib/Api";
import withNotify from "../../../../lib/NotificationWrapper";
import ReactDatetime from "react-datetime";
import uuid from "react-uuid";
import slugify from "react-slugify";
import ReactBSAlert from "react-bootstrap-sweetalert";
import PatientPlanMeal from "./PatientPlanMeal";
import HeaderProntuario from "../../../../components/Header/HeaderProntuario";

class PlanoAlimentarIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDeleteMeal: null,
            patient: {},
            meals: [],
            customMeals: [],
            openModalSuggestHour: false,
            openModalCustomFood: false,
            openModalPlan: false,
            mealSelected: {},
            mealPlan: {meals: [], status: 'draft'},
            customMealName: '',
            customMealHour: '',
            customMealNotes: '',
            userMealPlanModal: null,
            openModalNotes: false,
            confirmAlert: ''

        }
    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    start = async () => {

        await apiRequest(`/patient/${this.id}/plan-meal`, {method: 'GET'}
        ).then(success => {
            this.setState({mealPlan: success.data.data});
        }).catch(async error => {
            await apiRequest(`/patient/${this.id}/plan-meal`, {method: 'POST'}
            ).then(postSuccess => {
                this.setState({mealPlan: postSuccess.data.data});
            }).catch(postError => {
                this.setState({mealPlan: {}});
            })
        });
        this.organizeMeals();
    }

    handleChangeMealHour = (data, type) => {
        if (moment.isMoment(data)) {
            const newHour = data.format('HH:mm');
            if (type === '1') {
                this.setState({mealSelected: {...this.state.mealSelected, suggestHour: newHour}})
            }
            if (type === '2') {
                this.setState({customMealHour: newHour});
            }
        }
    }

    handleChangeMealNotes = (value) => {

        this.setState({mealSelected: {...this.state.mealSelected, notes: value}})

    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    async componentWillMount() {
        if (this.id) {
            await this.getUserData();
        }
        await this.start();
        await this.organizeMeals();
    }

    organizeMeals = () => {
        const mealsType = ['Café da Manhã', 'Colação', 'Almoço', 'Lanche', 'Jantar', 'Ceia'];
        const suggestHour = ['08:30', '10:30', '12:00', '16:00', '19:00', '22:00'];
        const slug = ['breakfast', 'collation', 'lunch', 'snack', 'dinner', 'supper']
        let meals = [];
        let index = 0;
        for (const meal of mealsType) {

            let mealToPush = {
                "key": uuid(),
                "suggestHour": suggestHour[index],
                "name": meal,
                "uuid": null,
                "allow": false,
                "notes": '',
                "resume": null,
                "resume_percentage": null,
                "type": slug[index],
            };
            const mealCreated = this.searchMeal(mealToPush);
            if (mealCreated) {
                mealToPush.allow = (mealCreated.resume && mealCreated.resume.calorias > 0);
                mealToPush.resume = mealCreated.resume;
                mealToPush.resume_percentage = mealCreated.resume_percentage;
                mealToPush.uuid = mealCreated.uuid;
                mealToPush.notes = mealCreated.notes;
                mealToPush.suggestHour = mealCreated.hour.substring(0, 5);
            }
            meals.push(mealToPush);
            index++;
        }

        for (const meal of this.state.mealPlan.meals) {
            if (meal.type.substring(0, 7) === 'custom_') {
                meal.key = uuid();
                meal.allow = (meal.resume && meal.resume.calorias > 0);
                meal.notes = (meal.notes);
                meal.suggestHour = meal.hour.substring(0, 5);
                meals.push(meal);
            }
        }
        this.setState({meals: meals});
    }

    getUserData = async () => {
        const response = await apiRequest(`/patient/${this.id}`, {method: "GET"})
        const user = response.data.data;
        this.setState({patient: user});
    }

    changeSuggestHour = async () => {

        const meal = this.searchMeal(this.state.mealSelected);
        if (meal === false) {
            this.props.notify({type: 'error', message: 'Não foi possível encontar esta refeição.'});
            return false;
        }
        const route = `/patient/${this.id}/plan-meal/${this.state.mealPlan.id}/meals/${meal.uuid}`;
        await apiRequest(route, {
            method: 'PUT',
            data: {
                hour: this.state.mealSelected.suggestHour
            }
        }).then(success => {
            this.toggleModalSuggestHour();
            this.start();
            this.props.notify({type: 'success', message: `Horário sugerido para ${meal.name} alterado com sucesso.`});
        }).catch(error => {
            this.props.notify({
                type: 'error',
                message: `Não foi possível alterar o horário sugerido para ${meal.name}.`
            });
        });
    }

    saveMealNotes = async () => {

        const meal = this.searchMeal(this.state.mealSelected);
        if (meal === false) {
            this.props.notify({type: 'error', message: 'Não foi possível encontar esta refeição.'});
            return false;
        }
        const route = `/patient/${this.id}/plan-meal/${this.state.mealPlan.id}/meals/${meal.uuid}`;
        await apiRequest(route, {
            method: 'PUT',
            data: {
                notes: this.state.mealSelected.notes,
                hour: this.state.mealSelected.suggestHour
            }
        }).then(success => {
            this.toggleModalNotes();
            this.start();
            this.props.notify({type: 'success', message: `Observações para ${meal.name} adicionadas com sucesso.`});
        }).catch(error => {
            this.props.notify({
                type: 'error',
                message: `Não foi possível informar notas para ${meal.name}.`
            });
        });
    }

    searchMeal = (mealSelected) => {

        for (let meal of this.state.mealPlan.meals) {
            if (meal.type === mealSelected.type) {
                return meal;
            }
        }
        return false;
    }

    mealRedirect = (mealCreated) => {
        const redirectTo = `/admin/patient/${this.id}/refeicao/${mealCreated.uuid}/plano/${mealCreated.meal_plan}/alimentos`;
        this.props.history.push(redirectTo);
    }

    addCustomFood = () => {
        const customName = this.state.customMealName;
        const customHour = this.state.customMealHour;
        const customNotes = this.state.customMealNotes;
        if (customName.trim() === "" || customHour.trim() === "") {
            return false;
        }

        apiRequest(`/patient/${this.id}/plan-meal/${this.state.mealPlan.uuid}/meals`, {
            method: 'POST',
            data: {
                'type': `custom_${slugify(customName)}`,
                'hour': customHour,
                'name': customName,
                'notes': customNotes
            }
        }).then(success => {
            this.toggleModalCustomFood();
            this.start();
            this.props.notify({type: 'success', message: `Refeição ${customName} salva com sucesso.`});
        }).catch(error => {
            this.props.notify({type: 'error', message: 'Não foi possível salvar a refeição.'});
        });
    }

    createMeal = (mealSelected) => {

        const meal = this.searchMeal(mealSelected);
        if (meal) {
            this.mealRedirect(meal);
        } else {
            apiRequest(`/patient/${this.id}/plan-meal/${this.state.mealPlan.uuid}/meals`, {
                method: 'POST',
                data: {
                    'type': mealSelected.type,
                    'hour': mealSelected.suggestHour,
                    'name': mealSelected.name,
                    'notes': mealSelected.notes
                }
            }).then(success => {
                this.mealRedirect(success.data.data)
            }).catch(error => {
                this.props.notify({type: 'error', message: 'Não foi possível cadastrar esta refeição.'});
            });
        }
    }

    hideAlertDeleteMeal = () => {
        this.setState({confirmDeleteMeal: null});
    };

    confirmDeleteMeal = (mealSelected) => {
        this.setState({
            confirmDeleteMeal: (
                <ReactBSAlert
                    warning
                    style={{display: "block", marginTop: "-200px"}}
                    title={`Deseja excluir a refeição ${mealSelected.name}?`}
                    onConfirm={() => this.deleteMeal(mealSelected)}
                    onCancel={() => this.hideAlertDeleteMeal()}
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Confirmar"
                    cancelBtnText="Cancelar"
                    showCancel
                >
                </ReactBSAlert>
            )
        });
    }

    renderMealNotes = () => {

        let mealSelected = this.state.mealSelected;
        if (!mealSelected) {
            return <></>
        }
        return (
            <Modal isOpen={this.state.openModalNotes} size="md">
                <ModalHeader>
                    <span className='text-center'>Observações para {this.state.mealSelected.name}</span>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <FormGroup>
                                    <textarea style={{height: '100%', width: '100%'}} rows={10}
                                              onChange={(e) => {
                                                  this.handleChangeMealNotes(e.target.value)
                                              }}
                                              value={mealSelected.notes || ''}
                                              name="mealNotes"
                                    />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className='text-center'>
                        <Col className='text-center'>
                            <Button color="default" onClick={this.toggleModalNotes}>
                                Cancelar
                            </Button>
                            <Button color="primary" onClick={this.saveMealNotes}>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>

            </Modal>
        );
    }

    deleteMeal = (mealSelected) => {
        apiRequest(`/patient/${this.id}/plan-meal/${this.state.mealPlan.uuid}/meals/${mealSelected.uuid}`, {
            method: 'DELETE'
        }).then(success => {
            this.start();
            this.hideAlertDeleteMeal();
            this.props.notify({type: 'success', message: `Refeição ${mealSelected.name} removida com sucesso.`});
        }).catch(error => {
            this.props.notify({type: 'error', message: `Não foi possível remover a refeição ${mealSelected.name}.`});
        });
    }

    toggleModalSuggestHour = () => {
        this.setState({openModalSuggestHour: !this.state.openModalSuggestHour});
    }

    toggleModalCustomFood = () => {
        this.setState({openModalCustomFood: !this.state.openModalCustomFood});
    }

    toggleModalNotes = () => {
        this.setState({openModalNotes: !this.state.openModalNotes});
    }

    renderButtonAddMeal = (meal) => {
        return (
            <>
                <Col md='4' className='text-right'>
                    <Button color='primary'
                            onClick={() => {
                                this.createMeal(meal);
                            }}>
                        Adicionar Alimentos
                    </Button>
                </Col>
            </>
        );
    }

    renderMealCalculatePercentage = (meal) => {

        if (!meal.allow) {
            return <></>;
        }

        return (
            <>
                <Row className='flex text-center' style={{width: '65%', padding: '10px'}}>
                    <div className='plan-meal color bg-proteina'
                         style={{width: meal.resume_percentage.proteinas + "%"}}>
                        {meal.resume_percentage.proteinas}%
                    </div>
                    <div className='plan-meal color bg-carboidrato'
                         style={{width: meal.resume_percentage.carboidratos + "%"}}>
                        {meal.resume_percentage.carboidratos}%
                    </div>
                    <div className='plan-meal color bg-lipideo' style={{width: meal.resume_percentage.lipideos + "%"}}>
                        {meal.resume_percentage.lipideos}%
                    </div>
                </Row>
            </>
        )

    }

    renderMealCalculate = (meal) => {
        if (meal.allow) {
            return (
                <>
                    <Row className='text-center'>
                        <Col md='2'>Kcal</Col>
                        <Col md='2' className='plan-meal color proteina'>Proteínas (g)</Col>
                        <Col md='2' className='plan-meal color carboidrato'>Carboidratos (g)</Col>
                        <Col md='2' className='plan-meal color libideo'>Lipídeos (g)</Col>
                        {this.renderButtonAddMeal(meal)}
                    </Row>
                    <Row className='text-center' style={{marginTop: '-30px'}}>
                        <Col md='2'>{meal.resume.calorias}</Col>
                        <Col md='2' className='plan-meal color proteina'>{meal.resume.proteinas}</Col>
                        <Col md='2' className='plan-meal color carboidrato'>{meal.resume.carboidratos}</Col>
                        <Col md='2' className='plan-meal color libideo'>{meal.resume.lipideos}</Col>
                        <Col md='4'>&nbsp;</Col>
                    </Row>
                    <Row className='text-center'>
                        <Col md='2'>&nbsp;</Col>
                        <Col md='2' className='plan-meal color proteina'>{meal.resume_percentage.proteinas}%</Col>
                        <Col md='2' className='plan-meal color carboidrato'>{meal.resume_percentage.carboidratos}%</Col>
                        <Col md='2' className='plan-meal color libideo'>{meal.resume_percentage.lipideos}%</Col>
                        <Col md='4'>&nbsp;</Col>
                    </Row>
                </>
            );
        } else {
            return (
                <>
                    <Row>
                        <Col md='8' className='plan-meal no-food text-left'>Você ainda não adicionou
                            alimentos à essa
                            refeição</Col>
                        <Col md='4' className='text-right'>
                            <Button color='primary'
                                    onClick={() => {
                                        this.createMeal(meal);
                                    }}>
                                Adicionar Alimentos
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        }
    }

    renderMealsType = (meal) => {
        return (
            <Row key={meal.key}>
                <Col md='12'>
                    <Card className='plan-meal card'>
                        <CardHeader>
                            <Row>
                                <Col md='6'>
                                    <CardTitle>{meal.name}</CardTitle>
                                </Col>
                                <Col md="6">
                                    <div className='float-right clearfix' style={{textAlign: 'right'}}>
                                        <Row>
                                            <Button color='default'
                                                    disabled={meal.uuid ? false : true}
                                                    className="btn-link"
                                                    onClick={() => {
                                                        this.confirmDeleteMeal(meal);
                                                    }}>
                                                Excluir Refeição
                                            </Button>
                                        </Row>
                                        <Row>
                                            <Button color='default'
                                                    disabled={meal.uuid ? false : true}
                                                    className="btn-link"
                                                    onClick={() => {
                                                        this.setState({mealSelected: meal});
                                                        this.toggleModalNotes();
                                                    }}>
                                                Observações
                                            </Button>
                                        </Row>

                                    </div>
                                </Col>
                            </Row>
                            <p>Horário Sugerido: {meal.suggestHour} &nbsp;&nbsp;&nbsp;
                                <Button className="btn-link"
                                        disabled={!meal.allow}
                                        color="primary" size="sm" onClick={() => {
                                    this.setState({mealSelected: meal});
                                    this.toggleModalSuggestHour();
                                }}>
                                    Alterar
                                </Button>
                            </p>
                        </CardHeader>
                        <CardBody>
                            {meal.allow ? <h5>Cálculo total da refeição</h5> : ''}
                            {this.renderMealCalculate(meal)}

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    renderModalSuggestHour = (meal) => {
        return (
            <Modal isOpen={this.state.openModalSuggestHour} size="md">
                <ModalBody>
                    <h4 className='text-center'>Horário Sugerido {this.state.mealSelected.name}</h4>
                </ModalBody>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <FormGroup>
                                <label className='plan-meal label-custom-food'>Horário Sugerido:</label>
                                <ReactDatetime
                                    onChange={(e) => {
                                        this.handleChangeMealHour(e, '1')
                                    }}
                                    dateFormat={false}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: `${this.state.mealSelected.suggestHour}`
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className='text-center'>
                        <Col className='text-center'>
                            <Button color="default" onClick={this.toggleModalSuggestHour}>
                                Cancelar
                            </Button>
                            <Button color="primary" onClick={this.changeSuggestHour}>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>

            </Modal>
        );
    }

    renderModalCustomFood = () => {
        return (
            <>
                <Modal isOpen={this.state.openModalCustomFood} size="md">
                    <ModalBody>
                        <h4 className='text-center'>Adicionar Refeição</h4>
                    </ModalBody>
                    <ModalBody>
                        <Form>
                            <Col md='12'>
                                <FormGroup>
                                    <label className='plan-meal label-custom-food'>Nome:</label>
                                    <Input type="text" name="customMealName"
                                           value={this.state.customMealName}
                                           onChange={this.handleChange}
                                    />
                                    <label className='plan-meal label-custom-food'>Horário Sugerido:</label>
                                    <ReactDatetime
                                        value={this.state.customMealHour}
                                        onChange={(e) => {
                                            this.handleChangeMealHour(e, '2')
                                        }}
                                        dateFormat={false}
                                        inputProps={{
                                            className: "form-control",
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Row className='text-center'>
                                <Col className='text-center'>
                                    <Button color="default" onClick={this.toggleModalCustomFood}>
                                        Cancelar
                                    </Button>
                                    <Button color="primary" onClick={this.addCustomFood}>
                                        Salvar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }

    makeMealPlanDone = () => {
        let data = {
            status: 'done'
        }
        apiRequest(`/patient/${this.id}/plan-meal/${this.state.mealPlan.uuid}`, {
            method: 'PUT', data: data
        }).then(success => {
            this.props.notify({type: 'success', message: 'O plano alimentar foi concluído com sucesso.'});
            this.start();
        }).catch(err => {
            this.props.notify({type: 'error', message: 'Não foi possível concluir o plano alimentar.'});
        });
        this.hide()
    }

    hide = () => {
        this.setState({confirmAlert: null});
    }

    confirmMealPlanDone = () => {
        this.setState({
            confirmAlert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Confirmação"
                    onConfirm={() => {
                        this.makeMealPlanDone()
                    }}
                    onCancel={() => this.hide()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={'Confimar'}
                    cancelBtnText="Cancelar"
                    showCancel
                >
                    Confirma a conclusão do plano alimentar?<br/>
                    Concluíndo o plano, o paciente será notificado.
                </ReactBSAlert>
            )
        });
    }

    renderDoneButton() {
        if (this.state.mealPlan.meals.length === 0) {

            return (
                <></>
            )
        }
        return (
            <>
                <Button color='primary'
                        onClick={this.confirmMealPlanDone}>
                    <i className="fa fa-check"></i>
                    Concluir
                </Button>
            </>
        )
    }

    renderPlanButton = () => {
        if (this.state.mealPlan.meals.length === 0) {

            return (
                <></>
            )
        }
        return (
            <Button color='primary' outline
                    onClick={this.toogleModalPlan}>
                <i className="fa fa-food"></i>
                Mostrar plano alimentar
            </Button>
        )
    }

    toogleModalPlan = () => {

        this.setState({openModalPlan: !this.state.openModalPlan});
    }
    showMealPlan = () => {
        return (
            <>
                <Modal isOpen={this.state.openModalPlan} size="lg">
                    <ModalBody style={{height: '300px !important', overflow: 'scroll'}}>
                        <div>
                            <PatientPlanMeal patient={this.id}/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.toogleModalPlan}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    renderResume = () => {
        if (this.state.mealPlan.resume) {

            return (
                <>
                    <Row className="text-center">
                        <Col md={2}>Kcal</Col>
                        <Col md={2} className="plan-meal color proteina">Proteínas (g)</Col>
                        <Col md={2} className="plan-meal color carboidrato">Carboidratos (g)</Col>
                        <Col md={2} className="plan-meal color lipideo">Lipídeos (g)</Col>
                    </Row>
                    <Row className="text-center">
                        <Col md={2}>{this.state.mealPlan.resume.calorias}</Col>
                        <Col md={2} className="plan-meal color proteina">{this.state.mealPlan.resume.proteinas}</Col>
                        <Col md={2}
                             className="plan-meal color carboidrato">{this.state.mealPlan.resume.carboidratos}</Col>
                        <Col md={2} className="plan-meal color lipideo">{this.state.mealPlan.resume.lipideos}</Col>
                    </Row>
                    <Row className="text-center">
                        <Col md={2}>&nbsp;</Col>
                        <Col md={2}
                             className="plan-meal color proteina">{this.state.mealPlan.resume.proteinas_percentage} %</Col>
                        <Col md={2}
                             className="plan-meal color carboidrato">{this.state.mealPlan.resume.carboidratos_percentage} %</Col>
                        <Col md={2}
                             className="plan-meal color lipideo">{this.state.mealPlan.resume.lipideos_percentage} %</Col>
                    </Row>
                </>
            );
        }
    }

    render() {
        return (
            <>
                {this.state.confirmAlert}
                {this.renderModalSuggestHour()}
                {this.renderMealNotes()}
                {this.renderModalCustomFood()}
                {this.state.confirmDeleteMeal}
                {this.showMealPlan()}
                <div className="content">
                    <Row style={{position: 'sticky', top: '55px', zIndex: 100}}>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={8} md={8}>
                                            <CardTitle>Plano Alimentar de &nbsp;
                                                <b>{this.state.patient.name}</b></CardTitle>
                                        </Col>
                                        <Col sm={4} md={4}>
                                            <div className="float-right" style={{textAlign: 'right'}}>
                                                {this.renderDoneButton()}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={8} md={8}>
                                            <HeaderProntuario
                                                link={`/admin/patient/${this.id}/details`}
                                                buttonText="Voltar para o perfil do paciente."
                                            />
                                        </Col>
                                        <Col sm={4} md={4}>
                                            <div className="float-right" style={{textAlign: 'right'}}>
                                                {this.renderPlanButton()}
                                            </div>
                                        </Col>
                                    </Row>

                                    <h5>Refeições</h5>
                                    <h6>Resumo Nutricional</h6>
                                    {this.renderResume()}
                                    <br/>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>

                    {this.state.meals.map((meal) => {
                        if (meal.type.substr(0, 7) !== 'custom_') {
                            return this.renderMealsType(meal);
                        }
                    })}
                    <hr/>
                    <h5>Refeições Adicionadas</h5>
                    {this.state.meals.map((meal) => {
                        if (meal.type.substr(0, 7) === 'custom_') {
                            return this.renderMealsType(meal);
                        }
                    })}


                    <Button className='plan-meal add-custom-meal ' color='primary'
                            onClick={this.toggleModalCustomFood}>
                        <i className='nc-icon nc-simple-add'>&nbsp;&nbsp;</i>
                        Adicionar Refeição
                    </Button>

                </div>
            </>
        );
    }
}

export default withNotify(PlanoAlimentarIndex);
