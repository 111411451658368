import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  FormText,
  Input,
  Row,
  Col
} from "reactstrap";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Services from "models/Services";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";

const PARAM_NAMES = {
  mailto: 'E-mail para',
  link: 'Link externo'
};

class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      price: '',
      description1: '',
      description2: '',
      description3: '',
      payment: '',
      next_step: '',
      installments: '',
      is_b2c: false,
      image: '',
      originalImage: '',
      working: false,
      services: [],
      productServices: [],
      enabledServices: [],
      loadingServices: false,
      errors: {}
    }
  }

  componentDidMount() {
    if (this.id) {
      this.fetchItem();
    }
    this.loadServices()
      .then(() => {
        if (this.id)
          this.loadProductServices(this.id);
      });
  }

  fetchItem() {
    return apiRequest("/products/" + this.id, {method: "GET"})
        .then(response => {
          let {data} = response.data;
          data.originalImage = data.image;
          this.setState(data);
          return data;
        });
  }

  get id() {
    if (this.props.match.params) {
      return this.props.match.params.id;
    }
    return null;
  }

  get formTitle() {
    if (this.id) {
      return 'Alteração de produtos';
    }
    return 'Cadastro de produtos';
  }

  clearForm() {
    this.setState({
      name: '',
      price: '',
      description1: '',
      description2: '',
      description3: '',
      payment: '',
      next_step: '',
      installments: '',
      is_b2c: false,
      image: '',
      originalImage: '',
      enabledServices: []
    });
  }

  loadServices() {
    return apiRequest("/services", {method: 'GET'})
      .then(response => this.setState({services: response.data.data}));
  }
  
  loadProductServices(productId) {
    let data = {
      product_id: productId
    };
    return apiRequest("/product-services", {data, method: 'GET'})
      .then((response) => {
        let {data} = response.data;
        let serviceIds = data.map(ps => ps.service.id);
        this.setState({enabledServices: serviceIds, productServices: data});
      });
  }

  hasService(serviceId) {
    return this.state.enabledServices.indexOf(serviceId) >= 0;
  }

  findProductService(serviceId) {
    return this.state.productServices.find(productService => productService.service.id == serviceId);
  }

  handleChange = (e) => {
    let {name, value} = e.target;

    if (name == 'is_b2c') {
      value = !this.state.is_b2c;
    }
    
    this.setState({
      [name]: value
    });

    if (this.state.errors[name]) {
      this.setState({errors: {...this.state.errors, [name]: ''}});
    }
  }

  handleServiceCheck = event => {
    let serviceId = parseInt(event.target.value);
    let {enabledServices} = this.state;
    
    let foundIdx = enabledServices.indexOf(serviceId);
    if (foundIdx === -1) {
      enabledServices.push(serviceId);
    }
    else {
      enabledServices.splice(foundIdx, 1);
    }

    if (!this.findProductService(serviceId)) {
      let service = this.state.services.find(s => s.id == serviceId);
      let productServices = [...this.state.productServices];
      let newProductService = {service, params: {}};
      newProductService.params = {...newProductService.params};
      productServices.push(newProductService);
      this.setState({productServices});
    }

    this.setState({enabledServices: [...enabledServices]});
  }

  handleServiceParamChange = (productService, e) => {
    let {name, value} = e.target;

    if (name == 'quota' || name == 'name' || name == 'description') {
      productService[name] = value;
    }
    else {
      productService.params[name] = value;
    }

    // clone and update
    let productServices = [...this.state.productServices];
    let idx = productServices.findIndex(ps => ps.id == productService.id);
    if (idx === -1) {
      productServices.push(productService);
    }
    else {
      productServices[idx] = productService;
    }

    this.setState({productServices});
  }

  handleChangeImage = file => {
    this.setState({image: file});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let fields = ['name', 'price', 'description1', 'description2', 'description3',
      'next_step', 'payment', 'installments', 'is_b2c'];
    
    let data = new FormData()
    for (const field of fields) {
      let value = this.state[field];
      if (field == 'is_b2c') {
        value = value ? 1 : 0;
      }
      else if (!value) {
        continue;
      }
      data.append(field, value);
    }

    if (this.state.originalImage != this.state.image) {
      data.append('image', this.state.image);
    }

    let productServices = this.state.productServices.map(ps => {
      let {service: {id: serviceId}, quota, name, description, params} = ps;
      return {serviceId, name, description, quota, params};
    });
    productServices = productServices.filter(item => this.state.enabledServices.indexOf(item.serviceId) >= 0);
    data.append('productServices', JSON.stringify(productServices));
    
    this.setState({working: true});
    
    let request;
    let isEdit = !!this.id;
    if (this.id) {
      request = apiRequest("/products/" + this.id, {data, method: 'PUT'})
    }
    else {
      request = apiRequest("/products", {data, method: 'POST'});
    }
    
    request.then(response => {
        if (!isEdit)
          this.clearForm();
        this.props.notify({type: 'success', message: 'Produto armazenado com sucesso'});
      })
      .catch(err => {
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }
  
  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);
    
    return (
      <>
        <div className="content product-form-container">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{this.formTitle}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">Nome</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.name}>
                          <Input type="text" name="name" value={this.state.name}
                            onChange={this.handleChange}
                          />
                          <InputError name="name" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Tipo de produto</Label>
                      <Col sm="10">
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" checked={this.state.is_b2c}
                              onChange={this.handleChange} name='is_b2c'
                            />
                            <span className="form-check-sign" />
                            É um produto B2C
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Descrição 1</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.description1}>
                          <Input type="textarea" name="description1" value={this.state.description1 || ''}
                            onChange={this.handleChange}
                          />
                          <InputError name="description1" errors={this.state.errors} />
                          <FormText color="default" tag="span">
                            Texto abaixo do título do produto
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Label sm="2">Descrição 2</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.description2}>
                          <Input type="textarea" name="description2" value={this.state.description2 || ''}
                            onChange={this.handleChange}
                          />
                          <InputError name="description2" errors={this.state.errors} />
                          <FormText color="default" tag="span">
                            Bullet points - para cada nova linha, um novo bullet point será gerado.
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Descrição 3</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.description3}>
                          <Input type="textarea" name="description3" value={this.state.description3 || ''}
                            onChange={this.handleChange}
                          />
                          <InputError name="description3" errors={this.state.errors} />
                          <FormText color="default" tag="span">
                            Texto de <em>Opinião de quem já comprou</em>
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Preço da parcela</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.price}>
                          <Input type="number" name="price" step="0.01" value={this.state.price}
                            onChange={this.handleChange}
                          />
                          <InputError name="price" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Número de parcelas</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.installments}>
                          <Input type="number" name="installments" step="1" value={this.state.installments || ''}
                            onChange={this.handleChange}
                          />
                          <InputError name="installments" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Condições de pagamento</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.payment}>
                          <Input type="textarea" name="payment" value={this.state.payment || ''}
                            onChange={this.handleChange}
                          />
                          <InputError name="payment" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Próximo passo</Label>
                      <Col sm="10">
                        <FormGroup className={hasError.next_step}>
                          <Input type="textarea" name="next_step" value={this.state.next_step || ''}
                            onChange={this.handleChange}
                          />
                          <InputError name="next_step" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>

                    {this.renderServices()}

                    <Row>
                      <Label sm="2">Imagem</Label>
                      <Col md="4" sm="4">
                        <ImageUpload onChangeImage={this.handleChangeImage} 
                          originalUrl={this.state.originalImage}
                        />
                      </Col>
                    </Row>

                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  renderServices() {
    return (
      <Row>
        <Label sm="2">
          Serviços habilitados
        </Label>
        <Col className="checkbox-radios" sm="10">
          {this.state.loadingServices && (
            <span>Carregando serviços...</span>
          )}
          {!this.state.loadingServices && this.state.services.map(service => (
            <FormGroup check key={"prod-service-" + service.id}>
              <Label check>
                <Input type="checkbox" checked={this.hasService(service.id)}
                  onChange={this.handleServiceCheck} value={service.id}
                />
                <span className="form-check-sign" />
                {service.displayName}
              </Label>

              {this.hasService(service.id) && this.renderServiceParams(service)}
            </FormGroup>
          ))}
        </Col>
      </Row>
    );
  }

  renderServiceParams(service) {
    let productService = this.findProductService(service.id);
    if (!productService) {
      console.warn('Product service for service id was not found', service.id);
      // should not happen...
      return;  
    }

    let {name, description, params} = productService;

    return (
      <div className="service-params-container">

        {service.name == Services.consts.SPECIALIST_CALL && this.renderSpecialistCallParams(productService)}

        {service.name != Services.consts.SPECIALIST_CALL && this.renderOtherParams(productService)}
        
        <Row>
          <Label sm="3">Título do serviço</Label>
          <Col sm="9">
            <FormGroup>
              <Input type="text" name="name" value={name || ''}
                onChange={e => this.handleServiceParamChange(productService, e)}
              />
              <FormText color="default" tag="span">
                Se este campo for vazio, será mostrado o título do produto.
              </FormText>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Label sm="3">Descrição do serviço</Label>
          <Col sm="9">
            <FormGroup>
              <Input type="textarea" name="description" value={description || ''}
                onChange={e => this.handleServiceParamChange(productService, e)}
              />
              <FormText color="default" tag="span">
                Se este campo for vazio, será mostrada a descrição do produto.
              </FormText>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  renderSpecialistCallParams(productService) {
    let {quota = '', params = {}} = productService || {};
    let {duration = ''} = params || {};

    return (
      <React.Fragment>
        <Row>
          <Label sm="3">Número de sessões</Label>
          <Col sm="4">
            <FormGroup>
              <Input type="number" name="quota" value={quota}
                onChange={e => this.handleServiceParamChange(productService, e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="3">Duração da sessão</Label>
          <Col sm="4">
            <FormGroup>
              <Input type="number" name="duration" value={duration}
                onChange={e => this.handleServiceParamChange(productService, e)}
              />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderOtherParams(productService) {
    if (!productService.params) {
      return '';
    }

    return Object.entries(productService.params).map(([paramKey, paramValue]) => (
      <React.Fragment>
        <Row>
          <Label sm="3">{PARAM_NAMES[paramKey] || paramKey}</Label>
          <Col sm="4">
            <FormGroup>
              <Input type="text" name={paramKey} value={paramValue}
                onChange={e => this.handleServiceParamChange(productService, e)}
              />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    ));
  }
}

export default withNotify(ProductForm);
