import React from "react";

// reactstrap components
import moment from "moment";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Row
} from "reactstrap";
import InputError from "../../../components/InputError";
import apiRequest from "../../../../lib/Api";
import withNotify from "../../../../lib/NotificationWrapper";
import {Link} from "react-router-dom";
import Select from "react-select";
import {getUserData} from "../../../../lib/Auth";
import UserFormData from "../../../../services/UserFormData";
import HeaderProntuario from "../../../../components/Header/HeaderProntuario";

class Antropometria extends React.Component {
    constructor(props) {
        super(props);
        let userData = getUserData();
        let readOnly = false;
        let classInputs = '';
        if (userData.role === 'patient') {
            readOnly = true;
            classInputs = 'form-exames-readonly';
        }
        this.state = {
            user: {name: 'Iuri', date_start: moment(), gender:''},
            working: false,
            idade: 0,
            model: {
                medidas: {
                    peso_atual: 0,
                    altura: 0,
                    circunferencia_da_cintura: 0
                },
                atividade_fisica: {
                    fator_atividade_fisica: '',
                },
                dobras_cutaneas_masculinas: {
                    coxa_masculino: 0,
                    abdome: 0,
                    peitoral_masculino: 0
                },
                dobras_cutaneas_femininas: {

                    coxa_feminino: 0,
                    triceps_feminino: 0,
                    supra_iliaca: 0,
                },
                status: 'draft'
            },
            classInputs : classInputs,
            readOnly: readOnly
        }
        this.fatorAtividadeFisica = [
            {label: 'Sedentário', value: 'sedentario'},
            {label: 'Leve', value: 'leve'},
            {label: 'Moderado', value: 'moderado'},
            {label: 'Intenso', value: 'intenso'},
        ]
    }

    handleChange = (group, field, value) => {
        let data = this.state.model;
        data[group][field] = value
        this.setState({model: data});
    }

    get id() {

        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    async componentWillMount() {
        if (this.id) {
            await this.getUserData();
        }
    }

    getUserData = async () => {
        const response = await apiRequest(`/patient/${this.id}`, {method: "GET"})
        const user = response.data.data;
        let dateBegin = moment(user.created_at);
        let userState = {
            name: user.name,
            date_start: dateBegin,
            gender: user.gender
        }
        this.setState({user: userState} );
        /**
         * return
         */
        try {
            const responseExam = await apiRequest(`/patient/${this.id}/forms/antropometria`, {method: "GET"})
            let {data} = responseExam.data;
            let subgroups = data.data.groups[0].subgroups;
            let model = {status: data.status};
            for (const subgroup of subgroups) {
                for (const field of subgroup.fields) {
                    if (!model[subgroup.name]) {
                        model[subgroup.name] = {};
                    }
                    let fieldValue = field.value;
                    if (field.name === 'fator_atividade_fisica') {
                        fieldValue = this.fatorAtividadeFisica.find(element => element.value === field.value);
                    }
                    model[subgroup.name][field.name] = fieldValue;
                }
            }
            this.setState({'model': model});

        } catch (error) {
            await apiRequest(`/patient/${this.id}/forms/anamnese`, {method: "GET"}).then(response =>{
                let {data} = response.data;
                let model = this.state.model;
                let userForm = new UserFormData(data);
                let anamneseModel = userForm.toModel();
                model.medidas.peso_atual = anamneseModel.historico.peso.peso_atual;
                this.setState({model: model});

            }).catch(err => {

            })
        }

    }

    saveExam = async () => {

        let dados = {...this.state.model};
        delete dados.status;
        let group = {
            name: 'antropometria',
            subgroups: []
        }
        for (let subgroupName in dados) {
            let subgroupFormmated = {
                name: subgroupName,
                fields: []
            }
            const subgroup = dados[subgroupName];

            for (const field in subgroup) {
                let fieldValue = subgroup[field];
                if (field === 'fator_atividade_fisica') {
                    fieldValue = subgroup[field].value;
                }
                let fieldData = {
                    name: field,
                    value: fieldValue
                }
                subgroupFormmated.fields.push(fieldData);
            }
            group.subgroups.push(subgroupFormmated);
        }
        let dataToPost = {
            data: {
                data: {groups: [group]},
                status: 'done',
            },
            method: "POST"
        }
        try {
            const response = await apiRequest(`/patient/${this.id}/forms/antropometria`, dataToPost)
            this.props.notify({type: 'success', message: 'Antropometria registrada com sucesso'});
        } catch (e) {
            this.props.notify({type: 'error', message: e.data.message});
        }

    }

    renderSelectAtividade() {
        if (this.state.readOnly) {
            return  (
                <span>{this.state.model.atividade_fisica.fator_atividade_fisica.value}</span>
            )
        }

        return (

            <Select
                className={"react-select primary "+this.state.classInputs}
                classNamePrefix="react-select"
                name="state"
                value={this.state.model.atividade_fisica.fator_atividade_fisica}
                onChange={value =>
                    this.handleChange('atividade_fisica', 'fator_atividade_fisica', value)
                }
                options={[
                    {
                        value: "",
                        label: "Selecione um fator",
                        isDisabled: true
                    },
                    ...this.fatorAtividadeFisica
                ]}
                placeholder="Selecione"
                isDisabled={this.state.readOnly}
            />
        )
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        let link = `/admin/patient/${this.id}/details`;
        let textoLink = 'Voltar para o perfil do paciente.';
        if (this.state.readOnly) {
            link = `/admin/dashboard`;
            textoLink = 'Voltar para o painel.';
        }
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <HeaderProntuario
                                        title={`Preenchimento de Antropometria de ${this.state.user.name}`}
                                        link={link}
                                        buttonText={textoLink}
                                    />
                                </CardHeader>
                                <CardBody>

                                    <Row style={{paddingBottom: "25px"}}>
                                        <Col md='12'>
                                            <p><b>Medidas</b></p>
                                            <Row>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Peso Atual:</label>
                                                        <InputGroup>

                                                            <Input type="number" step="0.1"
                                                                   name="peso_atual"
                                                                   value={this.state.model.medidas.peso_atual}
                                                                   onChange={(event) =>
                                                                       this.handleChange('medidas', 'peso_atual', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText className={this.state.classInputs}>Kg</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Altura:</label>
                                                        <InputGroup>

                                                            <Input type="number" step="1" name="altura"
                                                                   value={this.state.model.medidas.altura}
                                                                   onChange={(event) =>
                                                                       this.handleChange('medidas', 'altura', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText className={this.state.classInputs}>Cm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>

                                                    </FormGroup>
                                                </Col>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Circunferência da cintura:</label>
                                                        <InputGroup>

                                                            <Input type="number" step="1"
                                                                   name="circunferencia_da_cintura"
                                                                   value={this.state.model.medidas.circunferencia_da_cintura}
                                                                   onChange={(event) =>
                                                                       this.handleChange('medidas', 'circunferencia_da_cintura', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText className={this.state.classInputs}>Cm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: "25px"}}>
                                        <Col md='12'>
                                            <p><b>Fator atividade física</b></p>
                                            <Row>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <Label>Fator atividade física</Label>
                                                        {this.renderSelectAtividade()}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row style={{paddingBottom: "25px", display: this.state.user.gender.toLocaleLowerCase() === 'masculino' ? '': 'none'}}>

                                        <Col md='12'>
                                            <p><b>Dobras cutâneas masculino</b></p>
                                            <Row>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Coxa:</label>
                                                        <InputGroup>
                                                            <Input type="number" step="1"
                                                                   name="coxa_masculino"
                                                                   value={this.state.model.dobras_cutaneas_masculinas.coxa_masculino}
                                                                   onChange={(event) =>
                                                                       this.handleChange('dobras_cutaneas_masculinas', 'coxa_masculino', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText  className={this.state.classInputs}>mm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>

                                                    </FormGroup>

                                                </Col>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Peitoral:</label>
                                                        <InputGroup>

                                                            <Input type="number" step="1" name="peitoral_masculino"
                                                                   value={this.state.model.dobras_cutaneas_masculinas.peitoral_masculino}
                                                                   onChange={(event) =>
                                                                       this.handleChange('dobras_cutaneas_masculinas', 'peitoral_masculino', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText  className={this.state.classInputs}>mm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>

                                                    </FormGroup>
                                                </Col>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Abdôme:</label>
                                                        <InputGroup>

                                                            <Input type="number" step="1" name="abdome"
                                                                   value={this.state.model.dobras_cutaneas_masculinas.abdome}
                                                                   onChange={(event) =>
                                                                       this.handleChange('dobras_cutaneas_masculinas', 'abdome', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText  className={this.state.classInputs}>mm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: "25px", display: this.state.user.gender.toLocaleLowerCase() === 'feminino' ? '': 'none'}}>

                                        <Col md='12'>
                                            <p><b>Dobras cutâneas feminino</b></p>
                                            <Row>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Coxa:</label>
                                                        <InputGroup>
                                                            <Input type="number" step="1"
                                                                   name="coxa_feminino"
                                                                   value={this.state.model.dobras_cutaneas_femininas.coxa_feminino}
                                                                   onChange={(event) =>
                                                                       this.handleChange('dobras_cutaneas_femininas', 'coxa_feminino', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText  className={this.state.classInputs}>mm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>

                                                    </FormGroup>

                                                </Col>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Tríceps:</label>
                                                        <InputGroup>

                                                            <Input type="number" step="1" name="triceps_feminino"
                                                                   value={this.state.model.dobras_cutaneas_femininas.triceps_feminino}
                                                                   onChange={(event) =>
                                                                       this.handleChange('dobras_cutaneas_femininas', 'triceps_feminino', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText className={this.state.classInputs}>mm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>

                                                    </FormGroup>
                                                </Col>
                                                <Col md='2'>
                                                    <FormGroup>
                                                        <label>Supra ilíca:</label>
                                                        <InputGroup>

                                                            <Input type="number" step="1" name="supra_iliaca"
                                                                   value={this.state.model.dobras_cutaneas_femininas.supra_iliaca}
                                                                   onChange={(event) =>
                                                                       this.handleChange('dobras_cutaneas_femininas', 'supra_iliaca', event.target.value)
                                                                   }
                                                                   className={this.state.classInputs}
                                                                   disabled={this.state.readOnly}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText  className={this.state.classInputs}>mm</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='10'>
                                            <Col sm='2'>
                                                <Button color="white"
                                                        style={{display: this.state.readOnly ? 'none': ''}}
                                                        className="white-button" tag={Link}
                                                        to={`/admin/patient/${this.id}/details`}
                                                        disabled={this.state.working}>
                                                    Cancelar
                                                </Button>
                                            </Col>
                                        </Col>
                                        <Col sm='2'>
                                            <Button color="primary" style={{display: this.state.readOnly ? 'none': ''}}
                                                    onClick={this.saveExam} disabled={this.state.working}>
                                                Concluir
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(Antropometria);
