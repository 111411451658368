import React from "react";
import {Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Label, Row} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import Select from "react-select";
import InputError from "../../components/InputError";
import FileUpload from "../../../components/CustomUpload/FileUpload";
import apiRequest from "../../../lib/Api";

class ImportFoods extends React.Component {

    constructor(props) {
        super(props);
        this.types = [
            {
                value: "",
                label: "selecione",
                isDisabled: true,
                help: '',
                accept: 'application/json'
            },
            {
                value: "ibge",
                label: "IBGE",
                help: 'Arquivo de importacao da tabela do IBGE (jun/20210). Deve ser um arquivo ZIP, contendo 4 tabelas em xls.',
                accept: 'application/zip'
            },
        ];
        this.state = {
            working: false,
            errors: {},
            message: '',
            name: '',
            model: {
                table: {...this.types[0]},
                file: '',
            }
        }

    }

    handleChangeImage = file => {
        let model = this.state.model;
        model.file = file;
        this.setState({
            model: model
        });
    }
    handleSelect = (option) => {
        let model = this.state.model;
        model.table = option;
        this.setState({model: model});

        if (this.state.errors.table) {
            this.setState({errors: {...this.state.errors, table: ''}});
        }
    }

    sendFile = () => {

        if (this.state.model.table.value === '' ) {
            let errors = {
                table: 'A tabela deve ser informada.'
            }
            this.props.notify({type:"error", message: "informe a tabela."});
            this.setState({errors: errors})
            return;
        }
        if (!this.state.model.file) {
            this.props.notify({type:"error", message: "Faça o upload do arquivo."});
            return;
        }
        if (!this.state.model.table.value === '' ) {
            this.props.notify({type:"error", message: "Faça o upload do arquivo."});
            return;
        }
        let data = {...this.state.model};
        data.table = data.table.value;
        let formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
        apiRequest(`/foods/import-table`, {data: formData, method: 'POST'}).then(response => {
            let message = 'Arquivo enviado com sucesso. Ao terminar o processamento você recebera um email';
            this.props.notify({type: 'success', message: message});
            let model = {
                table:this.types[0],
                file: ''
            }
            this.refs.fileInput.clear();
            this.setState({model: model});
        }).catch(error => {
            this.props.notify({type: 'error', message: 'Não foi possível enviar o arquivo.'});
        });
    }
    helpUpload() {
        if (this.state.model.table.value === '') {
            return (<></>);
        }
        return (
            <>
                <Alert color="info" style={{zIndex:'90 !important'}}>
                    {this.state.model.table.help}
                </Alert>
            </>
        )
    }
    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Importar Tabela de Alimentos
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Label sm="2">Tabela</Label>
                                        <Col sm="10">
                                            <FormGroup className={hasError.table}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="type"
                                                    value={this.state.model.table}
                                                    onChange={this.handleSelect}
                                                    options={this.types}
                                                    placeholder="Informe a tabela"
                                                />
                                                <InputError name="table" errors={this.state.errors}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Arquivo</Label>
                                        <Col md="10" sm="10">
                                            <FileUpload onChangeImage={this.handleChangeImage}
                                                        accept={this.state.model.table.accept}
                                                        originalUrl=''
                                                        ref='fileInput'
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={2}>{''}</Col>
                                        <Col>
                                            {this.helpUpload()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} style={{textAlign: 'right'}}>
                                            <Button color='primary' onClick={this.sendFile}>
                                                <i className="fa fa-save"/>
                                                Salvar</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
export default withNotify(ImportFoods);
