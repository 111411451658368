import React from "react";
import {Button, Card, CardBody, Modal} from "reactstrap";
import ChallengeFront from "./ChallengeFront";

class ChallengeBack extends React.Component {
    constructor(props) {
        super(props);

        this.showModal = false;
        this.state = {
            cardFront: null
        }
    }


    renderModal() {

        if (!this.state.cardFront) {
            return;
        }
        const close = () => {
            this.setState({isOpen: !this.state.isOpen});
        }

        return (
            <>
                <Modal isOpen={this.state.isOpen} toggle={close} className={'challenge size-modal'}>
                    <ChallengeFront
                        logo={this.state.cardFront.logo || null}
                        title={this.state.cardFront.title || null}
                        description={this.state.cardFront.description || null}
                    />
                    <div className={'text-center'}>
                        <Button onClick={close}>Fechar</Button>
                    </div>
                </Modal>
            </>
        );

    }

    selectCard(card) {
        this.setState({cardFront: card, isOpen: true});
    }

    render() {

        let colorButton = "primary";
        let disabled = this.props.isAvailable === false;

        return (
            <>
                {this.renderModal()}
                <Card>
                    <br />
                    <div className={'text-center'}>
                        <img src={this.props.logo} alt="Nutrideck"/>
                    </div>
                    <CardBody className={'text-center'}>
                        <div className={'challenge nutrideck-card-title'}>
                            {this.props.title}
                        </div>
                        <div style={{minHeight:'40px'}}>
                            <p className={'text-center'}>
                                {this.props.description}
                            </p>
                        </div>
                        <Button color={colorButton}
                                disabled={disabled}
                                onClick={() => this.selectCard(this.props.cardFront)}>
                            {this.props.titleButton}
                        </Button>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default ChallengeBack;
