import queryString from 'query-string';
import ApiError from './ApiError';
import {getAuthToken, logout, redirectLogin} from './Auth';

const apiRoot = process.env.REACT_APP_API_HOST + '/api';

const defaultRequestParams = {
  method: 'POST',
  // expose cookie to authenticate with rocket.chat
  credentials: 'include'
};

const defaultHeaders = {
  'Accept': 'application/json',
};

function apiRequest(endpoint, params, {blob = false} = {}) {
  let url = apiRoot + endpoint;

  params = {...defaultRequestParams, ...params};
  params.headers = {...defaultHeaders, ...params.headers};
  let loader = params.loader === undefined ? true : params.loader;

  const accessToken = getAuthToken();
  if (accessToken) {
    params.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  let {method, data = {}} = params;
  if (data instanceof FormData) {
    if (method == 'PUT') {
      // hack to make Laravel parse multipart/form-data
      data.append('_method', 'PUT');
      params.method = 'POST';
    }
    params.body = data;
  }
  else if (Object.keys(data).length) {
    if (method === 'GET') {
      url = url + '?' + queryString.stringify(data);
    }
    else {
      params.headers['Content-Type'] = 'application/json';
      params.body = JSON.stringify(data);
    }

    delete params.data;
  }

  let savedResponse;
  let element = document.getElementById('Teste');
  if (element && loader) {
     element.style.display="";
  }
  return fetch(url, params)
    .then(response => {
      savedResponse = response;
      if (response.status === 401 && !url.match(/login/) && !url.match(/chat/)) {
        if (element) {
          element.style.display="none";
        }
        logout();
        redirectLogin();
      }
      if (response.status === 403) {
        logout();
        redirectLogin(true);
      }
      if (response.status === 204) {
        if (element && loader) {
          element.style.display="none";
        }
        return {};
      }
      if (blob) {
        if (element && loader) {
          element.style.display="none";
        }
        return response.blob();
      }
      if (element) {
        element.style.display="none";
      }
      return response.json();
    })
    .then(data => {
      if (blob) {
        if (element && loader) {
          element.style.display="none";
        }
        return data;
      }
      const {status, statusText} = savedResponse;
      if (savedResponse.status >= 200 && savedResponse.status < 300) {
        if (element && loader) {
          element.style.display="none";
        }
          return {data, status, statusText};
      }
      if (element && loader) {
        element.style.display="none";
      }
      throw new ApiError(status, data, statusText);
    })
}

export default apiRequest;

export {apiRequest, apiRoot};
