import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Label, Nav, NavItem, NavLink,
    Row, TabContent,
    Table, TabPane,
    UncontrolledTooltip
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import StandardsCalendar from "../standards/StandardsCalendar";
import InputError from "../../components/InputError";
import ReactDatetime from "react-datetime";
import withNotify from "../../../lib/NotificationWrapper";
import moment from "moment";

const PeriodicityOptions = {
    yearly: 'Anual',
    monthly: 'Mensal',
    single: 'Único'
};

class ExceptionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isDisponibily: true,
            horizontalTabs: 'disponibilidade',
            isForm: false,
            model: {
                date: '',
                time_start: '',
                time_end: ''
            },
            errors:{}
        };
    }

    fetchItem() {
        return apiRequest("/schedule-exceptions/" + this.id, {method: "GET"})
            .then(response => {
                let exception = response.data.data;
                this.setState({model: exception});
            });
    }

    componentDidMount() {
        this.requestData();
    }

    clearForm() {
        let model = {
            date: '',
            time_start: '',
            time_end: '',
        }
        this.setState({
            model: model,
            errors: {}
        });
    }

    get periodicityOptions() {
        return [
            {value: "yearly", label: "Anual"},
            {value: "monthly", label: "Mensal"},
            {value: "single", label: "Único"}
        ];
    }

    getExceptions = () =>  {
        apiRequest("/schedule-exceptions", {method: "GET"})
            .then(response => {
                this.setState({items: response.data.data});
            });
    }
    requestData() {
        this.getExceptions();
    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    maybeDelete = (id) => {
        this.setState({deleteId: id});
        this.toggleConfirmation(true);
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }

        let model = this.state.model;
        model[name] =  value;
        this.setState({
            model: model
        });

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let {date, time_start, time_end, type} = this.state.model;
        let data = {
            date: moment.isMoment(date) ? date.format('DD/MM/YYYY') : date,
            time_start: moment.isMoment(time_start) ? time_start.format('HH:mm') : time_start,
            time_end: moment.isMoment(time_end) ? time_end.format('HH:mm') : time_end,
            type: 'single'
            // type: type ? type.value : null
        };

        this.setState({working: true});

        let request;
        if (this.id) {
            request = apiRequest("/schedule-exceptions/" + this.id, {data, method: 'PUT'})
        }
        else {
            request = apiRequest("/schedule-exceptions", {data, method: 'POST'});
        }

        request.then(response => {
            this.clearForm();
            this.getExceptions();
            this.props.notify({type: 'success', message: 'Exceção armazenada com sucesso'});
        })
            .catch(err => {
                console.error(err);
                this.setState({errors: err.data.errors});
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    delete = () => {
        const id = this.state.deleteId;
        apiRequest("/schedule-exceptions/" + this.state.deleteId, {method: "DELETE"})
            .then(() => {
                this.props.notify({type: 'success', message: 'Exceção removida com sucesso'});

                let items = this.state.items.filter(item => item.id !== id);
                this.setState({items});
            });
    }

    getTypeDisplay(type) {
        return PeriodicityOptions[type] || '';
    }

    changeTab(tab) {
        this.setState({
            tabSelected: tab,
            isDisponibily: tab === 'disponibility' || false,
            isList: tab === 'list' || false,
        })
    }

    renderDisponibility() {
        return (
            <>
                <StandardsCalendar/>
            </>
        )
    }

    renderException() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        let {items} = this.state;
        return (
            <>
                <Row>
                    <Col md="12">
                        <Form action="" className="form" method="POST"
                              onSubmit={this.handleSubmit}
                        >
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Nova Exceção</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Label sm="2">Data da exceção</Label>
                                        <Col sm="4">
                                            <FormGroup className={hasError.date}>
                                                <ReactDatetime
                                                    inputProps={{
                                                        className: "form-control",
                                                        name: "date",
                                                        placeholder: "Selecione uma data",
                                                        autoComplete: 'off'
                                                    }}
                                                    timeFormat={false}
                                                    dateFormat={true}
                                                    locale="pt-br"
                                                    value={this.state.model.date}
                                                    onChange={value =>
                                                        this.handleChange("date", value)
                                                    }
                                                />
                                                <InputError name="date" errors={this.state.errors}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Hora de início</Label>
                                        <Col sm="4">
                                            <FormGroup className={hasError.time_start}>
                                                <ReactDatetime
                                                    inputProps={{
                                                        className: "form-control",
                                                        name: "time_start",
                                                        placeholder: "Selecione uma hora",
                                                        autoComplete: 'off'
                                                    }}
                                                    timeFormat={true}
                                                    dateFormat={false}
                                                    locale="pt-br"
                                                    value={this.state.model.time_start}
                                                    onChange={value =>
                                                        this.handleChange("time_start", value)
                                                    }
                                                />
                                                <InputError name="time_start" errors={this.state.errors}/>
                                            </FormGroup>
                                        </Col>

                                        <Label sm="2">Hora de término</Label>
                                        <Col sm="4">
                                            <FormGroup className={hasError.time_end}>
                                                <ReactDatetime
                                                    inputProps={{
                                                        className: "form-control",
                                                        name: "time_end",
                                                        placeholder: "Selecione uma hora",
                                                        autoComplete: 'off'
                                                    }}
                                                    timeFormat={true}
                                                    dateFormat={false}
                                                    locale="pt-br"
                                                    value={this.state.model.time_end}
                                                    onChange={value =>
                                                        this.handleChange("time_end", value)
                                                    }
                                                />
                                                <InputError name="time_end" errors={this.state.errors}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md="2"/>
                                        <Col md="10">
                                            <Button className="btn-round" color="info" type="submit"
                                                    disabled={this.state.working}
                                            >
                                                Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Table responsive>
                        <thead className="text-primary">
                        <tr>
                            <th className="text-center">#</th>
                            <th>Data</th>
                            <th>Hora início</th>
                            <th>Hora fim</th>
                            <th className="text-right">Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map(item => {

                            return (
                                <>
                                    <tr key={item.id}>
                                        <td className="text-center">{item.id}</td>
                                        <td>{item.date}</td>
                                        <td>{item.time_start}</td>
                                        <td>{item.time_end}</td>

                                        <td className="text-right">
                                            <Button
                                                tag={Link}
                                                className="btn-icon btn-neutral"
                                                color="success"
                                                id="tooltip264453216"
                                                size="sm"
                                                to={`/admin/schedule-exceptions/${item.id}/edit`}
                                            >
                                                <i className="fa fa-edit"/>
                                            </Button>{" "}
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip264453216"
                                            >
                                                Editar
                                            </UncontrolledTooltip>

                                            <Button
                                                className="btn-icon btn-neutral"
                                                color="danger"
                                                id="tooltip476609793"
                                                size="sm"
                                                type="button"
                                                onClick={() => this.maybeDelete(item.id)}
                                            >
                                                <i className="fa fa-times"/>
                                            </Button>{" "}
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip476609793"
                                            >
                                                Remover
                                            </UncontrolledTooltip>
                                        </td>
                                    </tr>
                                </>
                            )
                        })
                        }
                        </tbody>
                    </Table>
                </Row>
            </>
        )
    }

    render() {

        let hasError = InputError.convertToHasClass(this.state.errors);
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Alteração de Disponibilidade</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="nav-tabs-navigation">
                                        <div className="nav-tabs-wrapper">
                                            <Nav id="tabs" role="tablist" tabs>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "disponibilidade"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "disponibilidade"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            this.setState({horizontalTabs: "disponibilidade"})
                                                        }
                                                    >
                                                        <b>Disponibilidade Padrão</b>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "excessao"}
                                                        data-toggle="tab"
                                                        href="#"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "excessao"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            this.setState({horizontalTabs: "excessao"})
                                                        }
                                                    >
                                                        <b>Exceções de horário</b>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </div>
                                    <TabContent
                                        className="text-center"
                                        id="my-tab-content"
                                        style={{backgroundColor: '#efefef'}}
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="disponibilidade" role="tabpanel"
                                                 style={{backgroundColor: '#efefef', padding: "20px"}}>
                                            <br />
                                            <Alert color="info">
                                            <span>
                                                O calendário abaixo representa um dia na semana. Ao configurar o dia selecionado ele
                                                automaticamente irá replicar a informação para os demais dias seguintes. Exemplo,
                                                ao configurar Segunda-Feira, ele irá replicar o horário informado para todas as
                                                Segundas-Feiras, independente do mês.
                                            </span>
                                            </Alert>
                                            <br />
                                            {this.renderDisponibility()}
                                        </TabPane>
                                    </TabContent>
                                    <TabContent
                                        className="text-center"
                                        id="my-tab-content"
                                        style={{backgroundColor: '#efefef'}}
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="excessao" role="tabpanel"
                                                 style={{backgroundColor: '#efefef', padding: "20px"}}>
                                            {this.renderException()}
                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>

                <ConfirmModal message="Tem certeza que deseja remover esta exceção?"
                              onConfirm={() => this.delete()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />
            </>
        );
    }
}

export default withNotify(ExceptionList);
