import React from "react";
import moment from 'moment';
import {Link} from 'react-router-dom';

// reactstrap components
import {
  Progress,
  Table,
  Row,
  Col,
} from "reactstrap";

import apiRequest from "lib/Api";
import defaultAvatar from "assets/img/default-avatar.png";

class OrganizationUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }
  
  componentDidMount() {
    let id = this.props.organizationId || 'mine';
    apiRequest(`/organizations/${id}/usage`, {method: "GET"})
      .then(response => {
        this.setState({users: response.data})
      });
  }
  describeSituation(situation) {
    if (!situation.last && !situation.next) {
      return 'Não agendado ainda';
    }
    else if (situation.last && !situation.next) {
      return this.describeAppointment(situation.last);
    }
    else if (!situation.last && situation.next) {
      return this.describeAppointment(situation.next);
    }
    else {
      const {last, next} = situation;
      if (last.situation == 'canceled') {
        if (next.situation == 'pending' || next.situation == 'approved') {
          return 'Re-agendou para ' + this.formatDate(next.datetime_start);
        }
        else {
          return this.describeAppointment(next);
        }
      }
      else {
        return this.describeAppointment(next);
      }
      // TODO if multiple appointments are allowed per user, we might
      // have a second column, named 'First appointment: Yes/No'
    }
  }

  describeAppointment(appointment) {
    switch(appointment.situation) {
      case 'pending':
      case 'approved':
          return 'Próximo: ' + this.formatDate(appointment.datetime_start);
      case 'started':
          return 'Iniciou em ' + this.formatDate(appointment.datetime_start);
      case 'done':
          return 'Último: ' + this.formatDate(appointment.datetime_start);
      case 'canceled':
          return 'Cancelou o horário ' + this.formatDate(appointment.datetime_start);
    }
  }

  formatDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }
  
  render() {
    return (
      <div className='user-overview-table'>
        <Table responsive>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Atividade</th>
              <th>Progresso</th>
              <th>Agendamento</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.map(user => (
              <tr key={'dashboard-pu-' + user.id}>
                <td>
                  <Row>
                    <Col className="user-avatar" sm="auto">
                      <img
                        src={user.user.pictureUrl || defaultAvatar}
                      />
                    </Col>
                    <Col className="user-name" sm="auto">
                      {user.user.name}
                    </Col>
                  </Row>
                </td>
                <td>
                  {this.renderServices(user.services)}
                </td>
                <td>
                  {this.renderProgress(user.services)}
                </td>
                <td style={{maxWidth: '150px'}}>
                  {user.appointments 
                    ? this.describeSituation(user.appointments)
                    : 'Sem agendamento' }
                </td>
              </tr>
            ))}

          </tbody>
        </Table>
      </div>
    );
  }

  renderServices(services) {
    return (
      <React.Fragment>
        {services.map(service =>
          <p className="program-name">{service.name}</p>
        )}
      </React.Fragment>
    )
  }

  renderProgress(services) {
    return (
      <React.Fragment>
        {services.map(service =>
          <div className="program-progress">
            <Progress
              max={service.quota}
              value={service.usage}
              barClassName="progress-bar-info"
            />
            <span>
              ({service.usage + '/' + service.quota})
            </span>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default OrganizationUsage;