import React from "react";
import {Card, CardHeader, CardTitle, Col, Row} from "reactstrap";
import ChallengeBack from "../../../components/Cards/ChallengeBack";

import logoFront01 from "../../../assets/img/challenge_cards/front_01.png";
import logoFront02 from "../../../assets/img/challenge_cards/front_02.png";
import logoFront03 from "../../../assets/img/challenge_cards/front_03.png";
import logoFront04 from "../../../assets/img/challenge_cards/front_04.png";
import logoFront05 from "../../../assets/img/challenge_cards/front_05.png";
import logoFront06 from "../../../assets/img/challenge_cards/front_06.png";

import logoBack01 from "../../../assets/img/challenge_cards/back_01.png";
import logoBack02 from "../../../assets/img/challenge_cards/back_02.png";
import logoBack03 from "../../../assets/img/challenge_cards/back_03.png";
import logoBack04 from "../../../assets/img/challenge_cards/back_04.png";
import logoBack05 from "../../../assets/img/challenge_cards/back_05.png";
import logoBack06 from "../../../assets/img/challenge_cards/back_06.png";

import logoNoLogo from "../../../assets/img/challenge_cards/no_logo.png";
import moment from "moment";

class Challenge extends React.Component {

    constructor(props) {
        super(props);
    }

    isAvailable(xp) {
        const dateNow = moment.now();
        let isAvailable = false;
        switch (xp) {
            case 1:
                let date1 = new moment('2021-11-15 00:00:00');
                isAvailable = moment(dateNow).isSameOrAfter(date1);
                break;
            case 2:
                // 29.11
                let date2 = new moment('2021-11-29 00:00:00');
                isAvailable = moment(dateNow).isSameOrAfter(date2);
                break;
            case 3:
                // 13.12
                const date3 = new moment('2021-12-13 00:00:00');
                isAvailable = moment(dateNow).isSameOrAfter(date3);
                break;
            case 4:
                // 27.12
                const date4 = new moment('2021-12-27 00:00:00');
                isAvailable = moment(dateNow).isSameOrAfter(date4);

                break;
            case 5:
                // 10.01
                const date5 = new moment('2022-01-10 00:00:00');
                isAvailable = moment(dateNow).isSameOrAfter(date5);
                break;
            case 6:
                // 24.01
                const date6 = new moment('2022-01-24 00:00:00');
                isAvailable = moment(dateNow).isSameOrAfter(date6);
                break;
        }
        return isAvailable;
    }
    getLogoFront(xp) {
        switch(xp) {
            case 1:
                return logoFront01;
            case 2:
                return logoFront02;
            case 3:
                return logoFront03;
            case 4:
                return logoFront04;
            case 5:
                return logoFront05;
            case 6:
                return logoFront06;
        }
    }

    getLogoBack(xp) {
        switch(xp) {
            case 1:
                return logoBack01;
            case 2:
                return logoBack02;
            case 3:
                return logoBack03;
            case 4:
                return logoBack04;
            case 5:
                return logoBack05;
            case 6:
                return logoBack06;
        }
    }

    get cardsFront()  {
        let cards = [];
        for (let xp = 1; xp <= 6; xp++) {
            cards.push(
                {
                    logo: this.getLogoFront(xp),
                    isAvailable: this.isAvailable(xp),
                    cardNumber: xp,
                    dateStart: '2021-01-01',
                    dateEnd: '2021-01-01',
                    titleButton: `Experiência ${xp}`,
                    title: "Rituais que transformam a relação com os alimentos",
                    description: "Clique, acesse e compartilhe!",
                }
            );
        }
        return cards;
    }

    get cardsBack()  {
        return [
            {
                logo: this.getLogoBack(1),
                cardNumber: 1,
                title: "Escolha bem os seus alimentos",
                description: "Monte sua lista de compras pensando nas frutas, legumes e verduras. Busque a feira ou hortifruti mais perto da sua casa. Prefira os alimentos da estação. Dê preferência aos orgânicos e divirta-se escolhendo os melhores ítens. Compartilhe sua experiência!",
            },
            {
                logo: this.getLogoBack(2),
                cardNumber: 2,
                title: "Despertar delicioso",
                description: "Nada mais gostoso do que acordar e curtir esse despertar. Escolha um dia mais tranquilo e prepare um café da manhã especial. Frutas, iogurte, um pão especial são ótimas sugestões. Coloque sua playlist favorita e curta muito esse momento. Compartilhe sua experiência!",
            },
            {
                logo: this.getLogoBack(3),
                cardNumber: 3,
                title: "Amor na cozinha",
                description: "Comer bem também é comer com liberdade. Fazer um bolo pode ser uma ótima forma de reunir a família e desfrutar dessa relação tão deliciosa. Compartilhe com toda família e amigos os resultados da sua receita! Compartilhe sua experiência!",
            },
            {
                logo: this.getLogoBack(4),
                cardNumber: 4,
                title: "Prove novos sabores",
                description: "Já parou pra pensar na diversidade das comidas. Comidas típicas, regionais, texturas, sabores, ingredientes! Bora se aventurar em algo novo? Peça no delivery e chame os amigos pra compartilhar. Conte pra gente dessa aventura!",
            },
            {
                logo: this.getLogoBack(5),
                cardNumber: 5,
                title: "Cozinha prática",
                description: "Prove que você é capaz de cozinhar coisas gostosas e saudáveis com rapidez e praticidade. As receitas de forno são uma ótima pedida. Você ainda ganha um tempo para você enquanto sua refeição está assando. Compartilhe essa experiência!",
            },
            {
                logo: this.getLogoBack(6),
                cardNumber: 6,
                title: "Comida em família",
                description: "Refeição em família é sempre uma delícia. Pode ser almoço, um lanche da tarde ou mesmo um jantar gostoso. Pensa numa receita que todos adoram, prepare com carinho e compartilhe esse momento tão especial com quem você mais ama!",
            }
        ];
    }

    render() {

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Desafios Nutrideck</CardTitle>
                                    <br/>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {this.cardsFront.map(card => {
                            return (
                                <Col md={3} key={card.cardNumber}>
                                    <ChallengeBack
                                        logo={card.logo}
                                        title={card.title}
                                        description={card.description}
                                        isAvailable={card.isAvailable}
                                        titleButton={card.titleButton}
                                        cardNumber={card.cardNumber}
                                        cardFront={this.cardsBack.find(cardBack => cardBack.cardNumber === card.cardNumber)}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </>
        );
    }
}

export default Challenge;
