import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {Link} from "react-router-dom";
import PaginationCtrl from "../../../components/Pagination/PaginationCtrl";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import apiRequest from "../../../lib/Api";
import Select from "react-select";
import InputError from "../../components/InputError";

class EmailTemplateList extends React.Component {
    constructor(props) {
        super(props);
        this.types = [
            {
                value: 0,
                label: 'Todos os tipos'
            },
            {
                value: 1,
                label: 'Transacionais'
            },
            {
                value: 2,
                label: 'Agendados'
            },
        ]
        this.state = {
            emails: [],
            filter: '',
            type: this.types[0],
            deleteEmailId: null
        }

    }

    filterChange = (event) => {
        let value = event.target.value;
        this.setState({filter: value});
    }

    componentDidMount() {
        this.requestData();
    }

    requestData = page => {
        let data = {
            page: page || 1
        };

        if (this.state.filter || this.state.type.value !== 0) {
            data.search = `subject:${this.state.filter}`;
            if (this.state.type.value !== 0) {
                data.search += `;type:${this.state.type.value}`;
            }
        }

        apiRequest("/email-templates", {method: "GET", data})
            .then(response => {
                let {data, meta} = response.data;
                this.setState({emails: data, meta});
            });
    }
    executeFilter = (event) => {
        event.preventDefault();
        this.requestData();
    }


    maybeDeleteEmail = (id) => {
        this.setState({deleteEmailId: id});
        this.toggleConfirmation(true);
    }

    handleSelect = (name, value) => {
        this.setState({[name]: value});
    }

    deleteEmail = () => {
        const id = this.state.deleteEmailId;
        apiRequest("/email-templates/" + this.state.deleteEmailId, {method: "DELETE"})
            .then(() => {
                this.props.notify({type: 'success', message: 'Template de email removido com sucesso'});

                let emails = this.state.emails.filter(email => email.id !== id);
                this.setState({emails});
            })
            .catch(() => {
                this.props.notify({type: 'error', message: 'Não foi possível remover o email.'});
            });
    }

    toggleConfirmation = (show) =>  {
        this.setState({showDeleteConfirmation: show});
    }

    render() {
        let {emails} = this.state;

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Templates Cadastrados</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal">
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Input placeholder="Informe o nome do email para pesquisa" type="text"
                                                           onChange={this.filterChange} value={this.state.filter}
                                                           onKeyPress={event => event.which === 13 && event.preventDefault()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        isDisabled={this.state.disabled}
                                                        value={this.state.type}
                                                        onChange={(evt) => {
                                                            this.handleSelect('type', evt);
                                                        }}
                                                        options={this.types}
                                                        placeholder="Selecione um tipo"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <Button color="info" type="button" style={{margin: '1px -15px'}}
                                                        onClick={this.executeFilter}
                                                ><i className="fa fa-search"/>
                                                </Button>
                                            </Col>
                                            <Col md="3" style={{textAlign:'right'}}>
                                                <Button color="primary outline"
                                                        tag={Link}
                                                        to={'/admin/email-templates/new'}
                                                ><i className="fas fa-plus"/>{' '}
                                                    Novo
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table >
                                        <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">#</th>
                                            {/*<th>Ação que dispara</th>*/}
                                            <th>Assunto</th>
                                            <th>Categoria</th>
                                            <th>Tipo</th>
                                            {/*<th>Produto</th>*/}
                                            <th className="text-right">Ações</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {emails.map(email => {

                                            return <tr key={email.id}>
                                                <td className="text-center">{email.id}</td>
                                                <td>{email.description}</td>
                                                {/*<td>{email.subject}</td>*/}
                                                <td>{email.category}</td>
                                                <td>{email.type === 1 ? 'Transacional' : 'Agendado'}</td>
                                                {/*<td>{email.productService ? email.productService.name : ''}</td>*/}

                                                <td className="text-right">
                                                    <Button
                                                        tag={Link}
                                                        className="btn-icon btn-neutral"
                                                        color="success"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        to={`/admin/email-templates/edit/${email.id}`}
                                                    >
                                                        <i className="fa fa-edit"/>
                                                    </Button>{" "}

                                                    <Button
                                                        className="btn-icon btn-neutral"
                                                        color="danger"
                                                        id="tooltip476609793"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => this.maybeDeleteEmail(email.id)}
                                                    >
                                                        <i className="fa fa-times"/>
                                                    </Button>{" "}
                                                </td>
                                            </tr>;

                                        })}

                                        </tbody>
                                    </Table>
                                    <PaginationCtrl responseMeta={this.state.meta}
                                                    requestPage={this.requestData}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <ConfirmModal message="Tem certeza que deseja remover este template de email?"
                              onConfirm={() => this.deleteEmail()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />
            </>
        );
    }
}

export default withNotify(EmailTemplateList)
