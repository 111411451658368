import React from "react";
import classnames from "classnames";
import {Link, NavLink} from "react-router-dom";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";

import logo from "assets/img/logo.svg";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "bg-white"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container>
          <div className="navbar-wrapper login-nutrideck" style={{width:"100%"}}>
          <Collapse
              isOpen={this.state.collapseOpen}
              className=""
              navbar
          >


              <a href="https://nutrideck.com.br/quem-somos" className="nav-link" >
                <span >Quem somos</span>
              </a>

            <a href="https://nutrideck.com.br/nutricionistas" className="nav-link" >
              <span >Nutricionistas</span>
            </a>
            <a href="https://nutrideck.com.br/blog" className="nav-link" >
              <span >Blog</span>
            </a>

          </Collapse>

            <NavbarBrand href="https://nutrideck.com.br/">
              <img src="https://www.nutrideck.com.br/wp-content/uploads/2021/03/logo_principal.png" alt="nutrideck-logo"/>
            </NavbarBrand>



          <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            <Link to={"/auth/login" + window.location.search} className="nav-link" >
              <span>Entrar</span>
            </Link>

            <a href="https://nutrideck.com.br/nutricionistas" className="nav-link button">
              <span className={"white"}>Iniciar o Programa</span>
            </a>

          </Collapse>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
