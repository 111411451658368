import React from "react";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from 'moment';
import ptBr from 'moment/locale/pt-br';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";

class ExceptionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      time_start: '',
      time_end: '',
      // type: '',
      working: false,
      errors: {}
    }
  }

  componentDidMount() {
    if (this.id) {
      this.fetchItem();
    }
  }

  fetchItem() {
    return apiRequest("/schedule-exceptions/" + this.id, {method: "GET"})
      .then(response => {
        let exception = response.data.data;
        // exception.type = this.periodicityOptions.find(option => option.value == exception.type);
        this.setState(exception);
      });
  }

  get id() {
    if (this.props.match.params) {
      return this.props.match.params.id;
    }
    return null;
  }

  get formTitle() {
    if (this.id) {
      return 'Alteração de exceções';
    }
    return 'Cadastro de exceções';
  }

  get periodicityOptions() {
    return [
      { value: "yearly", label: "Anual" },
      { value: "monthly", label: "Mensal" },
      { value: "single", label: "Único" }
    ];
  }

  clearForm() {
    this.setState({
      date: '',
      time_start: '',
      time_end: '',
      // type: '',
      errors: {}
    });
  }

  handleChange = (name, value) => {
    if (typeof name == 'object' && typeof name.target == 'object') {
      let event = name;
      event.preventDefault();
      name = event.target.name;
      value = event.target.value;
    }

    this.setState({
      [name]: value
    });

    if (this.state.errors[name]) {
      this.setState({errors: {...this.state.errors, [name]: ''}});
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let {date, time_start, time_end, type} = this.state;
    let data = {
      date: moment.isMoment(date) ? date.format('DD/MM/YYYY') : date, 
      time_start: moment.isMoment(time_start) ? time_start.format('HH:mm') : time_start,
      time_end: moment.isMoment(time_end) ? time_end.format('HH:mm') : time_end,
      type: 'single'
      // type: type ? type.value : null
    };

    this.setState({working: true});
    
    let request;
    if (this.id) {
      request = apiRequest("/schedule-exceptions/" + this.id, {data, method: 'PUT'})
    }
    else {
      request = apiRequest("/schedule-exceptions", {data, method: 'POST'});
    }
    
    request.then(response => {
        this.clearForm();
        this.props.notify({type: 'success', message: 'Exceção armazenada com sucesso'});
      })
      .catch(err => {
        console.error(err);
        this.setState({errors: err.data.errors});
      })
      .finally(
        response => this.setState({working: false})
      );
  }
  
  render() {
    let hasError = InputError.convertToHasClass(this.state.errors);
    
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Form action="" className="form" method="POST"
                onSubmit={this.handleSubmit}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{this.formTitle}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Label sm="2">Data da exceção</Label>
                      <Col sm="4">
                        <FormGroup className={hasError.date}>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              name: "date",
                              placeholder: "Selecione uma data",
                              autoComplete: 'off'
                            }}
                            timeFormat={false}
                            dateFormat={true}
                            locale="pt-br"
                            value={this.state.date}
                            onChange={value =>
                              this.handleChange("date", value)
                            }
                          />
                          <InputError name="date" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Hora de início</Label>
                      <Col sm="4">
                        <FormGroup className={hasError.time_start}>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              name: "time_start",
                              placeholder: "Selecione uma hora",
                              autoComplete: 'off'
                            }}
                            timeFormat={true}
                            dateFormat={false}
                            locale="pt-br"
                            value={this.state.time_start}
                            onChange={value =>
                              this.handleChange("time_start", value)
                            }
                          />
                          <InputError name="time_start" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                      
                      <Label sm="2">Hora de término</Label>
                      <Col sm="4">
                        <FormGroup className={hasError.time_end}>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              name: "time_end",
                              placeholder: "Selecione uma hora",
                              autoComplete: 'off'
                            }}
                            timeFormat={true}
                            dateFormat={false}
                            locale="pt-br"
                            value={this.state.time_end}
                            onChange={value =>
                              this.handleChange("time_end", value)
                            }
                          />
                          <InputError name="time_end" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    {/*
                    <Row>
                      <Label sm="2">Periodicidade</Label>
                      <Col sm="4">
                        <FormGroup className={hasError.type}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="type"
                            value={this.state.type}
                            onChange={value =>
                              this.handleChange("type", value)
                            }
                            options={[
                              {
                                value: "",
                                label: "Selecione uma periodicidade",
                                isDisabled: true
                              },
                              ...this.periodicityOptions
                            ]}
                            placeholder="Selecione uma periodicidade"
                          />
                          <InputError name="type" errors={this.state.errors} />
                        </FormGroup>
                      </Col>
                    </Row>
                    */ }

                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="2" />
                      <Col md="10">
                        <Button className="btn-round" color="info" type="submit"
                          disabled={this.state.working}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(ExceptionForm);
