import React from "react";

// reactstrap components
import {Button, Col, Row} from "reactstrap";

import {apiRequest, apiRoot} from 'lib/Api';
import {getAuthToken, getUserData} from 'lib/Auth';
import {Input} from "react-bootstrap-typeahead";
import * as moment from "moment";
import PerfectScrollbar from "perfect-scrollbar";

const PING_INTERVAL = 30000;  // 1 min
// for testing
const testMode = false;
let myUsername = null;
let ps;
const rocketChatMessages = new Set([
    'notification',
    'unread-changed-by-subscription',
    'unread-changed',
    'room-opened',
    'new-message',
    'click-user-card-message',
    'click-mention-link',
    'click-message-link',
    'click-action-link'
]);

class BotDropChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direct: '',
            username: '',
            message: '',
            messages: [],
            intervalId: null,
            isGuest: false,
            token: '',
            chatId: '',
            lastId: '',
            currentUser: getUserData(),
            chatSessionId: null,
            videoChat: false
        };

    }

    get id() {
        if (this.props.user_id) {
            return this.props.user_id;
        }
        if (this.props.match.params) {
            return this.props.match.params.id;
        }
        return null;
    }

    get chatId() {
        return this.state.chatId
    }


    async componentDidMount() {
        if (!this.id) {
            return;
        }

        let intervalId = setInterval(this.getMessages, 1500);
        this.setState({intervalId: intervalId});
        ps = new PerfectScrollbar(this.refs.sidebar, {
            suppressScrollX: true,
            suppressScrollY: false
        });
        await this.openChatSession('app123');
        await this.getMessages();
        window.addEventListener("unload", e => {

            // when page's unload, use sendBeacon to make sure that
            // a request will be send to backend
            const url = apiRoot + `/chat-sessions/close?token=` + getAuthToken() + `&chat_id=${this.chatId}`;
            const success = navigator.sendBeacon(url);
        })
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({[name]: value});
    }

    openChatSession = (roomId) => {
        if (this.state.isGuest) {
            return;
        }
        let params = {roomId, appointment_id: this.id};
        return apiRequest('/chat-sessions/open', {method: "POST", data: params, loader: false})
            .then(response => {
                let {data} = response.data;
                this.setState({chatId: data.id, chatSessionId: data.chat_id});
                setInterval(this.ping, PING_INTERVAL);
            });
    }

    maybeNotifyMessage = (message) => {
        let data = {message, chat_id: this.state.chatId};
        return apiRequest(`/chat-sessions/new-message`, {method: "POST", data});
    }

    ping = () => {
        if (this.state.isGuest) {
            return;
        }
        let data = {chat_id: this.state.chatId};
        return apiRequest(`/chat-sessions/ping`, {method: "POST", data, loader: false});
    }

    send = async () => {
        let data = {
            message: this.state.message,
            chat_id: this.state.chatId,
        }
        await apiRequest(`/chat/messages`, {method: "POST", data, loader: false});
        this.setState({message: ''});
    }
    getMessages = async () => {
        let data = {
            chat_id: this.state.chatSessionId,
        }
        await apiRequest(`/chat/messages`, {method: "GET", data, loader: false}).then((response) => {
            this.setState({messages: response.data.data});
        });
    }

    renderMessages = () => {
        let messages = this.state.messages;

        if (messages.length > 0) {
            const lastMessage = 'msg' + messages[messages.length - 1].uuid;
            this.state.lastId = lastMessage;
        }

        return (
            messages.map(message => {
                    let className = "msg right-msg";
                    if (message.user_id === this.state.currentUser.id) {
                        className = "msg left-msg";
                    }

                    let lastId = 'msg' + message.uuid;
                    let hour = moment(message.created_at).format('HH:mm')
                    return (
                        <div className={className} key={message.uuid} id={lastId}>
                            <div
                                className="msg-img"
                            ></div>

                            <div className="msg-bubble">
                                <div className="msg-info">
                                    <div className="msg-info-name">{message.name}</div>
                                    <div className="msg-info-time">{hour}</div>
                                </div>

                                <div className="msg-text">
                                    {message.message}
                                </div>
                            </div>
                        </div>
                    )
                }
            )
        )
    }

    async componentWillUnmount() {
        // use intervalId from the state to clear the interval
        await apiRequest(`/chat-sessions/close?token=` + getAuthToken() + `&chat_id=${this.chatId}`);
        clearInterval(this.state.intervalId);
        clearTimeout(this.tm)
        ps.destroy();
    }

    renderVideo() {
        if (!this.state.videoChat) {
            return (
                <></>
            )
        }
        let link = 'https://meet.jit.si/appointment_chat_' + this.id;
        return (
            <Col md="6">

                <iframe src={`${link}`} width="100%" height="90%"
                        allow="microphone, camera"
                        allowFullScreen
                />

            </Col>
        )
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Row>
                                <div id={'chatbox'} className="chatbox" ref="sidebar">
                                    <div className="msger-chat">
                                        {this.renderMessages()}
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="msger-inputarea">
                                        <Input type='text' name='message' className='msger-input'
                                               onKeyPress={event => {
                                                   if (event.which === 13) {
                                                       event.preventDefault();
                                                       this.send(event);
                                                     }
                                                  }
                                               }
                                               onChange={this.handleChange} value={this.state.message}
                                        />
                                        <Button type="button" className="msger-send-btn"
                                                onClick={this.send}>Enviar</Button>

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {this.renderVideo()}
                    </Row>
                </div>
            </>
        )
    }

}

// to test, with no rocket chat in use

export default BotDropChat;
