import React from "react";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import {calendarMessages} from "../../../lib/Locale";


// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Form,
  FormGroup
} from "reactstrap";
import {Prompt} from "react-router-dom";

const localizer = BigCalendar.momentLocalizer(moment);

const DEFAULT_DURATION = 60;

class StandardsCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: DEFAULT_DURATION,
      currentDuration: DEFAULT_DURATION,
      selectedDate: new Date(),
      blocks: [],
      haveDataUnsaved: false
    };
  }

  componentDidMount() {
    this.requestData();
  }

  selectBlock = block => {
    let {blocks} = this.state;
    let pos = blocks.indexOf(block);
    if (pos === -1) {
      return;
    }
    blocks.splice(pos, 1);
    this.setState({blocks: [...blocks]});
  };

  addNewBlock = (slotInfo) => {
    let newBlocks = this.state.blocks;
    newBlocks.push({
      title: 'Disponível',
      start: slotInfo.start,
      end: slotInfo.end,
      weekday: moment(slotInfo.start).format('E')  // ISO day of week
    });
    this.setState({
      blocks: newBlocks,
      haveDataUnsaved: true
    });
  };

  eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };

  changeDuration = e => {
    let {duration} = this.state;
    if (isNaN(parseInt(duration))) {
      return;
    }

    this.setState({currentDuration: Number(duration)});
  }

  requestData() {
    return apiRequest('/schedule-standards', {method: 'GET'})
      .then(response => {
        let selectedDate = moment(this.state.selectedDate);
        // transform blocks to frontend format
        let blocks = response.data.data.map(block => {
          // build dates
          let start = moment(selectedDate.format('YYYY-MM-DD') + ' ' + block.time_start);
          let end = moment(selectedDate.format('YYYY-MM-DD') + ' ' + block.time_end);
          // change weekday
          let {weekday} = block;
          start = start.isoWeekday(weekday);
          end = end.isoWeekday(weekday);
          return {start: start.toDate(), end: end.toDate(), weekday, title: 'Disponível'};
        });

        this.setState({blocks, selectedDate});
      });
  }

  onSave = e => {
    // transform blocks to backend format
    let blocks = this.state.blocks.map(block => {
      let start = moment(block.start).format('HH:mm:ss');
      let end = moment(block.end).format('HH:mm:ss');
      let {weekday} = block;
      return {weekday, time_start: start, time_end: end};
    });
    let data = {data: blocks};

    this.setState({working: true});

    apiRequest("/schedule-standards", {data, method: 'PUT'}).then(response => {
        let [firstError,] = response.data.errors;
        if (firstError == 'cannot_remove') {
          let message = 'Os seguintes horários estão agendados, e precisam ser cancelados antes: ' + response.data.data.join(', ');
          this.props.notify({type: 'warning', message});
        }
        else {
          this.props.notify({type: 'success', message: 'Disponibilidade armazenada com sucesso'});
          this.setState({haveDataUnsaved: false})
        }
      })
      .catch((err, data) => {
        this.props.notify({type: 'error', message: 'Não foi possível salvar a disponibilidade. Tente mais tarde.'});
      })
      .finally(
        response => this.setState({working: false})
      );
  }

  onNavigate = date => {
    this.transformDates(date);
    this.setState({selectedDate: date});
  }

  // for all dates that has same week day as selected date,
  // we will change their date to fit into current week
  transformDates(selectedDate) {
    selectedDate = moment(selectedDate);
    const selectedWeekday = selectedDate.format('d');

    let newBlocks = [];
    for(let block of this.state.blocks) {
      let {weekday, start, end} = block;
      if (weekday == selectedWeekday &&
          selectedDate.format('DDMMYYYY') != moment(start).format('DDMMYYYY')) {
        start = moment(start)
          .date(selectedDate.date())
          .month(selectedDate.month())
          .year(selectedDate.year());
        end = moment(end)
          .date(selectedDate.date())
          .month(selectedDate.month())
          .year(selectedDate.year());

        block.start = start.toDate();
        block.end = end.toDate();
      }
      newBlocks.push(block);
    }

    this.setState({blocks: newBlocks});
  }

  render() {
    let formats = {
      dayHeaderFormat: 'dddd'
    };

    return (
      <>
        <Prompt
            when={this.state.haveDataUnsaved}
            message="Você tem dados não salvos. Deseja continuar?"
        />
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto mb-2" md="10">
              <Row>
                <Form inline={true} className="form-calendar-header">
                  <FormGroup>
                    <Label className="p-3">Duração do atendimento</Label>
                    <InputGroup style={{width: '200px'}} className="m-0">
                      <Input value={this.state.duration}
                        onChange={e => this.setState({duration: e.target.value})}
                      />
                      <InputGroupAddon addonType="append"
                        onClick={this.changeDuration}
                        style={{cursor: 'pointer'}}
                      >
                        &nbsp; Aplicar
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup >
                    <Button color="primary m-3"
                      onClick={this.onSave}
                            style={{position:"sticky", top:"10px"}}
                    >
                      <span className="btn-label">
                        <i className="nc-icon nc-cloud-upload-94" />
                      </span>
                      Salvar
                    </Button>
                  </FormGroup>
                </Form>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card className="card-calendar">
                <CardBody>
                  <BigCalendar
                    selectable
                    className="standards-calendar"
                    localizer={localizer}
                    events={this.state.blocks}
                    defaultView="day"
                    step={this.state.currentDuration}
                    timeslots={1}
                    formats={formats}
                    messages={calendarMessages}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={event => this.selectBlock(event)}
                    onSelectSlot={slotInfo => this.addNewBlock(slotInfo)}
                    eventPropGetter={this.eventColors}

                    onNavigate={this.onNavigate}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(StandardsCalendar);
