import React from "react";
import {Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import apiRequest from "../../../../lib/Api";
import InputError from "../../../components/InputError";
import FileUpload from "../../../../components/CustomUpload/FileUpload";
import * as moment from "moment";
import {Link} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";

class ExamIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            modal: false,
            confirmDelete: null,
            model: {
                filename: '',
                FileContent: '',
            },
            errors: {}
        }
    }

    componentDidMount() {

        this.getFiles();
    }

    closeModal = () => {
        this.setState({modal: false});
    }

    openModal = () => {
        this.setState({modal: true});
    }
    getFiles = async () => {

        await apiRequest(`/users/${this.props.patient_id}/files`, {
            method: 'GET'
        }).then(success => {
            let {data} = success.data;
            this.setState({files: data});
        });
    }

    sendFile = () => {
        if (!this.state.model.FileContent) {
            this.props.notify({type:"error", message: "faça o upload do arquivo."});
            return;
        }
        let data = this.state.model;
        data.patient_id = this.props.patient_id;
        let formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
        apiRequest(`/fm/upload/exame`, {data: formData, method: 'POST'}).then(response => {
            this.closeModal();
            this.getFiles();
            this.props.notify({type: 'success', message: 'Arquivo enviado com sucesso.'});
        }).catch(error => {
            this.props.notify({type: 'error', message: 'Não foi possível enviar o arquivo.'});

        });
    }
    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }

        let model = this.state.model;
        model[name] = value;
        this.setState({model: model});

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleChangeImage = file => {
        let model = this.state.model;
        model.FileContent = file;
        this.setState({
            model: model
        });
    }

    downloadFile = (file) => {

        const type = file.type;
        const fileName = file.file;
        apiRequest(`/fm/download/file/${this.props.patient_id}/${type}/${fileName}`, {method: "GET"}, {blob: true})
            .then(response => {
                var file = window.URL.createObjectURL(response);
                var a = document.createElement('a');
                a.href = file;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    hideAlertDeleteFile = () => {
        this.setState({confirmDelete: null});
    };

    confirmDeleteFile = (file) => {
        this.setState({
            confirmDelete: (
                <ReactBSAlert
                    warning
                    title={`Deseja excluir o arquivo ${file.name}?`}
                    style={{display: "block", marginTop: "-200px"}}
                    onConfirm={() => this.deleteFile(file)}
                    onCancel={() => this.hideAlertDeleteFile()}
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Confirmar"
                    cancelBtnText="Cancelar"
                    showCancel
                >
                </ReactBSAlert>
            )
        });
    }
    deleteFile = (file) => {
        apiRequest(`/users/${this.props.patient_id}/files/${file.uuid}`, {method: "DELETE"}).then(response => {
            this.props.notify({type:'success', message: `Arquivo ${file.name} foi removido com sucesso.`});
            this.getFiles();
            this.setState({confirmDelete:null});
        }).catch(error => {
            this.props.notify({type:'error', message: `Não foi possível remover o arquivo ${file.name}.`});
            this.setState({confirmDelete:null});
        })


    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        let files = this.state.files;

        return (
            <>
                {this.state.confirmDelete}
                <Row>
                    <Col md={12}>
                        <div style={{textAlign: 'right'}}>
                            <Button color="primary" onClick={this.openModal}>
                                <i className="fa fa-plus"/>
                                Adicionar
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table hover>
                            <thead>
                            <tr>
                                <th>
                                    Nome
                                </th>
                                <th>
                                    Enviado Por
                                </th>
                                <th>
                                    Data do Envio
                                </th>
                                <th>
                                    Arquivo
                                </th>
                                <th>
                                    Excluir
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {files.map(file => {
                                return (
                                    <tr key={file.uuid}>
                                        <td className="text-left">
                                            {file.name}
                                        </td>
                                        <td>
                                            {file.sender.name}
                                        </td>
                                        <td>
                                            {moment(file.created_at).format('DD/MM/YYYY')}
                                        </td>
                                        <td>
                                            <Link color="link" to={'#'}
                                                  onClick={(event) => {
                                                      event.preventDefault();
                                                      this.downloadFile(file)
                                                  }}
                                            >Visualizar</Link>
                                        </td>
                                        <td>
                                            <Button color="link" style={{fontSize: '20px'}}
                                                    onClick={(event) =>{
                                                        this.confirmDeleteFile(file);
                                                    }}
                                            ><i className="fa fa-trash"/></Button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Modal isOpen={this.state.modal} size="md">
                    <ModalHeader style={{position: 'sticky', textAlign: 'left'}}>
                        Adicionar exames / arquivos
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup className={hasError.name}>
                                    <label>Nome</label>
                                    <Input value={this.state.model.filename}
                                           size="10"
                                           name="filename"
                                           onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                            <Col md="10" sm="10">
                                <FileUpload onChangeImage={this.handleChangeImage}
                                            originalUrl=''
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='error' onClick={this.closeModal}>Fechar</Button>
                        <Button color='primary' onClick={this.sendFile}>Salvar</Button>
                        &nbsp;
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export default withNotify(ExamIndex);
