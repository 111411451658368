import React from "react";
import {Redirect, Link} from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import {getUserData, shouldCompleteProfile, checkToken} from "../../../lib/Auth";
import withNotify from "../../../lib/NotificationWrapper";
import {toCurrency} from "../../../lib/Formatter";

import Wizard from "models/Wizard";

class ProductCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      redirect: false,
      working: false
    }
  }

  componentDidMount() {
    this.fetchItem()
      .then(() => {
        let redirect = Wizard.beforeCheckout();
        if (redirect !== true) {
          this.setState({redirect});
        }
      });
  }

  fetchItem() {
    return apiRequest("/products/" + this.productId, {method: "GET"})
        .then(response => this.setState({product: response.data.data}));
  }

  get productId() {
    if (this.props.match.params) {
      return this.props.match.params.id;
    }
    return null;
  }

  confirmPurchase = () => {
    this.setState({working: true});

    let {product} = this.state;
    let data = {
      product_id: product.id,
      price_paid: product.price
    };

    apiRequest("/purchases", {method: "POST", data})
      .then(() => {
        Wizard.afterCheckout()
          .then(redirect => {
            this.props.notify({type: 'success', message: 'Compra registrada com sucesso.'});
            this.setState({redirect});
          });
      })
      .catch(err => {
        this.props.notify({type: 'error', message: 'Houve um erro ao tentar comprar este produto. Tente novamente mais tarde.'});
      })
      .finally(() => this.setState({working: false}));
  }

  render() {
    let {redirect} = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    
    let {product} = this.state;
    if (!product) {
      return (
        <>
          <div className="content">
            Carregando...
          </div>
        </>
      );
    }

    return (
      <>
        <div className="content product-checkout-container">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="product-title text-selfguru">Confirme sua compra</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <p>
                        Você selecionou o seguinte produto:
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Table>
                        <tbody>
                          <tr>
                            <td>{product.name}</td>
                            <td>{product.installments} x {toCurrency(product.price)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <p>
                        O pagamento será processado a partir do Paypal. Clique abaixo para confirmar.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <div>
                        <Button className="btn-round" color="default"
                          to='/admin/dashboard' disabled={this.state.working}
                          tag={Link}
                        >
                          Cancelar
                        </Button>

                        <Button className="btn btn-round" color="selfguru" type="button"
                          onClick={this.confirmPurchase} disabled={this.state.working}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withNotify(ProductCheckout);
