import React from "react";
import {Redirect} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from 'react-select';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";

import apiRequest from "lib/Api";
import {checkUserProfile, getUserData} from "lib/Auth";
import withNotify from "lib/NotificationWrapper";
import Services from "models/Services";

import defaultAvatar from "assets/img/default-avatar.png";
import ExtendedDataFilter from "../../../components/ExtendedData/ExtendedDataFilter";
import {instanceOf} from "prop-types";

class ChooseSpecialist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialists: [],
            purchaseService: null,
            availablePurchaseServices: [],
            selectedSpecialist: null,
            userData: getUserData,
            modal: false,
            openFilter: false,
            disabled: false,
            extended_data: {values: []},
            showFilterButton: false,
            specialist: {
                name: '',
                extended_data: {values: []}
            }
        }
    }

    showDetails(specialist) {
        this.state.specialist = specialist;
        this.setState({modal: !this.modal, specialist: specialist});
     }

    closeModal = () => {
        this.setState({modal: false});
    }

    componentDidMount() {
        let done;
        if (this.layout == 'setup') {
            done = checkUserProfile();
        } else {
            done = this.checkPurchaseService();
        }

        done.then(purchaseService => purchaseService && this.loadSpecialists(purchaseService));
        apiRequest("/extended-data/place/specialist", {method: "GET"})
            .then((response) => {
               let {data} = response.data;
               for (let field of data.extended_data.sections[0].fields) {
                   if (field.searchable) {
                       this.setState({showFilterButton: true});
                       break;
                   }
               }

            });
    }

    checkPurchaseService() {
        if (this.purchaseServiceId) {
            return Services.getPurchasedService(this.purchaseServiceId)
                .then(purchaseService => {
                    if (!purchaseService.available) {
                        this.props.notify({type: 'error', message: 'Este serviço já foi utilizado.'});
                        this.setState({disabled: true});
                    }
                    this.setState({purchaseService});
                    return purchaseService;
                })
                .catch(err => {
                    this.props.notify({type: 'error', message: 'Este serviço não existe.'});
                    this.setState({disabled: true});
                });
        } else {
            return Services.getSpecialistCallServices()
                .then(items => {
                    if (!items) {
                        items = [];
                    }
                    items = items.filter(ps => {
                        return ps.available;
                    });
                    if (!items.length) {
                        this.props.notify({type: 'warning', message: 'Não há service disponível.'});
                        this.setState({disabled: true});
                    } else if (items.length > 1) {
                        // let user choose the product service
                        this.setState({availablePurchaseServices: items});
                    } else {
                        this.setState({purchaseService: items[0]});
                        return items[0];
                    }
                    return null;
                });
        }
    }

    loadSpecialists(purchaseService) {
        let params = {
            searchRole: 'specialist',
        }

        if (this.state.extended_data) {

            params.searchData = JSON.stringify(this.state.extended_data);
        }

        if (!purchaseService) {
            return;
        }
        params.productServiceId = purchaseService.productServiceId || purchaseService.productService.id;

        return apiRequest("/users", {data: params, method: "GET"})
            .then(response => {
                let specialists = response.data.data;
                this.setState({specialists});
                if (!specialists.length) {
                    this.props.notify({
                        type: 'warning',
                        message: 'Não há especialistas disponíveis para este serviço.'
                    });
                }
            });
    }


    get layout() {
        return this.props.location.pathname.match(/[\w\-]+/)[0];
    }

    get purchaseServiceId() {
        if (this.props.match.params) {
            return this.props.match.params.purchaseServiceId;
        }
        return null;
    }

    get createRoute() {
        let route;
        if (this.layout == 'setup') {
            route = 'appointments/new-with';
        } else {
            route = 'appointments/with';
        }
        return `/${this.layout}/${route}/${this.state.selectedSpecialist}/${this.state.purchaseService.id}`;
    }

    get remainingDescription() {
        let {purchaseService} = this.state;
        if (!purchaseService) {
            return '';
        }
        let left = purchaseService.quota - purchaseService.usage;
        if (left === 1) {
            return <span>Você possui mais <b>1 consulta</b> disponível</span>;
        } else {
            return <span>Você possui mais <b>{left} consultas</b> disponíveis</span>;
        }
    }

    choose = (id) => {
        this.setState({selectedSpecialist: id});
    }

    render() {
        if (this.state.selectedSpecialist && this.state.purchaseService) {
            return <Redirect to={this.createRoute}/>;
        }

        let cardSize;
        if (this.layout == 'setup') {
            cardSize = {sm: 6, md: 4};
        } else {
            cardSize = {sm: 6};
        }

        return (
            <>

                {this.state.availablePurchaseServices.length > 0 && !this.state.purchaseService &&
                this.renderChoosePurchaseService()
                }

                <div className="content choose-specialist-container">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Escolha o especialista</CardTitle>
                                    <h5 className="card-category">
                                        {this.remainingDescription}
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    {this.state.showFilterButton &&
                                        <Row>
                                            <Col md={12}>
                                                <div style={{textAlign: 'right', padding: '4px'}}>
                                                    <Button className="btn-round btn-sm" color="info" type="button"
                                                            onClick={this.openFilter}>
                                                        Filtros
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="no-gutters">
                                        {this.state.specialists.map((specialist, idx) =>
                                            <Col {...cardSize} key={specialist.id}>
                                                <div
                                                    className={`specialist-card ${(idx % 2 == 0 && this.layout == 'setup') ? 'even' : 'odd'}`}>
                                                    <div className="specialist-card-image">
                                                        <img src={specialist.pictureUrl || defaultAvatar}/>
                                                    </div>
                                                    <div className="specialist-card-info">
                                                        <h5 className="specialist-title">
                                                            {specialist.name}
                                                        </h5>
                                                        <p className="specialist-bio">
                                                            {specialist.bio}
                                                        </p>
                                                        <div className="specialist-btn-container">
                                                            <Button className="btn-round btn-sm" color="success"
                                                                    type="button"
                                                                    onClick={() => this.choose(specialist.id)}
                                                            >
                                                                Escolher
                                                            </Button>
                                                            <Button className="btn-round btn-sm" color="info"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.showDetails(specialist)
                                                                    }}>
                                                                Saiba mais sobre {specialist.name}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" className="text-right">

                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <ExtendedDataFilter ref="filter" type="specialist" fillFields={this.setFilters}
                                    searchAction={this.search}/>
                <Modal isOpen={this.state.modal} toggle={this.openModal} size="md">
                    <ModalHeader toggle={this.toggle} style={{position: 'sticky'}}>Detalhes do
                        Especialista {this.state.specialist.name}</ModalHeader>
                    <ModalBody>

                     {this.renderFields(this.state.specialist.extended_data.values)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'error'} onClick={this.closeModal}>Fechar</Button>
                        &nbsp;
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    renderFields = (fields) => {
      let fieldsToReturn = [];
      for (const info in fields) {
        let value = fields[info].values
          fields[info].values = value;
        fieldsToReturn.push(fields[info]);
      }
      return (<>
            {fieldsToReturn.map(info => {
                return (
                    <Row key={info.field} className={"odd"}>
                      <Label sm="6"><b>{info.label}:</b></Label>
                      <Col sm="6">
                        {info.values instanceof Array ? info.values.join(", ") : info.values}
                      </Col>
                    </Row>
                )
              })
              }
        </>
       )
    }

    search = (clear) => {
        if (clear) {
            this.state.extended_data = {};
        }
        this.loadSpecialists(this.state.purchaseService)
    }
    setFilters = (data) => {

        this.setState({extended_data: data});
    }
    openFilter = () => {
        this.refs.filter.handleClick();
    };

    renderChoosePurchaseService() {
        let options = this.state.availablePurchaseServices.map(ps => ({
            value: ps.id,
            label: ps.name
        }));
        let selectedOption;

        return (
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-200px"}}
                title="Escolha o produto"
                onConfirm={() => {
                    let purchaseService = this.state.availablePurchaseServices.find(
                        ps => ps.id = selectedOption.value
                    );
                    this.setState({purchaseService});
                    this.loadSpecialists(purchaseService);
                }}
                confirmBtnBsStyle="info"
                confirmBtnText="Próximo"
            >
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    onChange={option => selectedOption = option}
                    options={options}
                    placeholder="Selecione um produto"
                />
            </ReactBSAlert>
        );
    }
}

export default withNotify(ChooseSpecialist);
