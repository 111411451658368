import React from "react";
import {Button, Card, CardBody, CardHeader, CardImg, CardTitle, Col, Row} from "reactstrap";

import logoInstagram from "../../../assets/img/logos/instagram.png";
import logoBlog from "../../../assets/img/logos/blog.png";
import logoPodcast from "../../../assets/img/logos/podcast.png";
import NutrideckCard from "../../../components/Cards/NutrideckCard";

class Content extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Conteúdo Nutrideck</CardTitle>
                                    <br/>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <NutrideckCard
                                logo={logoInstagram}
                                title="Instagram"
                                description="Conteúdo leve e divertido: alimentos e seus nutrientes, alimentação e saúde, rotina alimentar e comer com liberdade"
                                titleButton="Acessar"
                                width={120}
                                height={120}
                                routeButton="https://www.instagram.com/nutrideck_brasil/"
                            />
                        </Col>
                        <Col md={4}>
                            <NutrideckCard
                                logo={logoBlog}
                                title="Blog"
                                description="Matérias para se aprofundar em conceitos de nutrição e alimentação saudável"
                                titleButton="Acessar"
                                width={120}
                                height={120}
                                routeButton="https://www.nutrideck.com.br/blog/"
                            />
                        </Col>
                        <Col md={4}>
                            <NutrideckCard
                                logo={logoPodcast}
                                title="Podcast"
                                description="Uma série de conteúdo para você escutar e aprender tudo sobre alimentação e produtividade"
                                titleButton="Acessar"
                                width={120}
                                height={120}
                                routeButton="https://open.spotify.com/show/5hPg4P2NyQG10mnYey1Izw"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Content;
