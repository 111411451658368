import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import SetupLayout from "layouts/Setup/Setup.jsx";
import ChatLayout from "layouts/Chat/Chat.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";

import bugsnagReact from '@bugsnag/plugin-react';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import bugsnag from '@bugsnag/js';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { hotjar } from 'react-hotjar';

const bugsnagClient = bugsnag({
  apiKey: 'b8e763ab9792c217c01e74d9ec75d44c',
  releaseStage: process.env.NODE_ENV,
  notifyReleaseStages: ['production']
});
if (process.env.NODE_ENV === 'production') {
    hotjar.initialize(2468792, 6);
    hotjar.identify('USER_ID', {userProperty: 'value'});
}
bugsnagClient.use(bugsnagReact, React);

const hist = createBrowserHistory();

const ErrorBoundary = bugsnagClient.getPlugin('react');
const App: React.FC = () => {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
   console.log("ultima versao:"+isLatestVersion);
    return (
        <div >
            {!isLatestVersion && (
                <Modal isOpen={true}>
                    <ModalHeader style={{position: 'sticky'}}>Nova versão disponível!</ModalHeader>
                    <ModalBody style={{textAlign: 'center'}}>
                        <h3><i className="fas fa-redo-alt"/>&nbsp;
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                emptyCacheStorage();
                            }}
                        >
                            Atualizar agora
                        </a>
                        </h3>
                    </ModalBody>
                </Modal>

            )}
        </div>
    );
};
ReactDOM.render(
  <ErrorBoundary>
    <ClearCacheProvider duration={10000}>
        <App/>
    <Router history={hist}>
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/setup" render={props => <SetupLayout {...props} />} />
        <Route path="/chat" render={props => <ChatLayout {...props} />} />
        <Route exact path="/linkedin" component={LinkedInPopUp} key="linkedin"/>
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </Router>
    </ClearCacheProvider>,
  </ErrorBoundary>,
  document.getElementById("root")
);

// bugsnagClient.notify(new Error('Test error'));
