import React from "react";
import {Link} from "react-router-dom";
import {getUserData} from "../../../lib/Auth";
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import moment from "moment";
import apiRequest from "../../../lib/Api";
import HeaderProntuario from "../../../components/Header/HeaderProntuario";

class UserFormData extends React.Component {
    constructor(props) {
        super(props);
        let userData = getUserData()
        let readOnly = false;
        let classInputs = '';
        if (userData.role === 'patient') {
            readOnly = true;
            classInputs = 'form-exames-readonly';
        }
        this.state = {
            user: {name: '', date_start: moment()},
            currentUser: getUserData(),
            hasResponse: false,
            readOnly: readOnly,
            haveToSave: false,
            isDone: true,
            objetivos: '',
            classInputs: classInputs,
        }
    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    get formName() {
        switch (this.props.match.params.form) {
            case 'objetivos' :
                return 'Objetivos'
            case 'perfil_conduta':
                return 'Perfil e Conduta'
            case 'metas_combinados':
                return 'Metas e Combinados'
        }
    }

    get formId() {
        return this.props.match.params.form;
    }

    async componentWillMount() {
        await apiRequest("/patient/" + this.id, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                let readOnly = false;
                let classInputs = '';

                if (this.state.currentUser.role === 'patient') {
                    readOnly = true;
                    classInputs = 'form-exames-readonly';
                }
                this.setState({user: data, readOnly: readOnly, classInputs: classInputs});
            });

        const request = await apiRequest(`/patient/${this.id}/forms/${this.formId}`, {method: 'GET'})
            .then(success => {
                this.setState({hasResponse: true});
                this.fillForm(success.data.data)
            }).catch(error => {
                this.setState({hasResponse: false});
            });
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }

        this.setState({[name]: value});
    }

    fillForm = (response) => {
        for (const group of response.data.groups) {
            for (const subgroup of group.subgroups) {
                for (const field of subgroup.fields) {
                    this.setState({[field.name]: field.value});
                }
            }
        }
    }

    save = async () => {
        const dados = this.state[this.formId] || '';
        if (dados === null || dados.trim() === '') {
            this.props.notify({type: 'error', message: `Informe os ${this.formName} antes de continuar.`});
            return;
        }
        let group = {
            name: this.formId,
            subgroups: []
        }
        let subgroup = {
            name: this.formId,
            fields: []
        }
        let fieldData = {
            name: this.formId,
            value: this.state[this.formId]
        }

        subgroup.fields.push(fieldData);
        group.subgroups.push(subgroup);

        let dataToPost = {
            data: {
                data: {groups: [group]},
                status: 'done',
            },
            method: "POST"
        }
        try {
            const response = await apiRequest(`/patient/${this.id}/forms/${this.formId}`, dataToPost)
            this.props.notify({type: 'success', message: `${this.formName} registrado com sucesso`});
        } catch (e) {
            this.props.notify({type: 'error', message: e.data.message});
        }
    }
    render() {
        let link = `/admin/patient/${this.id}/details`;
        let textoLink = 'Voltar para o perfil do paciente.';
        if (this.state.readOnly) {
            link = `/admin/dashboard`;
            textoLink = 'Voltar para o painel.';
        }
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                <HeaderProntuario
                                    title={`Preenchimento de ${this.formName} de ${this.state.user.name}`}
                                    link={link}
                                    buttonText={textoLink}
                                />
                                </CardHeader>
                                <CardBody>

                                    <form>
                                        <Row>
                                            <Col md='12'>
                                                <FormGroup>
                                                    <label>{this.formName}:</label>
                                                    <Input type="textarea" name={this.formId}
                                                           style={{maxHeight: '200px !important'}}
                                                           rows={10}
                                                           value={this.state[this.formId]}
                                                           onChange={this.handleChange}
                                                           className={this.state.classInputs}
                                                           disabled={this.state.readOnly}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='10'>
                                                <Col sm='2'>
                                                    <Button color="white"
                                                            style={{display: this.state.readOnly ? 'none' : ''}}
                                                            className="white-button" tag={Link}
                                                            to={`/admin/patient/${this.id}/details`}
                                                            disabled={this.state.working}>
                                                        Cancelar
                                                    </Button>
                                                </Col>
                                            </Col>
                                            <Col sm='2'>
                                                <Button color="primary"
                                                        style={{display: this.state.readOnly ? 'none' : ''}}
                                                        onClick={this.save} disabled={this.state.working}>
                                                    Concluir
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default withNotify(UserFormData)
