import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import defaultAvatar from "assets/img/default-avatar.png";
import logo from "assets/img/logo_principal.svg";
import logoSmall from "assets/img/logo-small.png";

import {
  getUserData,
  logout,
  redirectLogin,
  redirectToLoginIfNeeded,
  getUserFriendlyRole
} from "../../lib/Auth";
import classnames from "classnames";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(props.routes),
      windowWidth: this.getWindowWidth(),
      user: getUserData() || {}
    };
    redirectToLoginIfNeeded();
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  getWindowWidth()
  {
    return window.outerWidth;
  }
  setWindowWidth = () => {
     this.setState({windowWidth : this.getWindowWidth()});
  }
  get isMobile() {
    return this.state.windowWidth < 991;
  }

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.permissions) {
        let {permissions} = prop;
        if (!Array.isArray(permissions)) {
          permissions = [permissions];
        }
        if (permissions.indexOf(this.state.user.role) === -1) {
          if (permissions.indexOf('admin') !== -1 && this.state.user.adminOrganization) {
            // acceptable, special case
          }
          else {
            return;
          }
        }
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          {!prop.absolute && (
          <NavLink to={prop.layout + prop.path} activeClassName=""
                   onClick={this.toggleSidebar}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
          )}
          {prop.absolute && (
          <a href={prop.url} target="blank">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </a>
          )}
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    let routeParts = routeName.split("/");
    return this.props.location.pathname.indexOf(routeParts[2]) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    window.addEventListener("resize", this.setWindowWidth);
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  logout = (e) => {
    e.preventDefault();
    logout()
      .then(() => redirectLogin(true));
  }
  toggleSidebar = () => {

    document.documentElement.classList.toggle("nav-open");
  };
  render() {
    let avatar = this.state.user.pictureUrl || defaultAvatar;
    let friendlyRole = getUserFriendlyRole();
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <a
            href="https://www.nutrideck.com.br"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logoSmall} alt="nutrideck-logo-small" />
            </div>
          </a>
          <a
            href="https://www.nutrideck.com.br"
            className="logo-normal"
          >
            <img src={logo} alt="nutrideck" />
          </a>
          {this.isMobile &&
          (<div className="float-right" style={{position: 'absolute', right: '10px', top: '5px'}}>
            <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
            >
              <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
              >
                <i className="fa fa-bars" style={{fontSize: "30px"}}/>
              </button>
            </div>
          </div>
          )
          }
        </div>

        <div className="sidebar-wrapper" ref="sidebar">

          <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                href="#"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {this.state.user.name}
                  <b className="caret" />
                  <br/>
                  <small style={{opacity: 0.6}}>{friendlyRole}</small>
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">Meu perfil</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/change-password" activeClassName="">
                      <span className="sidebar-mini-icon">AS</span>
                      <span className="sidebar-normal">Alterar senha</span>
                    </NavLink>
                  </li>
                  <li>
                    <a onClick={this.logout} style={{cursor: 'pointer'}}>
                      <span className="sidebar-mini-icon">L</span>
                      <span className="sidebar-normal">Logout</span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
