import React from "react";

// reactstrap components
import {Button, Col, Row} from "reactstrap";

import {apiRequest, apiRoot} from 'lib/Api';
import {getAuthToken, getUserData} from 'lib/Auth';
import * as queryString from "query-string";
import {Input} from "react-bootstrap-typeahead";
import * as moment from "moment";
import AdvertiseJitsiModal from "../../components/Modals/AdvertiseJitsiModal";

import {BrowserView, MobileView} from "react-device-detect";

const rocketChatHost = process.env.REACT_APP_ROCKETCHAT_HOST;
const PING_INTERVAL = 30000;  // 1 min

// for testing
const testMode = false;
let myUsername = null;


class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalAdvertise: false,
            direct: '',
            username: '',
            message: '',
            messages: [],
            intervalId: null,
            isGuest: false,
            token: '',
            chatId: '',
            lastId: '',
            currentUser: getUserData(),
            chatSessionId: null
        };

    }

    get id() {
        if (this.props.match.params) {
            return this.props.match.params.id;
        }
        return null;
    }

    get chatId() {
        return this.state.chatId
    }

    async componentDidMount() {
        if (!this.id) {
            return;
        }

        let intervalId = setInterval(this.getMessages, 1500);
        this.setState({intervalId: intervalId});

        let token = queryString.parse(this.props.location.search).token;
        this.setState({token: token});
        await this.fetchChatInfo();

        window.addEventListener("unload", e => {

            // when page's unload, use sendBeacon to make sure that
            // a request will be send to backend
            const url = apiRoot + `/chat-sessions/close?token=` + getAuthToken() + `&chat_id=${this.chatId}`;
            const success = navigator.sendBeacon(url);
        })

        this.checkModal();
    }

    checkModal() {

        this.setState({
            showModalAdvertise: (
                <MobileView>
                    <AdvertiseJitsiModal
                        open={true}
                    />
                </MobileView>
            )
        });
    }


    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({[name]: value});
    }

    /**
     * Fetch info required to open chat in rocket.chat
     */
    async fetchChatInfo() {

        let token = queryString.parse(this.props.location.search).token;
        if (token) {
            await apiRequest("/auth/guest?token=" + token, {method: "GET"}).then(response => {
                this.setState({isGuest: true})
                return;
            });
        } else {
            //return;
            return await apiRequest("/appointments/" + this.id + "/chat", {method: "POST"})
                .then(async response => {
                    this.setState({
                        direct: response.data.direct,
                        username: response.data.username
                    });
                    if (testMode) {
                        myUsername = response.data.username;
                    }
                    await this.openChatSession('app123');
                    await this.getMessages();
                });
        }
    }

    openChatSession = (roomId) => {
        if (this.state.isGuest) {
            return;
        }
        let params = {roomId, appointment_id: this.state.direct};
        return apiRequest('/chat-sessions/open', {method: "POST", data: params})
            .then(response => {
                let {data} = response.data;
                this.setState({chatId: data.id, chatSessionId: data.chat_id});
                setInterval(this.ping, PING_INTERVAL);
            });
    }

    maybeNotifyMessage = (message) => {
        let data = {message, chat_id: this.state.chatId};
        return apiRequest(`/chat-sessions/new-message`, {method: "POST", data});
    }

    ping = () => {
        if (this.state.isGuest) {
            return;
        }
        let data = {chat_id: this.state.chatId};
        return apiRequest(`/chat-sessions/ping`, {method: "POST", data});
    }

    send = async () => {
        let data = {
            message: this.state.message,
            chat_id: this.state.chatId,
        }
        await apiRequest(`/chat/messages`, {method: "POST", data});
        this.setState({message: ''});
    }
    getMessages = async () => {
        let data = {
            chat_id: this.state.chatSessionId,
        }
        await apiRequest(`/chat/messages`, {method: "GET", data}).then((response) => {
            this.setState({messages: response.data.data});
        });
    }

    renderMessages = () => {
        let messages = this.state.messages;

        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1].uuid;
            this.state.lastId = lastMessage;
        }

        return (
            messages.map(message => {
                let className = "msg right-msg";
                if (message.user_id === this.state.currentUser.id) {
                    className = "msg left-msg";
                }

                let lastId = 'msg' + message.uuid;
                let hour = moment(message.created_at).format('HH:mm')
                return (
                    <div className={className} key={message.uuid} id={lastId}>
                        <div
                            className="msg-img"
                        ></div>

                        <div className="msg-bubble">
                            <div className="msg-info">
                                <div className="msg-info-name">{message.name}</div>
                                <div className="msg-info-time">{hour}</div>
                            </div>

                            <div className="msg-text">
                                {message.message}
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    render() {
        let link = 'https://meet.jit.si/appointment_chat_' + this.id;
        if (!this.state.direct && false) {
            return <p></p>;
        }

        return (

            <>
                {this.state.showModalAdvertise}
                <div className="content">
                    <Row>
                        <Col md="6" className="height-video">

                            <iframe src={`${link}`} width="100%" height="90%"
                                    allow="microphone; camera"
                                    allowFullScreen
                            />

                        </Col>
                        <Col md="5">
                            <BrowserView>
                                <Row>
                                    <div className="chatbox" style={{height: '80vh'}}>
                                        <div className="msger-chat">
                                            {this.renderMessages()}
                                            {this.state.lastId ? (document.getElementById(this.state.lastId) ? document.getElementById(this.state.lastId).scrollIntoView() : '') : ''}
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="msger-inputarea">
                                        <Input type='text' name='message' className='msger-input'
                                               onKeyPress={event => {
                                                   if (event.which === 13) {
                                                       event.preventDefault();
                                                       this.send(event);
                                                   }
                                               }
                                               }
                                               onChange={this.handleChange} value={this.state.message}
                                        />
                                        <Button type="button" className="msger-send-btn"
                                                onClick={this.send}>Enviar</Button>
                                    </div>
                                </Row>
                            </BrowserView>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

// to test, with no rocket chat in use
if (testMode) {
    let randomString = length => Math.random().toString(20).substr(2, length);
    let generateData = (eventName) => ({
        eventName,
        data: {
            rid: randomString(),
            u: {username: myUsername}
        }
    });
    window.testMessage = (msg) => {
        let data = generateData('new-message');
        data.data.msg = msg;
        window.postMessage(data, '*');
    }
    window.testOtherAction = (action = 'unread-changed') => {
        let data = generateData(action);
        window.postMessage(data, '*');
    }
}

export default Chat;
