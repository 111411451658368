import React from "react";
import Select from "react-select";
import InputMask from 'react-input-mask';
import DateField from '../../../components/Date/DateField';
import {Link, Redirect} from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import withNotify from "../../../lib/NotificationWrapper";
import InputError from "../../components/InputError";
import {getUserData} from "../../../lib/Auth";
import {removeMask} from "../../../lib/Formatter";
import statesDef from "../../../variables/states";
import moment from "moment";

class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            birth: '',
            gender: '',
            password: '',
            address: '',
            city: '',
            state: '',
            teste: '',
            cep: '',
            occupation: '',
            phone: '',
            address_number: '',
            address_complement: '',
            neighborhood: '',
            errors: {},
            loggedUserData: getUserData(),
            redirect: '',
            extended_data: {},
            isMe: false
        };
        this.statesDef = statesDef.map(state =>
            ({label: state.name, value: state.id})
        );
        this.handleCep = this.handleCep.bind(this);
    }

    componentWillMount() {
        if (this.id) {
            this.fetchUser();
        }
    }

    fetchUser() {
        return apiRequest("/users/" + this.id, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                for (let field of Object.keys(data)) {
                    if (data[field] === null) {
                        data[field] = '';
                    }
                }
                data.state = this.statesDef.find(option => option.value == data.state);
                this.setState(data);
                if (data.gender) {
                    const genderSelected = {label: data.gender, value: data.gender}
                    this.setState({'gender': genderSelected});
                }
                if (data.birth) {
                    let birth = data.birth.split('-');
                    this.setState({'birth': `${birth[2]}/${birth[1]}/${birth[0]}`});
                }
            });
    }

    componentDidMount() {
        let {id} = this.props.match.params;
        if (id == 'me') {
            this.setState({isMe: true});
        }
    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            if (id == 'me') {
                id = this.state.loggedUserData.id;
            }
            return id;
        }
        return null;
    }

    get scope() {
        if (this.props.match.params.scope) {
            return this.props.match.params.scope;
        }
        if (this.props.match.path.match(/patient/)) {
            return 'patient';
        }
        if (this.props.match.path.match(/specialists/)) {
            return 'specialist';
        }
        throw new Error("Invalid context");
    }

    get role() {
        if (this.scope === 'patient') {
            return 'patient';
        }
        if (this.scope === 'specialist') {
            return 'specialist';
        }
        return null;
    }

    get formTitle() {
        if (this.scope === 'patient') {
            if (this.id) {
                return 'Alteração de pacientes';
            }
            return 'Cadastro de pacientes';
        }
        if (this.scope === 'specialist') {
            if (this.id) {
                return 'Alteração de especialista';
            }
            return 'Cadastro de especialista';
        }
        return '';
    }

    clearForm() {
        this.setState({
            name: '',
            email: '',
            birth: '',
            gender: '',
            address: '',
            city: '',
            state: '',
            teste: '',
            cep: '',
            occupation: '',
            phone: '',
            address_number: '',
            address_complement: '',
            neighborhood: ''

        });
    }

    handleCep(name, value) {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({cep: value});
        this.setCep(value)
    }

    setCep(cep) {

        cep = cep.replaceAll('-', '');
        cep = cep.replaceAll('_', '');

        if (cep.length < 8) {
            return;
        }

        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => response.json())
            .then(data => {
                console.log(data)

                const userState = data.state = this.statesDef.find(option => option.value === data.uf);
                this.setState({
                    city: data.localidade,
                    address: data.logradouro,
                    state: userState,
                    neighborhood: data.bairro,
                })
            })
            .catch(err => {
                console.log('cep Nao encontrado')
            })
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({[name]: value});

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let {
            name,
            email,
            address,
            city,
            state,
            cep,
            phone,
            birth,
            occupation,
            gender,
            address_number,
            address_complement,
            neighborhood

        } = this.state;

        cep = removeMask(cep);
        phone = removeMask(phone);
        state = state ? state.value : '';

        let data = {name, email, address, city, state, cep, phone, birth, occupation, gender,
            address_number,
            address_complement,
            neighborhood};
        if (birth) {
            if (moment.isMoment(birth)) {
                data.birth = birth.format('YYYY-MM-DD');
            } else {
                data.birth = moment(birth, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
        }
        data.cpf = '00000000000';
        data.mobile = '';
        data.bio = '';
        data.mobile = '';
        data.linkedin = '';
        data.extended_data = '';
        data.role = this.role


        // use form data because we need to attach a file together
        let formData = new FormData()
        for (let [key, value] of Object.entries(data)) {
            if (key === 'gender') {
                value = value.value
            }
            formData.append(key, value);
        }

        this.setState({working: true});

        let request;
        let isEdit = !!this.id;
        if (this.id) {
            request = apiRequest("/users/" + this.id, {data: formData, method: 'PUT'})
        } else {
            formData.append('role', this.role);
            request = apiRequest("/users", {data: formData, method: 'POST'});
        }

        request.then(response => {
            if (!isEdit) {
                this.clearForm();
            }
            this.props.notify({type: 'success', message: 'Paciente armazenado com sucesso'});
        }).catch(err => {
            this.setState({errors: err.data.errors});
        }).finally(
            response => this.setState({working: false})
        );
    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="6">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{this.formTitle}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup className={hasError.name}>
                                            <label>Nome:</label>
                                            <Input type="text" name="name" value={this.state.name}
                                                   onChange={this.handleChange}
                                            />
                                            <InputError name="name" errors={this.state.errors}/>
                                        </FormGroup>

                                        <FormGroup className={hasError.birth}>
                                            <label>Data de Nascimento:</label>
                                            <DateField
                                                name="birth"
                                                value={this.state.birth}
                                                onChange={value =>
                                                    this.handleChange('birth', value)
                                                }
                                            />
                                            <InputError name="birth" errors={this.state.errors}/>
                                        </FormGroup>

                                        <FormGroup className={hasError.email}>
                                            <label>E-mail:</label>
                                            <Input type="text" name="email" value={this.state.email}
                                                   onChange={this.handleChange}
                                            />
                                            <InputError name="email" errors={this.state.errors}/>
                                        </FormGroup>

                                        <Label>Gênero:</Label>
                                        <FormGroup className={hasError.state}>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="state"
                                                value={this.state.gender}
                                                onChange={value =>
                                                    this.handleChange("gender", value)
                                                }
                                                options={[
                                                    {
                                                        value: "Masculino",
                                                        label: "Masculino",
                                                    },
                                                    {
                                                        value: "Feminino",
                                                        label: "Feminino",
                                                    }
                                                ]}
                                                placeholder="Selecione um gênero"
                                            />
                                            <InputError name="gender" errors={this.state.errors}/>
                                        </FormGroup>
                                        <Row>
                                            <Col md="6">
                                                <Label>CEP</Label>
                                                <FormGroup className={hasError.cep}>
                                                    <InputMask mask="99999-999" value={this.state.cep}
                                                               onChange={this.handleCep} name="cep"
                                                    >
                                                        {(inputProps) => <Input type="text" {...inputProps} />}
                                                    </InputMask>
                                                    <InputError name="cep" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Label>Cidade</Label>
                                                <FormGroup className={hasError.city}>
                                                    <Input type="text" name="city" value={this.state.city}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="city" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <Label>Estado</Label>
                                                <FormGroup className={hasError.state}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="state"
                                                        value={this.state.state}
                                                        onChange={value =>
                                                            this.handleChange("state", value)
                                                        }
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Selecione um estado",
                                                                isDisabled: true
                                                            },
                                                            ...this.statesDef
                                                        ]}
                                                        placeholder="Selecione um estado"
                                                    />
                                                    <InputError name="state" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <Label>Endereço:</Label>
                                                <FormGroup className={hasError.address}>
                                                    <Input type="text" name="address" value={this.state.address}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="address" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <Label>Bairro:</Label>
                                                <FormGroup className={hasError.neighborhood}>
                                                    <Input type="text" name="neighborhood" value={this.state.neighborhood}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="neighborhood" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Label>Número:</Label>
                                                <FormGroup className={hasError.address_number}>
                                                    <Input type="text" name="address_number" value={this.state.address_number}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="address_number" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={8}>
                                                <Label>Complemento:</Label>
                                                <FormGroup className={hasError.address_complement}>
                                                    <Input type="text" name="address_complement" value={this.state.address_complement}
                                                           onChange={this.handleChange}
                                                    />
                                                    <InputError name="address_complement" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md="6">
                                                <Label>Telefone</Label>
                                                <FormGroup className={hasError.phone}>
                                                    <InputMask mask="(99) 9999tt999?"
                                                               formatChars={{
                                                                   "9": "[0-9]",
                                                                   "t": "[0-9\-]",
                                                                   "?": "[0-9 ]"
                                                               }}
                                                               maskChar={null}
                                                               value={this.state.phone}
                                                               onChange={this.handleChange} name="phone"
                                                    >
                                                        {(inputProps) => <Input type="text" {...inputProps} />}
                                                    </InputMask>
                                                    <InputError name="phone" errors={this.state.errors}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Label>Profissão:</Label>
                                        <FormGroup className={hasError.occupation}>
                                            <Input type="text" name="occupation" value={this.state.occupation}
                                                   onChange={this.handleChange}
                                            />
                                            <InputError name="occupation" errors={this.state.errors}/>
                                        </FormGroup>
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col style={{textAlign: "left"}}>
                                                <Button className='white-button'
                                                        tag={Link}
                                                        to={'/admin/patients/list'}
                                                >
                                                    Cancelar
                                                </Button>
                                            </Col>
                                            <Col style={{textAlign: "right"}}>
                                                <Button color="info" type="submit"
                                                        disabled={this.state.working}
                                                >
                                                    Salvar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(UserForm);
