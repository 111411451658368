const foodGroups = [
    {id: 1, name: " Cereais e derivados"},
    {id: 2, name: " Verduras, hortaliças e derivados"},
    {id: 3, name: " Frutas e derivados"},
    {id: 4, name: " Gorduras e óleos"},
    {id: 5, name: " Pescados e frutos do mar"},
    {id: 6, name: " Carnes e derivados"},
    {id: 7, name: " Leite e derivados"},
    {id: 8, name: " Bebidas (alcoólicas e não alcoólicas)"},
    {id: 9, name: " Ovos e derivados"},
    {id: 10, name: " Produtos açucarados"},
    {id: 11, name: " Miscelâneas"},
    {id: 12, name: " Outros alimentos industrializados"},
    {id: 13, name: " Alimentos preparados"},
    {id: 14, name: " Leguminosas e derivados"},
    {id: 15, name: " Nozes e sementes"}
];
export default foodGroups;
