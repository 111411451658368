import React from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import * as moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import apiRequest from "../../../../lib/Api";

class CardAppointment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key : '',
            when : '',
            type : '',
            confirmMessage: null,
        }
    }

    componentDidMount() {

        const date = moment(this.props.appointment.datetime_start);
        this.setState({
            key: this.props.appointment.id,
            when: date.format('DD [de] MMMM [de] YYYY [às] h:mm a'),
            service_title: this.props.appointment.product_title,
        });
    }

    cancel = () => {
        this.setState({
            confirmMessage: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Confirma o cancelamento do agendamento?"
                    onConfirm={async () => {
                        await apiRequest(`/appointments/${this.props.appointment.id}/cancel`, {
                            method: 'PUT'
                        }).then(success => {
                            this.props.notify({type:'success', message:'Agendamento cancelado com sucesso.'});
                            this.setState({confirmMessage:null});
                        }).catch(error => {
                            this.props.notify({type:'error', message:'Não foi possível cancelar o agendamento.'});
                        });
                    }}
                    onCancel={() => this.setState({confirmMessage: null})}
                    confirmBtnBsStyle="primary"
                    confirmBtnText='Confirmar'
                    cancelBtnBsStyle="default"
                    cancelBtnText="Cancelar"
                    showCancel
                />
            )
        });
    }



    renderButtons = () => {
        if (this.props.appointment.situation === 'done') {
            return;
        }
        return (
            <Row style={{textAlign:'center'}}>
                <Col md='4' sm='6' style={{textAlign:'center'}}>
                    <Button color='default'
                            disabled={this.props.appointment.situation === 'started'}
                            onClick={() => {
                                if (this.props.changeButton) {
                                    return this.props.changeButton();
                                }
                            }}
                    >Alterar</Button>
                </Col>
                <Col md='4'
                     style={{textAlign:'center'}}
                >
                    <Button color='danger'
                            disabled={this.props.appointment.situation === 'started'}
                            onClick={() => {
                                if (this.props.cancelButton) {
                                    return this.props.cancelButton();
                                } else {
                                    return this.cancel();
                                }
                            }}
                    >
                        Cancelar
                    </Button>
                </Col>
                <Col md='4' style={{textAlign:'center'}}>
                    <Button color='primary'
                            disabled={this.props.appointment.situation === 'started'}
                            onClick={() => {
                                if (this.props.startButton) {
                                    this.props.startButton();
                                }
                            }}
                    >{this.props.appointment.situation === 'started' ? 'Iniciado' : 'Iniciar'}</Button>
                </Col>
            </Row>
        );
    }

    render = () => {
        return (
            <React.Fragment>
                <Col>
                    {this.state.confirmMessage}
                    <Card className='card-appointment content'>
                        <CardBody>
                            <h5 className='card-appointment title'>Quando:</h5>
                            <p>{this.state.when}</p>
                            <h5 className='card-appointment title'>Tipo:</h5>
                            <p>{this.state.service_title}</p>
                            <br />
                            {this.props.renderButtons ? this.renderButtons() : ''}

                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default withNotify(CardAppointment);
