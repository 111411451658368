import React from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import moment from "moment";
import {Link} from "react-router-dom";

class AppointmentCard extends React.Component {
    constructor(props) {

        let buttonOnTop = props.buttonOnTop || false;
        super(props);
        this.state = {
            buttonOnTop: buttonOnTop
        }
    };

    renderButton(appointment)
    {
        return(
            <Row>
                <Col>
                    <Link
                        to={`/admin/appointments/${appointment.id}`}
                    >
                        Visualizar Consulta
                    </Link>
                </Col>
            </Row>
        )
    }
    render() {
        let appoiment = this.props.appointment;
        let date = moment(appoiment.datetime_start);
        return (
            <>
                <Row>
                    <Col sm={12} md={10}>
                        <Card className="card-appointment" style={{height: "95%"}}>
                            <CardHeader>
                                {this.props.label ? this.props.label : 'Próximo agendamento'}
                                {this.state.buttonOnTop && this.renderButton(appoiment)}
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}><b>Data:</b></Col>
                                    <Col md={12}>{date.format('LL')} {}</Col>
                                </Row>
                                <Row>
                                    <Col md={12}><b>Dia da semana:</b></Col>
                                    <Col md={12}>{date.format('dddd')} {}</Col>
                                </Row>
                                <Row>
                                    <Col md={12}><b>Horário:</b></Col>
                                    <Col md={12}>{date.format('HH:mm')} {}</Col>
                                </Row>
                                <Row>
                                    <Col md={12}><b>Tipo:</b></Col>
                                    <Col
                                        md={12}>{appoiment.service_type === 'personal_meeting' ? 'Presencial' : 'Online'} {}</Col>
                                </Row>
                                {!this.state.buttonOnTop && this.renderButton(appoiment)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default AppointmentCard;
