import React from "react";

import HomeAdmin from "./HomeAdmin";
import HomeMember from "./HomeMember";
import HomeSpecialist from "./HomeSpecialist";
import {getUserData} from "lib/Auth";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: getUserData(),
      isAdmin: false
    };
  }

  get isAdmin() {
    return !!this.state.userData.adminOrganization;
  }

  get role() {
    return this.state.userData.role;
  }

  render() {
    if (this.role == 'super_admin') {
      return (
        <>
          <div className="content dashboard-admin-container">
          </div>
        </>
      );
    }
    else if (this.role == 'specialist') {
      return <HomeSpecialist {...this.props} />;
    }
    else if (this.isAdmin) {
      return <HomeAdmin {...this.props} />
    }
    else {
      return <HomeMember {...this.props} />;
    }
  }
}

export default Home;
